import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { Constants } from '../../constants';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ChatroomAdminsModalComponent } from '../chatroom/chatroom-admins-modal.component';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { TimeoffSettingsService } from '../timeoff-settings/timeoff-settings.service';

@Component({
  selector: 'timeoff-schema',
  templateUrl: './timeoff-schema.component.html'
})

export class TimeoffSchemaComponent implements OnInit {

  public TIMEOFF_SCHEMA_TABS: any;
  public selectedTab: number;
  public schemaForm: schema;
  public schemaEmployees: Array<any> = [];
  public defaultLanguage: string;
  public selectedLanguage: string;
  public availableCompanyLanguages: Array<string>;

  private schemaId: number;
  private lastSchemaName: string = '';
  private _route: any;
  private userSession: any;
  private EDITION_ALLOWED_KEYS: Array<string> = Constants.EDITION_ALLOWED_KEYS;

  @ViewChild('schemaName', {static: false}) schemaNameRef: ElementRef;

  constructor (
    private location: Location, private upgrade: UpgradeModule, private titleService: Title, private timeoffSettingsService: TimeoffSettingsService, 
    private translate: TranslateService, public formUtilsService: FormUtilsService, private Dialog: MatDialog, private notificationService: DuiNotificationsService

  ) { 
      this.TIMEOFF_SCHEMA_TABS = Constants.TIMEOFF_SCHEMA_TABS;
      this._route = this.upgrade.$injector.get('$route');
      this.schemaId = this._route.current.params.id;
      this.userSession = window['getUserSession']();
      this.defaultLanguage = this.userSession.companyDefaultLanguage;
      this.selectedLanguage = this.userSession.companyDefaultLanguage;
      this.availableCompanyLanguages = this.userSession.companyLanguages;

      this.selectedTab = this._route.current.locals.selectedTab;
    }

  ngOnInit() {
    this.timeoffSettingsService.findSchema(this.schemaId).subscribe(
      (response: any) => {
          this.schemaForm = response;
          this.lastSchemaName = response.timeOffSchema.name;
      },
      (onError: any) => { }
    );
  }

  // PRIVATE METHODS

  private checkSchemaNameAndSave(newName: string) :void {
    if(!newName) {
      this.schemaNameRef.nativeElement.textContent = this.schemaForm.timeOffSchema.name;
      return;
    } else if( newName !== this.schemaForm.timeOffSchema.name) {
      this.schemaForm.timeOffSchema.name = this.schemaNameRef.nativeElement.textContent;
      this.schemaFormSave();
    }
  }

  private schemaFormSave() :void {
    this.timeoffSettingsService.updateSchemaName(this.schemaId, this.schemaForm.timeOffSchema.name).subscribe(
      (response: any) => {
        this.schemaForm.timeOffSchema.name = response.name;
        this.lastSchemaName = response.name;

        this.notificationService.showSuccessNotification();
      },
      (onError: any) => {
        this.schemaNameRef.nativeElement.focus();
        // Reset schema name to last name
        this.schemaForm.timeOffSchema.name = this.lastSchemaName;
        this.schemaNameRef.nativeElement.textContent = this.lastSchemaName;
        // Select the name
        var range = document.createRange();
        range.selectNodeContents(this.schemaNameRef.nativeElement);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
          
      }
    );
  }

  // PUBLIC METHODS

  onSchemaNameBlur(): void {
    this.checkSchemaNameAndSave(this.schemaNameRef.nativeElement.textContent);
  }

  onSchemaNameKeyDown(event: any) {
    if (this.schemaNameRef.nativeElement.textContent.length >= 64 && this.EDITION_ALLOWED_KEYS.indexOf(event.code) === -1) {
      event.preventDefault();
      return
    }
    if (event.code === 'Escape' || event.code === 'Enter') {
      if (event.code === 'Escape') {
          this.schemaNameRef.nativeElement.textContent = this.schemaForm.timeOffSchema.name;
      }
      this.schemaNameRef.nativeElement.blur();
    }
  }

  onSchemaNamePaste(event: any) :void {
    event.preventDefault();

    let selectedText = window.getSelection().toString();
    let textToPaste = event.clipboardData.getData('text');
    if (selectedText) {
      this.schemaNameRef.nativeElement.textContent = this.schemaNameRef.nativeElement.textContent.replace(selectedText, textToPaste.substring(0,64));
    } else {
      this.schemaNameRef.nativeElement.textContent += textToPaste.substring(0, 64 - this.schemaNameRef.nativeElement.textContent.length);
    }
    this.checkSchemaNameAndSave(this.schemaNameRef.nativeElement.textContent);
  }

  navigateTo(url: string): void {
    this.location.go(url);
  }

  loadTabContent( tab: number): void {
    if (this.selectedTab !== tab) {
      this.selectedTab = tab;
      if (tab === this.TIMEOFF_SCHEMA_TABS.GENERAL) {
        this.titleService.setTitle(this.translate.instant('schemes.tabs.general'));
        this._route.updateParams({ tabName: 'general' });
      } else if (tab === this.TIMEOFF_SCHEMA_TABS.ABSENCES) {
        this.titleService.setTitle(this.translate.instant('schemes.tabs.absences'));
        this._route.updateParams({ tabName: 'absences'});
      } else if (tab === this.TIMEOFF_SCHEMA_TABS.EMPLOYEES) {
        this.titleService.setTitle(this.translate.instant('schemes.tabs.employees'));
        this._route.updateParams({ tabName: 'employees'});
      }
    }
  }

  addEmployeesToSchema(): void {
    // get all the employees from schedule for pre-charge modal
    this.timeoffSettingsService.findAllEmployees(this.schemaId, {page: 0, size: 20000}, null).subscribe(
      (response) => {
          this.formUtilsService.finishSubmitAction();

          const dialogSettings = new MatDialogConfig();
          dialogSettings.data = {
              title: this.translate.instant('schemes.employees.modal.title'),
              advice: this.translate.instant('schemes.employees.modal.adviceText'),
              users: response.content
              };
          dialogSettings.width = '65vw';
          dialogSettings.position = { top: '4vh'};
          dialogSettings.panelClass = ['animated','slideInDown'];

          this.Dialog.open(ChatroomAdminsModalComponent, dialogSettings).afterClosed().subscribe(result => {
              this.formUtilsService.finishSubmitAction();

              if (result?.arePendingChanges && result?.addedUsers) {
                  this.schemaEmployees = result.addedUsers;
              }
          });
          },
      (error: any) => {
          // Show notification error
      }
  );
  }
}

type nextPeriod = {
  nextPeriodConfigKind?: number,
  startNextPeriodDay?: number | null,
  startNextPeriodMonth?: number | null
}

type schemaWorkdays = {
  fridayEnabled?: boolean,
  mondayEnabled?: boolean,
  saturdayEnabled?: boolean,
  sundayEnabled?: boolean,
  thursdayEnabled?: boolean,
  tuesdayEnabled?: boolean,
  wednesdayEnabled?: boolean,
}

type schema = {
  currentPeriod?: any,
  nextPeriod?: any,
  timeOffSchema: {
    active?: boolean, 
    allowBearRequests?: boolean,
    allowPendingDays?: boolean,
    allowPersonalDays?: boolean
    annualLeaveDays?: number,
    bearDaysAllowed?: number,
    daysKind?: number,
    defaultSchema?: boolean,
    deletable?: boolean,
    description?: string | null,
    enjoymentPeriods?: Array<any> | null,
    id?: number,
    limitPendingDay?: number,
    limitPendingMonth?: number,
    name?: string
    nextPeriodConfig?: nextPeriod,
    noticeContents?: any,
    pendingDaysAllowed?: number,
    personalDaysAllowed?: number,
    startBearDay?: number,
    startBearMonth?: number,
    totalEmployees?: number,
    workDays?: schemaWorkdays
  }
}