<div class="modal-header">
  <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
    <span class="button__icon icon icon-close"></span>
  </button>
  <h3 class="modal-title" [textContent]="'timeoff.teams.newTeam' | translate"></h3>
</div>

<div class="modal-body">
  <!-- Team name -->
  <div class="group-form group-form--label-150">
    <div class="group-form__label">
      <span class="group-form__label__text" [textContent]="'model.teams.newName' | translate"></span>
    </div>
    <div class="group-form__data">
      <input #teamName 
        id="teamName" 
        name="teamName"
        data-cy="team-name"
        type="text"
        class="input"  
        [ngClass]="{'input--error': isNameDuplicated}"
        maxlength="64"
        [(ngModel)]="teamForm.name"
        (ngModelChange)="onChange()" 
        (keyup)="isNameDuplicated = false" 
        (keyup.enter)="onEnterPressed()" 
        [placeholder]="'model.teams.titleplaceholder' | translate" />
    </div>
  </div>
  <!-- Team description -->
  <div class="group-form group-form--label-150">
    <div class="group-form__label">
      <span class="group-form__label__text" [translate]="'model.teams.description'"></span>
      <span class="group-form__label__text group-form__label__text--optional" [translate]="'global.form.optional'"></span>
    </div>
    <div class="group-form__data">
      <textarea id="teamDescription" class="textarea-autogrow" [(ngModel)]="teamForm.description" (ngModelChange)="onChange()"
        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
        [placeholder]="'model.teams.descriptionplaceholder' | translate" maxlength="160">
      </textarea>
    </div>
  </div>
  <!-- Workplace responsible -->
  <div class="group-form group-form--label-150 margin-bottom-0">
    <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" offset="10">
      <span class="group-form__label__text" [translate]="'timeoff.teams.grid.teamLeader'"></span>
      @if (isHolidaysModuleActive) {
        <span class="group-form__label__help icon icon-help-fill margin-left-10" [title]="'timeoff.teams.optionNoResponsibleTimeOffTooltip' | translate"></span>
      }
      <small class="group-form__label__text">({{'global.form.optional' | translate}})</small>
    </div>
    <div class="group-form__data">
      <user-dropdown class="min-width-100-percent" [(inputModel)]="teamForm.responsible"></user-dropdown>
    </div>
  </div>
</div>

<div class="modal-footer flex justify-end">
  <loading-button [buttonId]="'team-create'" [classes]="'button--secondary margin-right-10'" [text]="'model.teams.createTeam'"
    (action)="onAccept()" [isValid]="isFormValid">
  </loading-button>
  <button id="team-cancel" data-cy="team-cancel" type="button" class="button" (click)="onCancel()">
    <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
  </button>
</div>