<div class="main-container">
    <div class="main-container__header">
        <div class="flex align-items-start justify-space-between width-100-percent">
            <h2 [textContent]="'timeTracking.featureTitle' | translate"></h2>
            <div class="flex align-items-end">
                <loading-button [classes]="'button button--small'" [iconClass]="'icon-export'" 
                    [text]="'timeTracking.list.downloadReport'" (action)="openDownloadModal()" [isValid]="true">
                </loading-button>
            </div>
        </div>
    </div>
    <div class="main-container__content">
        <div class="main-container--full-width">
            <div class="filter-container margin-top-0">
                <div class="flex align-items-center">
                    <div class="button-bar">
                        @for (kind of dateFilterBtns; track kind) {
                            <button class="button button--small" [ngClass]="{'button--selected' : dateFilterBtnSelected === kind.id}"
                                (click)="onSetDateFilterBtn(kind)">
                                <span class="button__label" [textContent]="kind.text"></span>
                            </button>
                        }
                    </div>
                    <!-- // Inputs for date filters -->
                    @if (dateFilterBtnSelected === DATE_FILTER.DAILY) {
                        <button #beforeDate id="before" class="button button--small button--only-icon margin-left-24" (click)="onSetPrevOrNextDate(beforeDate)">
                            <span class="button__icon icon icon-arrow-left"></span>
                        </button>
                        <dialenga-date-picker #dayFilter inputId="dayFilter" class="margin-left-10 margin-right-10 height-32" 
                            style="box-shadow: 0 0px 0px 1px #d9d9d9; border-radius: 4px;" classes="height-32" [(inputModel)]="dataFilterOpts.date" 
                            [showTodayBtn]="true" [maxDate]="today" isDisabled="true" showWeekDay="true" (inputModelChange)="onSetDailyFilterDate()">
                        </dialenga-date-picker>
                        <button #nextDate id="next" class="button button--small button--only-icon" (click)="onSetPrevOrNextDate(nextDate)" [disabled]="dataFilterOpts.date >= today">
                            <span class="button__icon icon icon-arrow-right"></span>
                        </button>
                    }
                    @if (dateFilterBtnSelected === DATE_FILTER.MONTHLY || dateFilterBtnSelected === DATE_FILTER.WEEKLY) {
                        <!-- Year select -->
                        <span [translate]="'documents.list.filters.yearLabel'" class="margin-left-24 margin-right-5"></span>
                        <kendo-dropdownlist style="width: 100px" [data]="years" [(ngModel)]="dataFilterOpts.year"
                            class="kendo-angular-dropdown margin-right-20" (valueChange)="onSetYearFilter()"
                            [textField]="'name'" valueField="value" [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span [textContent]="dataItem?.name" [ngClass]="dataItem?.value ? 'featured-selection' : ''"></span>
                            </ng-template>
                            <ng-template kendoDropDownListItemTemplate let-dataItem >
                                <span class="padding-3" [textContent]="dataItem?.name"></span>
                            </ng-template>
                        </kendo-dropdownlist>
                        @if (dateFilterBtnSelected === DATE_FILTER.WEEKLY) {
                            <!-- Week select -->
                            <span [translate]="'documents.list.filters.weekLabel'" class="margin-right-5"></span>
                            <kendo-dropdownlist style="width: fit-content" [data]="weeksOfYear" [(ngModel)]="dataFilterOpts.week"
                                class="kendo-angular-dropdown" (valueChange)="onSetMonthOrWeekFilter()"
                                textField="" valueField="value" [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                    <div class="inline-flex align-items-center">
                                        <span class="week-number margin-right-8" [textContent]="dataItem?.value"></span>
                                        <span [textContent]="dataItem?.range.text"></span>
                                    </div>
                                </ng-template>
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                    <div class="flex align-items-center" [ngClass]="{'itemDisabled': dataFilterOpts.year.value == today.year() && dataItem?.value > today.week()}">
                                        <span class="week-number margin-right-8" [textContent]="dataItem?.value"></span>
                                        <span [textContent]="dataItem?.range.text"></span>
                                    </div>
                                </ng-template>
                            </kendo-dropdownlist>
                        }
                        @if (dateFilterBtnSelected === DATE_FILTER.MONTHLY) {
                            <!-- Month select -->
                            <span [translate]="'documents.list.filters.monthLabel'" class="margin-right-5"></span>
                            <kendo-dropdownlist style="width: fit-content" [data]="months" [(ngModel)]="dataFilterOpts.month"
                                class="kendo-angular-dropdown" (valueChange)="onSetMonthOrWeekFilter($event)"
                                [textField]="'name'" valueField="value" [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                    <span [textContent]="dataItem?.name" style="text-transform: capitalize;" [ngClass]="dataItem?.value ? 'featured-selection' : ''"></span>
                                </ng-template>
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                    <span class="capitalize padding-3" [textContent]="dataItem?.name"
                                    [ngClass]="{'itemDisabled': dataFilterOpts.year.value == today.year() && dataItem?.value > today.month() + 1}"></span>
                                </ng-template>
                            </kendo-dropdownlist>
                        }
                    }
                </div>
                <!-- Search name, username and advanced filter button -->
                <div class="search-filter-container align-items-center">
                    <button class="button button--small button--only-icon margin-right-10" (click)="setAdvancedFilter()" [ngClass]="{'button--secondary': showAdvancedFilter}"
                        kendoTooltip filter="span.icon" offset="10">
                        <span class="button__icon icon icon-18 icon-filter" [title]="'model.timeoffRequests.advancedFilter' | translate"></span>
                    </button>
                    <input type="text" class="input input--search width-70-percent" maxlength="1024" [placeholder]="'global.search.placeholder' | translate" [(ngModel)]="dataFilterOpts.advancedFilter.name"
                        (input)="listItemsSearch()" ngModelOptions="{ debounce: 200 }" [ngClass]="dataFilterOpts.advancedFilter.name?.length > 0 ? 'showClearBtn' : 'hideClearBtn'" [disabled]="timeTrackingDatagrid?.total < 1">
                    <div class="icon icon-remove" (click)="clearSearchInput()"></div>
                </div>
            </div>
            @if (showAdvancedFilter) {
                <div class="flex align-items-center">
                    <!-- Companies -->
                    <span [translate]="'users.company'" class="margin-right-5"></span>
                    <kendo-dropdownlist [defaultItem]="itemNotIndicated" [data]="companiesData" [(ngModel)]="dataFilterOpts.advancedFilter.subsidiaryCompany" style="width: auto"
                        class="kendo-angular-dropdown margin-right-20 max-width-200 min-width-120 margin-right-24" (valueChange)="onSelectAdvancedFilterItem()"
                        [textField]="'name'" valueField="id" [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                        <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <span class="truncate" [textContent]="dataItem?.name" [ngClass]="dataItem?.value ? 'featured-selection' : ''"></span>
                        </ng-template>
                        <ng-template kendoDropDownListItemTemplate let-dataItem >
                            <span class="padding-3" [textContent]="dataItem?.name"></span>
                        </ng-template>
                    </kendo-dropdownlist>
                    <!-- Headquarters -->
                    <span [translate]="'workplaces.grid.searchPlaceholder'" class="margin-right-5"></span>
                    <kendo-dropdownlist [defaultItem]="itemNotIndicated" [data]="headquartersData" [(ngModel)]="dataFilterOpts.advancedFilter.headquarter" style="width: auto"
                        class="kendo-angular-dropdown margin-right-20 max-width-200 min-width-120 margin-right-24" (valueChange)="onSelectAdvancedFilterItem()"
                        [textField]="'name'" valueField="id" [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                        <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <span class="truncate" [textContent]="dataItem?.name" [ngClass]="dataItem?.value ? 'featured-selection' : ''"></span>
                        </ng-template>
                        <ng-template kendoDropDownListItemTemplate let-dataItem >
                            <span class="padding-3" [textContent]="dataItem?.name"></span>
                        </ng-template>
                    </kendo-dropdownlist>
                    <!-- Departments -->
                    <span [translate]="'users.department'" class="margin-right-5"></span>
                    <kendo-dropdownlist [defaultItem]="itemNotIndicated" [data]="departmentsData" [(ngModel)]="dataFilterOpts.advancedFilter.department" style="width: auto"
                        class="kendo-angular-dropdown margin-right-20 max-width-200 min-width-120 margin-right-24" (valueChange)="onSelectAdvancedFilterItem()"
                        [textField]="'name'" valueField="id" [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                        <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <span class="truncate" [textContent]="dataItem?.name" [ngClass]="dataItem?.value ? 'featured-selection' : ''"></span>
                        </ng-template>
                        <ng-template kendoDropDownListItemTemplate let-dataItem >
                            <span class="padding-3" [textContent]="dataItem?.name"></span>
                        </ng-template>
                    </kendo-dropdownlist>
                    <!-- Teams -->
                    <span [translate]="'users.team'" class="margin-right-5"></span>
                    <kendo-dropdownlist [defaultItem]="itemNotIndicated" [data]="teamsData" [(ngModel)]="dataFilterOpts.advancedFilter.team" style="width: auto"
                        class="kendo-angular-dropdown margin-right-20 max-width-200 min-width-120 margin-right-24" (valueChange)="onSelectAdvancedFilterItem()"
                        [textField]="'name'" valueField="id" [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                        <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <span class="truncate" [textContent]="dataItem?.name" [ngClass]="dataItem?.value ? 'featured-selection' : ''"></span>
                        </ng-template>
                        <ng-template kendoDropDownListItemTemplate let-dataItem >
                            <span class="padding-3" [textContent]="dataItem?.name"></span>
                        </ng-template>
                    </kendo-dropdownlist>
                </div>
            }
            <!-- USers Time tracking datagrid -->
            @if (timeTrackingDatagrid?.data.length > 0) {
                <kendo-grid #timeTrackingGrid [data]="timeTrackingDatagrid" class="master-grid user-select-none margin-bottom-40"
                    [sortable]="false" [sort]="gridSort" (sortChange)="onSortChange($event)" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
                    [pageable]="totalPages > 1 ? gridPagerSettings : false" (pageChange)="onPageChange($event)" [skip]="dataPagingOpts.skip" [pageSize]="dataPagingOpts.size"
                    [navigable]="true" [resizable]="false" [selectable]="false" [loading]="isSyncing">
                    <kendo-grid-messages
                    [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate" [pagerOf]="'global.lists.pagerOf' | translate"
                    [pagerItems]="'global.lists.pagerItems' | translate">
                    </kendo-grid-messages>

                    <!-- Record state -->
                    <kendo-grid-column width="4" [class]="'cursor-pointer padding-0'" [sortable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="flex width-100-percent" style="height: 67px"
                                [ngClass]="{'bg-pending-color': !!dataItem.totalWorkdaySeconds && !!dataItem.totalWorkedSeconds && ((dataItem.totalWorkedSeconds + dataItem.timeOffSeconds) < dataItem.totalWorkdaySeconds),
                                            'bg-rejected-color': !!dataItem.totalWorkdaySeconds && !dataItem.totalWorkedSeconds}"></span>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Employee avatar and fullname -->
                    <kendo-grid-column width="250" class="cursor-default" sortable="false" [headerStyle]="{'pointer-events': 'none'}">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center cursor-pointer user-select-none" style="margin-left: 52px">
                                <span [textContent]="'conversations.header.sender' | translate"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <avatar [name]="dataItem.user.name" [surname]="dataItem.user.surname" [small]="false"
                                    [thumbnails]="{thumbnail_M: dataItem.user.avatarThumbnailMUrl || dataItem.user.avatarUrl, thumbnail_S: dataItem.user.avatarThumbnailSUrl || dataItem.user.avatarThumbnailMUrl}">
                                </avatar>
                                <span class="truncate margin-left-12" [textContent]="dataItem.user.fullName"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Total scheduled hours -->
                    <kendo-grid-column width="100" [sortable]="false">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center">
                                <span [translate]="'timeTracking.list.grid.scheduledHours'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <span [textContent]="dataItem.totalWorkdaySeconds | hoursMinutes:{inputKind: 'seconds', showSign: false}"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Total worked hours -->
                    <kendo-grid-column width="110" [sortable]="false">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center">
                                <span [translate]="'timeTracking.list.grid.workedHours'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <span [textContent]="dataItem.totalWorkedSeconds | hoursMinutes:{inputKind: 'seconds', showSign: false}"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Total timeoff hours -->
                    <kendo-grid-column width="110" [sortable]="false">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center">
                                <span [translate]="'timeTracking.list.grid.timeoffHours'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <span [textContent]="dataItem.timeOffSeconds | hoursMinutes:{inputKind: 'seconds', showSign: false}"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Balance -->
                    <kendo-grid-column width="110" [sortable]="false">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center padding-left-8">
                                <span [translate]="'timeTracking.list.grid.balance'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <span [textContent]="(dataItem.totalWorkedSeconds + dataItem.timeOffSeconds) - dataItem.totalWorkdaySeconds | hoursMinutes:{inputKind: 'seconds', showSign: true}"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Average column (monthly and weekly views) or Entry column (daily view)-->
                    <!-- <ng-container *ngIf="dateFilterBtnSelected !== DATE_FILTER.DAILY; then averageColumn else entryColumn"></ng-container>
                    <ng-template #averageColumn>
                    <kendo-grid-column width="100" [sortable]="false">
                        <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center padding-left-8">
                            <span [translate]="'timeTracking.list.grid.average'"></span>
                        </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center">
                            <span [textContent]="dataItem.average | hoursMinutes:{inputKind: 'minutes', showSign: false}"></span>
                        </div>
                        </ng-template>
                    </kendo-grid-column>
                    </ng-template>
                    <ng-template #entryColumn>
                    <kendo-grid-column width="100" [sortable]="false">
                        <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center padding-left-8">
                            <span [innerHTML]="'timeTracking.list.grid.entry' | translate"></span>
                        </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center">
                            <span [textContent]="dataItem.entryTime | hoursMinutes:{inputKind: 'minutes', showSign: false}"></span>
                        </div>
                        </ng-template>
                    </kendo-grid-column>
                    </ng-template> -->
                    <!-- Evolution graph (Hidden for now)-->
                    <kendo-grid-column width="200" [sortable]="false">
                        <ng-template kendoGridHeaderTemplate>
                            <!-- <div class="flex align-items-center">
                                <span [translate]="'timeTracking.list.grid.evolution'"></span>
                            </div> -->
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                        <!-- <ng-container *ngFor="let day of dataItem.dailyTimes">
                            <kendo-progressbar class="workedTime-vertical" [label]="false" [ngClass]="{'overworked': day.worked > day.scheduled}"
                                [max]="day.scheduled" [value]="day.worked" [orientation]="'vertical'">
                            </kendo-progressbar>
                            <ng-container *ngIf="day.worked > day.scheduled">
                                <kendo-progressbar class="overworkedTime-vertical" [label]="false"
                                [style.width.px]="day.worked - day.scheduled >= 40 ? 105 : day.worked - day.scheduled >= 20 ? 100 : 95"
                                [max]="1" [value]="1" [orientation]="'vertical'">
                                </kendo-progressbar>
                            </ng-container>
                        </ng-container> -->
                        </ng-template>
                    </kendo-grid-column>
                    <!-- CTA buttons column -->
                    <kendo-grid-command-column title="" width="40" [style]="{ 'text-align': 'end' }">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <div class="flex justify-end td-on-hover" kendoTooltip filter="a.k-master-button" offset="0">
                                <a class="k-button k-master-button" (click)="goDetailView(dataItem.user.id)" [title]="'conversations.detail' | translate">
                                    <span class="icon icon-eye-opened"></span>
                                </a>
                            </div>
                        </ng-template>
                    </kendo-grid-command-column>
                    <ng-template kendoGridNoRecordsTemplate></ng-template>
                    <!-- Custom Pager -->
                    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                        <article class="dui-grid-pager">
                            <kendo-pager-info></kendo-pager-info>
                            @if (timeTrackingDatagrid.total > 10) {
                                <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                                    <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                    <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                    <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                                </article>
                                <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                            }
                        </article>
                    </ng-template>
                </kendo-grid>
            }

            <!-- Skeleton time tracking list grid -->
            @if (timeTrackingDatagrid?.total == 0 && isSyncing) {
                <div>
                    <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
                        <!-- Record state -->
                        <kendo-grid-column [width]="4"></kendo-grid-column>
                        <!-- Employee avatar and fullname -->
                        <kendo-grid-column [width]="250" class="cursor-default">
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center cursor-pointer user-select-none" style="margin-left: 52px">
                                    <span [textContent]="'conversations.header.sender' | translate"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="circle" animation="pulse" [width]="40" [height]="40"></kendo-skeleton>
                                    <kendo-skeleton class="margin-left-12" shape="text" animation="pulse" [width]="150" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Scheduled hours -->
                        <kendo-grid-column [width]="100">
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center">
                                    <span [translate]="'timeTracking.list.grid.scheduledHours'"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="50" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Worked hours -->
                        <kendo-grid-column [width]="110">
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center">
                                    <span [translate]="'timeTracking.list.grid.workedHours'"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="50" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Total timeoff hours -->
                        <kendo-grid-column [width]="110" [sortable]="false">
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center">
                                    <span [translate]="'timeTracking.list.grid.timeoffHours'"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="50" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Balance -->
                        <kendo-grid-column [width]="110">
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center padding-left-8">
                                    <span [translate]="'timeTracking.list.grid.balance'"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="50" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Average -->
                        <!-- <kendo-grid-column width="100">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center padding-left-8">
                            <span [translate]="'timeTracking.list.grid.average'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                            <kendo-skeleton shape="text" animation="pulse" [width]="50" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                            </div>
                        </ng-template>
                        </kendo-grid-column> -->
                        <!-- Evolution graph -->
                        <kendo-grid-column width="200">
                            <ng-template kendoGridHeaderTemplate>
                                <!-- <div class="flex align-items-center">
                                    <span [translate]="'timeTracking.list.grid.evolution'"></span>
                                </div> -->
                            </ng-template>
                        </kendo-grid-column>
                        <!-- CTA column -->
                        <kendo-grid-command-column width="40"></kendo-grid-command-column>
                    </kendo-grid>
                </div>
            }
            <!-- No data information -->
            @if (timeTrackingDatagrid.total == 0 && !dataFilterOpts.advancedFilter.name && !isSyncing) {
                <no-results
                    [imageTitle]="'timeTracking.list.grid.noResultsTitle'" [description]="'timeTracking.list.grid.noResultsText'">
                </no-results>
            }
            @if (timeTrackingDatagrid.total == 0 && dataFilterOpts.advancedFilter.name && !isSyncing) {
                <no-search-results></no-search-results>
            }
        </div>
    </div>
</div>