<section class="main-container">
    <header class="main-container__header">
        <div>
            <ul class="breadcrumb">
                <li>
                    <a href="/documents" [translate]="'global.navbar.documents'"></a>
                </li>
            </ul>
            <h2 [translate]="'documents.import.title'"></h2>
        </div>
        @if (numConventions > 0) {
            <div class="flex align-items-center">
                <loading-button [buttonId]="'documents-history'" [classes]="'button button--small'" [iconClass]="'icon-list'" 
                    [text]="'documents.history.title'" [isValid]="true" (action)="navigateTo('/import-documents/history')">
                </loading-button>
            </div>
        }
    </header>

    <section class="main-container__content margin-top-24">
        <div class="main-container--small-width">
            <!-- Select file upload mode: Single or Multiple -->
            <section class="group-form group-form--label-220" 
                [ngClass]="uploadOptions.mode == UPLOAD_FILE_MODE.SINGLE && !showSelectForSingleFile && singleFileNameToUpload ? 'margin-bottom-32' : 'margin-bottom-24'">
                <div class="group-form__label">
                    <label class="group-form__label__text" [translate]="'documents.import.uploadMode'"></label>
                </div>
                <div class="group-form__data upload-type">
                    <section id="select-single" class="upload-type__item" [ngClass]="{'upload-type__item--selected' : uploadOptions.mode == UPLOAD_FILE_MODE.SINGLE}" 
                        (click)="setUploadFileMode(UPLOAD_FILE_MODE.SINGLE)">
                        <div class="upload-type__icon-box">
                            <span class="upload-type__icon icon nucleo icon-upload-document"></span>
                            <p class="upload-type__label" [translate]="'remoteSignature.mode.single'"></p>
                        </div>
                        <p class="upload-type__description" [translate]="'documents.import.uniqueFile'"></p>
                    </section>
                    <section id="select-multiple" class="upload-type__item" [ngClass]="{'upload-type__item--selected' : uploadOptions.mode == UPLOAD_FILE_MODE.MULTIPLE}" 
                        (click)="setUploadFileMode(UPLOAD_FILE_MODE.MULTIPLE)">
                        <div class="upload-type__icon-box">
                            <span class="upload-type__icon icon nucleo icon-upload-documents"></span>
                            <p class="upload-type__label" [translate]="'remoteSignature.mode.massive'"></p>
                        </div>
                        <p class="upload-type__description" [translate]="'documents.import.multipleFiles'"></p>
                    </section>
                </div>
            </section>

            <!-- Upload box -->
            <section class="group-form group-form--label-220 margin-bottom-32">
                <div class="group-form__label">
                    <label class="group-form__label__text" [translate]="'documents.import.filesSelection'"></label>
                </div>

                <div class="group-form__data group-form__data--column">
                    <!-- Convenctions notice -->
                    @if (uploadOptions.mode == UPLOAD_FILE_MODE.MULTIPLE) {
                        <div class="width-100-percent margin-top-8">
                            <!-- Info box if conventions have been established -->
                            @if (numConventions > 0) {
                                <section class="notification-box">
                                    <p class="margin-0">
                                        <span class="notification-box__text" [innerHTML]="'documents.help.multipleFilesNoticeStart' | translate"></span>
                                        <span class="notification-box__text link cursor-pointer" [translate]="'documents.help.namingConvention'" 
                                            (click)="openNamingConvention()">
                                        </span>
                                        <span class="notification-box__text" [translate]="'documents.help.multipleFilesNoticeEnd'"></span>
                                    </p>
                                </section>
                            }
                            <!-- Info box if conventions are NOT been established -->
                            @if (numConventions === 0) {
                                <section class="notification-box notification-box--warning">
                                    <div>
                                        <p class="notification-box__text" [innerHTML]="'documents.nameConvention.errorDescription' | translate"></p>
                                        <p class="notification-box__text margin-0" [innerHTML]="'documents.nameConvention.errorContactInfo' | translate"></p>
                                    </div>
                                </section>
                            }
                        </div>
                    }
                    <!-- Upload documents multiple files mode-->
                    @if (uploadOptions.mode == UPLOAD_FILE_MODE.MULTIPLE) {
                        <div class="width-100-percent">
                            <div class="files-upload new-drag-drop full-width fileUpload margin-top-8" 
                                [class.new-drag-drop--show-select]="!showSelectForMultipleFiles" (dragover)="onDragging($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                                @if (showSelectForMultipleFiles) {
                                    <div class="new-drag-drop__content">
                                        <span class="black-45 icon icon-24 icon-upload"></span>
                                        <span class="new-drag-drop__label margin-top-16" [translate]="'documents.import.dragMessageMultipleFiles'"></span>
                                        <span class="new-drag-drop__label" [translate]="'message.dragimageor'"></span>
                                        <label for="upload-multiple-files" class="new-drag-drop__button button button--small" [translate]="'documents.import.attachFiles'"></label>
                                    </div>
                                    <p class="new-drag-drop__dragging-message" [translate]="'documents.import.dropHere'"></p>
                                }
                                @if (!showSelectForMultipleFiles) {
                                    <div class="new-drag-drop__content">
                                        <span class="black-45 icon icon-24 icon-upload"></span>
                                        <div class="flex-align-items-center margin-top-16 text-center">
                                            <span id="total-upload-files" class="new-drag-drop__label block" [translate]="'documents.import.filesSelected'" 
                                                [translateParams]="{numFiles: numFilesToUpload}">
                                            </span>
                                            <span class="new-drag-drop__link link cursor-pointer block" [translate]="'documents.import.discard'" (click)="cleanFileInput()"></span>
                                        </div>
                                    </div>
                                }
                                <input type="file" #inputFile id="upload-multiple-files" name="upload-multiple-files" class="sr-only" multiple (change)="onInputFileChange($event)">
                            </div>
                            @if (showSelectForMultipleFiles) {
                                <div class="text-center margin-top-16">
                                    <span class="black-60" [translate]="'documents.import.directoryMessage'"></span>
                                    <input type="file" #inputFile id="directory" name="directory" class="sr-only" webkitdirectory directory multiple 
                                        (change)="onInputFileChange($event)">
                                    <label for="directory" class="directory-link margin-top-4" [translate]="'documents.import.attachDirectory'"></label>
                                </div>
                            }
                        </div>
                    }
                    <!-- Upload documents single file mode-->
                    @if (uploadOptions.mode == UPLOAD_FILE_MODE.SINGLE) {
                        <div class="width-100-percent">
                            <div class="files-upload new-drag-drop full-width fileUpload files-upload--show-select-cert margin-top-8" [ngClass]="{'d-none': !showSelectForSingleFile && singleFileNameToUpload}" (dragover)="onDragging($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                                <div class="new-drag-drop__content">
                                    <span class="black-45 icon icon-24 icon-upload"></span>
                                    <span class="new-drag-drop__label margin-top-16" [translate]="'documents.import.dragMessageSingleFile'"></span>
                                    <span class="new-drag-drop__label" [translate]="'message.dragimageor'"></span>
                                    <label for="upload-single-file" class="new-drag-drop__button button button--small" [translate]="'documents.import.attachFiles'"></label>
                                </div>
                                <p class="new-drag-drop__dragging-message" [translate]="'documents.import.dropHere'"></p>
                                <input type="file" #inputFile id="upload-single-file" name="upload-single-file" class="sr-only" accept="application/pdf" 
                                    (change)="onInputFileChange($event)">
                            </div>
                            @if (!showSelectForSingleFile && singleFileNameToUpload) {
                                <div class="flex align-items-center width-100-percent">
                                    <article class="added-file margin-right-16 overflow-hidden">
                                        <span class="icon icon-attachment black-45 icon-22"></span>
                                        <span class="added-file__filename truncate" [innerText]="singleFileNameToUpload"></span>
                                    </article>
                                    <button class="added-file__close icon icon-remove-fill black-45 icon-22" (click)="cleanFileInput()"></button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </section>

            <!-- Select kind document to upload -->
            @if (uploadOptions.mode == UPLOAD_FILE_MODE.SINGLE) {
                <section class="group-form group-form--label-220 margin-bottom-32">
                    <div class="group-form__label">
                        <label class="group-form__label__text" translate="documents.import.docType"></label>
                    </div>
                    <div class="group-form__data">
                        <div class="min-width-250">
                            <kendo-dropdownlist #documentKind name="docKind" class="dui-dropdown" [data]="documentKindsOptions" [(ngModel)]="uploadOptions.kind" 
                                (valueChange)="setDocumentKind($event)" valuePrimitive="true" [textField]="'name'" [valueField]="'id'" 
                                [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                    @if (dataItem) {
                                        <div class="flex align-items-center gap-8">
                                            <span class="icon" [ngClass]="{'icon-tag-fill-s': dataItem?.id, 'icon-tag-s icon-16': !dataItem?.id}" 
                                                [ngStyle]="{'color': getDocumentTagHexColor(dataItem?.color)}">
                                            </span>
                                            <span [textContent]="dataItem?.name"></span>
                                        </div>
                                    }
                                </ng-template>
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                    <div class="flex align-items-center gap-8">
                                        <span class="icon" [ngClass]="{'icon-tag-fill-s': dataItem?.id, 'icon-tag-s icon-16': !dataItem?.id}" 
                                            [ngStyle]="{'color': getDocumentTagHexColor(dataItem?.color)}">
                                        </span>
                                        <span [textContent]="dataItem?.name"></span>
                                    </div>
                                </ng-template>
                            </kendo-dropdownlist>
                        </div>
                    </div>
                </section>
            }

            <!-- Select date for documents in single upload mode -->
            @if (uploadOptions.mode == UPLOAD_FILE_MODE.SINGLE) {
                <section class="group-form group-form--label-220 margin-bottom-32">
                    <div class="group-form__label" kendoTooltip filter=".icon-help-fill" position="top">
                        <label class="group-form__label__text margin-right-8" [translate]="'documents.import.setDocumentDate'"></label>
                        <span class="group-form__label__help icon icon-help-fill" [title]="'documents.import.docDateTooltipAlways' | translate"></span>
                    </div>
                    <div class="group-form__data">
                        <toggle-input [inputId]="'setDocumentDateToggle'" [isDisabled]="false" [isSmall]="true" [(inputModel)]="uploadOptions.setDocumentDate"></toggle-input>
                        @if (uploadOptions.setDocumentDate) {
                            <dialenga-date-picker inputId="documents-date" class="input width-auto padding-0 margin-left-8" style="box-shadow: 0 0px 0px 1px #d9d9d9;" 
                                [(inputModel)]="uploadOptions.documentsDate" [showTodayBtn]="true">
                            </dialenga-date-picker>
                        }
                    </div>
                </section>
            }

            <!-- Replace documents -->
            <div class="group-form group-form--label-220 margin-bottom-32">
                <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'documents.import.ifExists'"></span>
                </div>
                <div class="group-form__data">
                    <div class="radio-buttons margin-top-8" [ngClass]="{'radio-buttons--disabled': isReplaceDisabled}">
                        <ng-container>
                            <input type="radio" id="keep" name="uploadMode" class="sr-only" [(ngModel)]="uploadOptions.replaceExisting" [value]="false">
                            <label for="keep" id="keep-label" class="radio-buttons__item margin-bottom-16" [ngClass]="{'radio-buttons__item--selected': !uploadOptions.replaceExisting}">
                                <span class="radio-buttons__radio icon-22 icon" [ngClass]="uploadOptions.replaceExisting ? 'icon-circle' : 'icon-circle-selected'"></span>
                                <span class="radio-buttons__label" [translate]="'documents.import.keepPreviousVersions'"></span>
                            </label>
                        </ng-container>
                        <ng-container>
                            <input type="radio" id="replace" name="uploadMode" class="sr-only" [(ngModel)]="uploadOptions.replaceExisting" [value]="true">
                            <label for="replace" class="radio-buttons__item" [ngClass]="{'radio-buttons__item--selected': uploadOptions.replaceExisting}">
                                <span class="radio-buttons__radio icon-22 icon" [ngClass]="uploadOptions.replaceExisting ? 'icon-circle-selected' : 'icon-circle'"></span>
                                <span class="radio-buttons__label" [translate]="'documents.import.replacePreviousVersions'"></span>
                            </label>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- Select documents to sign -->
            <section class="group-form group-form--label-220 margin-bottom-32" style="min-height: 36px;">
                <div class="group-form__label" kendoTooltip filter=".icon-help-fill" position="top">
                    <span class="group-form__label__text" [translate]="'global.navbar.signature'"></span>
                    <span class="group-form__label__help icon icon-help-fill margin-left-10" [title]="'documents.import.signatureTooltip' | translate"></span>
                    <small class="width-100 black-45 lowercase">({{ 'global.form.optional' | translate }})</small>
                </div>

                <div class="group-form__data direction-column align-items-start">
                    <section class="inline-flex align-items-center">
                        <toggle-input [inputId]="'setDocumentSignToggle'" class="flex align-items-center" [isDisabled]="false" [isSmall]="true" [(inputModel)]="uploadOptions.remoteSignature" style="min-height: 20px;" (inputModelChange)="handleRemoteSignatureToggle()"></toggle-input>
                        @if (!uploadOptions.remoteSignature) {
                            <span class="margin-left-6 black-60" [translate]="'documents.import.signatureNotRequired'"></span>
                        }
                    </section>

                    <!-- Options for remote signature -->
                    @if (uploadOptions.remoteSignature) {
                        <div class="flex direction-column align-items-start margin-top-32 width-100-percent">
                            <!-- Disclaimer if remote signature module is not in production mode -->
                            @if (!isSignatureProductionMode) {
                                <article class="signature-disclaimer margin-bottom-16">
                                    <div class="flex direction-column">
                                        <span class="bold margin-bottom-8" [translate]="'remoteSignature.advice.title'"></span>
                                        <span class="font-small" [translate]="'remoteSignature.advice.line1'"></span>
                                        <span class="font-small margin-top-8" [innerHTML]="'remoteSignature.advice.line2' | translate"></span>
                                    </div>
                                    <div class="flex justify-content-end margin-top-8">
                                        <button class="button button--small" type="button" (click)="showNewHireInfo()">
                                            <span class="button__label" [translate]="'activeFeatures.signature.howHiring'"></span>
                                        </button>
                                    </div>
                                </article>
                            }
                            <div class="margin-bottom-32">
                                <!-- Signature area -->
                                <div class="signature-data__title margin-bottom-16 width-100-percent">
                                    <div class="flex align-items-center" kendoTooltip filter=".icon-help-fill" position="top">
                                        <span class="signature-data__icon icon icon-signature-zone-s"></span>
                                        <span class="signature-data__label margin-bottom-0" [translate]="'remoteSignature.creation.signatureArea'"></span>
                                        <span class="signature-data__icon icon icon-help-fill margin-left-10" 
                                            [title]="'remoteSignature.creation.signatureAreaTooltip' | translate">
                                        </span>
                                    </div>
                                </div>
                                <div class="group-form__data width-100-percent">
                                    <div class="min-width-250 width-75-percent margin-left-24">
                                        <kendo-dropdownlist #signatureArea name="signature-area" class="dui-dropdown" [data]="signatureAreasOptions" 
                                            [(ngModel)]="uploadOptions.location"  (valueChange)="onAreaSelected()" [textField]="'name'" [valueField]="'id'" [defaultItem]="emptyOption"
                                            [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                            <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                                @if (!dataItem.id) {
                                                    <span class="black45" [translate]="'documents.import.attachFiles'"></span>
                                                }
                                                @if (!!dataItem.id) {
                                                    <div class="flex align-items-center list-item-height">
                                                        <span class="icon icon-20 nucleo margin-right-10 black-45" 
                                                            [ngClass]="{'icon-anchor': dataItem.anchor, 'icon-signature-zone-s': !dataItem.anchor}">
                                                        </span>
                                                        <span class="truncate black-85" [ngClass]="{'cursive-text': !dataItem.id}" style="max-width: 400px" 
                                                            [textContent]="dataItem.name">
                                                        </span>
                                                    </div>
                                                }
                                            </ng-template>
                                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                                <div class="flex align-items-center list-item-height">
                                                    @if (!!dataItem.id) {
                                                        <span class="icon icon-20 nucleo margin-right-10 black-45" 
                                                            [ngClass]="{'icon-anchor': dataItem.anchor, 'icon-signature-zone-s': !dataItem.anchor}">
                                                        </span>
                                                    }
                                                    <span class="truncate black-85" [ngClass]="{'cursive-text': !dataItem.id}" style="max-width: 400px" [textContent]="dataItem.name"></span>
                                                </div>
                                            </ng-template>
                                        </kendo-dropdownlist>
                                    </div>
                                </div>
                                @if (!uploadOptions.location) {
                                    <div class="notification-box direction-column align-items-start margin-left-24 margin-top-16 margin-bottom-0 width-auto">
                                        <span class="notification-box__title" [translate]="'remoteSignature.creation.noSignatureAreaTitle'"></span>
                                        <span class="notification-box__text" [translate]="'remoteSignature.creation.noSignatureAreaDescription'"></span>
                                    </div>
                                }
                                @if (uploadOptions.location && uploadOptions.location.id) {
                                    <div class="margin-left-24">
                                        <div class="signature-data__info">
                                            <span class="margin-right-4" [translate]="'remoteSignature.creation.signature'"></span>
                                            @if (!!uploadOptions.location.anchor) {
                                                <span [translate]="'remoteSignature.creation.signatureAreaAnchor'" [translateParams]="{anchor: uploadOptions.location.anchor}"></span>
                                            }
                                            @if (!uploadOptions.location.anchor) {
                                                <span [translate]="'remoteSignature.creation.signatureAreaPage'" [translateParams]="{page: uploadOptions.location.page}"></span>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <!-- Short message -->
                            <div class="width-100-percent margin-bottom-32">
                                <div class="signature-data__title">
                                    <div class="flex align-items-center">
                                        <span class="signature-data__icon icon icon-comment-fill-s"></span>
                                        <span class="signature-data__label margin-bottom-0" [translate]="'remoteSignature.creation.shortMessageTitle'"></span>
                                    </div>
                                </div>
                                <div class="padding-left-25">
                                    <textarea id="short-message-textarea" class="textarea" [(ngModel)]="uploadOptions.title" style="resize: vertical;" maxlength="64" 
                                        placeholder="{{'message.captionPlaceholder' | translate}}">
                                    </textarea>
                                </div>
                            </div>
                            <!-- Remote signature more options section -->
                            @if (!showSignatureMoreOptions) {
                                <div class="flex align-items-center">
                                    <div id="signature-more-options-dropdown" class="flex align-items-center" (click)="showSignatureMoreOptions = true">
                                        <span class="soft-title info-color cursor-pointer" [translate]="'remoteSignature.moreOptions'"></span>
                                        <span class="span icon icon-arrow-down info-color cursor-pointer margin-left-10"></span>
                                    </div>
                                </div>
                            }
                            @if (showSignatureMoreOptions) {
                                <!-- Extended message -->
                                <div class="margin-bottom-32 width-100-percent">
                                    <div class="signature-data__title margin-bottom-5">
                                        <div class="flex align-items-center">
                                            <span class="signature-data__icon icon icon-comment-fill-s"></span>
                                            <span class="signature-data__label margin-bottom-0" [translate]="'remoteSignature.creation.extendedMessageTitle'"></span>
                                        </div>
                                    </div>
                                    <div class="padding-left-24">
                                        <div class="textarea-editor-container--no-mentions textarea-editor-container--no-image">
                                            <dialenga-textarea class="new-rich-editor" textareaId="kendoTextEditor" [(inputModel)]="uploadOptions.message" [isRichFormat]="true"
                                                [showLinkBtns]="true" [hideActionsBtns]="true">
                                            </dialenga-textarea>
                                        </div>
                                    </div>
                                </div>
                                <!-- Reminder -->
                                <div class="margin-bottom-24 width-100-percent">
                                    <div class="flex direction-row align-items-center">
                                        <div class="flex align-items-center">
                                            <span class="signature-data__icon icon icon-reminder-s"></span>
                                            <span class="signature-data__label signature-data__label--toggle" [translate]="'remoteSignature.creation.reminders'"></span>
                                        </div>
                                        <toggle-input [inputId]="'signatureReminder'" [(inputModel)]="uploadOptions.reminder.reminderEnabled" [isSmall]="true"></toggle-input>
                                    </div>
                                    <div class="flex align-items-center padding-left-24" [ngClass]="{'margin-top-20': uploadOptions.reminder.reminderEnabled, 'margin-top-12': !uploadOptions.reminder.reminderEnabled}">
                                        <span class="signature-data__label signature-data__label--secondary margin-bottom-0"
                                            [translate]="uploadOptions.reminder.reminderEnabled ? 'remoteSignature.creation.remindAfter' : 'remoteSignature.creation.noReminders'">
                                        </span>
                                        @if (uploadOptions.reminder.reminderEnabled) {
                                            <input class="signature-data__input-number" ng-focus="selectOnClick($event)" type="number" [(ngModel)]="uploadOptions.reminder.reminderAmount" 
                                                min="1" max="59" (keyup)="daysInputValidation($event)">
                                        }
                                        @if (uploadOptions.reminder.reminderEnabled) {
                                            <span class="signature-data__label signature-data__label--secondary margin-0" [translate]="'users.holidays.days'" 
                                                [translateParams]="{days: uploadOptions.reminder.reminderAmount}">
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div class="width-100-percent">
                                    <!-- Expiration date advance -->
                                    <div class="flex align-items-center" [ngClass]="{'margin-bottom-16': !uploadOptions.expiration.expirationEnabled, 'margin-bottom-20': uploadOptions.expiration.expirationEnabled}">
                                        <section class="flex align-items-center">
                                            <span class="signature-data__icon icon icon-timer-s"></span>
                                            <span class="signature-data__label signature-data__label--toggle" [translate]="'remoteSignature.creation.expirationDateAdvance'"></span>
                                        </section>
                                        <toggle-input [inputId]="'signatureExpirationAdvance'" [(inputModel)]="uploadOptions.expiration.expirationEnabled" [isSmall]="true"></toggle-input>
                                    </div>
                                    @if (!uploadOptions.expiration.expirationEnabled) {
                                        <div class="notification-box margin-left-24 width-auto margin-bottom-0">
                                            <span class="notification-box__text" [translate]="'remoteSignature.creation.expirationDateAdvanceDescription'"></span>
                                        </div>
                                    }
                                    @if (!!uploadOptions.expiration.expirationEnabled) {
                                        <div class="margin-left-24">
                                            <div class="flex align-items-baseline">
                                                <span class="signature-data__label signature-data__label--secondary min-width-100 margin-right-16" [translate]="'remoteSignature.creation.expirationMode'"></span>
                                                <div class="button-bar button-bar--small margin-right-20">
                                                    <button class="button" type="button" [ngClass]="{'button--selected': uploadOptions.expiration.expirationKind == AFTER_N_DAYS}" (click)="uploadOptions.expiration.expirationKind = AFTER_N_DAYS">
                                                        <span class="button__label" [translate]="'remoteSignature.creation.expirationAfterDays'"></span>
                                                    </button>
                                                    <button class="button" type="button" [ngClass]="{'button--selected': uploadOptions.expiration.expirationKind == EXACT_DATE}" (click)="uploadOptions.expiration.expirationKind = EXACT_DATE">
                                                        <span class="button__label" [translate]="'remoteSignature.creation.expirationInDate'"></span>
                                                    </button>
                                                </div>
                                            </div>
                                            @if (uploadOptions.expiration.expirationKind == AFTER_N_DAYS) {
                                                <div class="flex align-items-center min-height-36">
                                                    <span class="signature-data__label signature-data__label--secondary min-width-100 margin-right-16 margin-bottom-0" 
                                                        [translate]="'remoteSignature.creation.expirationElapsed'">
                                                    </span>
                                                    <input class="signature-data__input-number margin-left-0" (focus)="selectOnClick($event)" type="number" 
                                                        [(ngModel)]="uploadOptions.expiration.expirationAmount" min="1" max="59" (keyup)="daysInputValidation($event)">
                                                    <span class="signature-data__label signature-data__label--secondary margin-bottom-0" 
                                                        [translate]="'users.holidays.days'" [translateParams]="{days: uploadOptions.expiration.expirationAmount}">
                                                    </span>
                                                </div>
                                            }
                                            @if (uploadOptions.expiration.expirationKind == EXACT_DATE) {
                                                <div class="flex align-items-center">
                                                    <span class="signature-data__label signature-data__label--secondary min-width-100 margin-right-16 margin-bottom-0" 
                                                        [translate]="'remoteSignature.creation.expirationDate'">
                                                    </span>
                                                    <dialenga-date-picker inputId="expiration-date" class="input padding-0 width-auto" style="box-shadow: 0 0px 0px 1px #d9d9d9;" 
                                                        [(inputModel)]="uploadOptions.expiration.expirationDate" [showTodayBtn]="true" [minDate]="expirationDateMin" [maxDate]="expirationDateMax">
                                                    </dialenga-date-picker>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </section>

            <!-- Send Button -->
            <div class="group-form group-form--label-220 margin-bottom-56">
                <div class="group-form__label">
                    <span class="group-form__label__text"></span>
                </div>
                <div class="group-form__data">
                    <loading-button [buttonId]="'upload-files'" [classes]="'button button--cta'" [text]="'global.form.send'" 
                        (action)="uploadFiles()" [isValid]="areUploadOptionsValid()">
                    </loading-button>
                </div>
            </div>
        </div>
    </section>
</section>