<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="'chatrooms.modal.create.title' | translate"></h3>
</div>
<div class="modal-body">
    <!-- Chatroom name -->
    <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text" [textContent]="'model.segment.name' | translate"></span>
        </div>
        <div class="group-form__data">
            <input #chatName 
                id="chatName" 
                name="chatName"
                data-cy="chat-name"
                type="text"
                class="input"  
                [ngClass]="{'input--error': isNameDuplicated}"
                maxlength="64" 
                [(ngModel)]="chatroomForm.name" 
                (ngModelChange)="onChange()" 
                (keyup)="isNameDuplicated = false" 
                (keyup.enter)="onEnterPressed()" 
                [placeholder]="'chatrooms.modal.create.nameplaceholder' | translate" />
        </div>
    </div>
    <!-- Chatroom description -->
    <!-- <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text" [textContent]="'model.segment.description' | translate"></span>
            <span class="group-form__label__text group-form__label__text--optional" [textContent]="'global.form.optional' | translate"></span>
        </div>
        <div class="group-form__data">
            <textarea id="chatroomDescription" class="textarea-autogrow width-360" [(ngModel)]="chatroomForm.description" 
                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                [placeholder]="'chatrooms.modal.create.descriptionplaceholder' | translate" maxlength="140"> 
           </textarea>
        </div>
    </div> -->
    <!-- Chatroom private toggle -->
    <div class="group-form group-form--label-150 margin-bottom-0">
        <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" position="top">
            <span class="group-form__label__text margin-right-10" [textContent]="'chatrooms.modal.create.privateRoom' | translate"></span>
            <span class="group-form__label__help icon icon-help-fill" [title]="'chatrooms.modal.create.privatetooltip' | translate"></span>
        </div>
        <div class="group-form__data margin-bottom-10">
            <toggle-input id="exclusive-room" [inputId]="'exclusive-room'" 
                [(inputModel)]="chatroomForm.exclusive"></toggle-input>
        </div>
    </div>
</div>
<div class="modal-footer flex justify-end">
    <loading-button [buttonId]="'create-chatroom'" [classes]="'button--secondary margin-right-10'" [text]="'chatrooms.createChatroom'" 
        (action)="onAccept()" [isValid]="isFormValid">
    </loading-button>
    <button id="cancel-chatroom" type="button" class="button" (click)="onCancel()">
        <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
    </button>
</div>