<div class="main-container main-container--dual-panel">
    <section class="main-container__header align-items-center">
        <div class="requests-stats-funnel">
            <article class="requests-stats-funnel__card">
                <h3 class="requests-stats-funnel__card-title" [translate]="'conversations.boxes.news'"></h3>
                <p class="requests-stats-funnel__card-data" [textContent]="requestsStats.newConversations"></p>
            </article>
            <article class="requests-stats-funnel__card">
                <h3 class="requests-stats-funnel__card-title" [translate]="'conversations.boxes.noAnswer'"></h3>
                <p class="requests-stats-funnel__card-data requests-stats-funnel__card-data--pending" [textContent]="requestsStats.withoutAnswer"></p>
            </article>
            <article class="requests-stats-funnel__card">
                <h3 class="requests-stats-funnel__card-title" [translate]="'conversations.boxes.averageRating'"></h3>
                @if (requestsStats.avgRating) {
                    <show-rating-icon [value]="requestsStats.avgRating" [showTitle]="false"></show-rating-icon>
                }
                <p class="requests-stats-funnel__card-data" [textContent]="requestsStats.avgRating | numberToDecimal: 2"></p>
            </article>
        </div>
        <div class="flex align-items-center" kendoTooltip filter="button" position="bottom">
            <button #createRequest id="create-request" data-cy="create-request" class="button button--secondary button--small" (click)="createNewRequest()">
                <span class="button__icon icon icon-add"></span>
                <span class="button__label" [translate]="'conversations.newConversation'"></span>
            </button>
            <loading-button [buttonId]="'refresh-button'" (action)="getRequestsList(dataPagingOpts, dataFilterOpts)" [title]="'global.messages.refreshData.tooltip' | translate"
                [classes]="'button button--small button--only-icon margin-left-10'" [iconClass]="'icon-sync'" [isValid]="true">
            </loading-button>
            <button class="button button--small button--only-icon margin-left-10" (click)="exportRequests()" [title]="'conversations.downloadCSV' | translate">
                <span class="button__icon icon icon-export"></span>
            </button>
        </div>
    </section>

    <section class="main-container__content">
        <!-- Requests list (left panel) -->
        <article class="main-container__content--left-panel">
            <!-- Filters and search for requests list -->
            <div class="requests-list__header">
                <div class="first-row-filter relative">
                    <div class="flex align-items-center min-width-70-percent">
                        <!-- Tray selection filter -->
                        <kendo-dropdownlist #trayFilter data-cy="tray-filter" [defaultItem]="defaultTrayOption" [data]="trayOptions" [(ngModel)]="traySelected"
                            class="kendo-angular-dropdown margin-right-8" [valuePrimitive]="false" (valueChange)="onTrayFilterSelected($event)"
                            [textField]="'tray'" valueField="id" [popupSettings]="{ width: 'auto'}">
                            <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span class="truncate" [textContent]="dataItem.tray"></span>
                            </ng-template>
                            <ng-template kendoDropDownListItemTemplate let-dataItem >
                                <div class="flex align-items-center min-height-28">
                                    <span class="truncate padding-3" [textContent]="dataItem.tray"></span>
                                </div>
                            </ng-template>
                        </kendo-dropdownlist>
                        <!-- Motive selection filter -->
                        <kendo-dropdownlist #motiveFilter data-cy="motive-filter" [defaultItem]="defaultMotiveOption" [data]="motiveOptions" [(ngModel)]="dataFilterOpts.motive"
                            class="kendo-angular-dropdown" valuePrimitive="true" (valueChange)="getRequestsList(dataPagingOpts, dataFilterOpts)"
                            [textField]="'motive'" valueField="id" [popupSettings]="{ width: 'auto'}">
                            <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                @if (dataItem.id) {
                                    <span class="icon icon-20 icon-tag-fill-s margin-right-10" [ngStyle]="{'color': getMotiveTagHexColor(dataItem.color)}"></span>
                                }
                                <span class="truncate" [textContent]="dataItem.motive"></span>
                            </ng-template>
                            <ng-template kendoDropDownListItemTemplate let-dataItem >
                                <div class="flex align-items-center min-height-28">
                                    @if (dataItem.id) {
                                        <span class="icon icon-20 icon-tag-fill-s margin-right-10" [ngStyle]="{'color': getMotiveTagHexColor(dataItem.color)}"></span>
                                    }
                                    <span class="truncate" [textContent]="dataItem.motive"></span>
                                </div>
                            </ng-template>
                        </kendo-dropdownlist>
                    </div>
                    @if (dataFilterOpts.state === REQUEST_STATE.OPEN && isValidationActive) {
                        <div data-cy="request-validation-filter" class="flex filters-buttons-bar small-select-container select-simulation-container no-border margin-left-10 padding-right-24"
                            kendoTooltip filter="toggle-input" offset="8">
                            <toggle-input class="margin-right-2" [inputId]="'filter-by-validation'" [(inputModel)]="applyFilterValidation" 
                                (inputChanged)="onApplyFilterValidationChange()" [isSmall]="true" [title]="'conversations.tooltips.toggleValidationState' | translate">
                            </toggle-input>
                            <div class="multiselect-options min-width-50" [ngClass]="{ 'disabled': !applyFilterValidation }">
                                <span class="box-shadow__legend-circle flex align-items-start bg-approved-color"></span>
                                <span class="box-shadow__legend-circle flex align-items-start bg-rejected-color margin-left-8"></span>
                            </div>
                        </div>
                    }
                    @if (dataFilterOpts.state === REQUEST_STATE.CLOSED && isValidationActive) {
                        <div class="flex align-items-center margin-right-8">
                            <kendo-multiselect class="requests-validation-states" [data]="validationStateOptions" [(ngModel)]="selectedValidationStates"
                                [textField]="'name'" [valueField]="'id'" [autoClose]="false" [valuePrimitive]="true" [clearButton]="false"
                                (valueChange)="onChangeValidationStates($event)" [itemDisabled]="validationStateDisabled"
                                [popupSettings]="{ width: 'auto', popupClass: 'popup-validation-state' }">
                                <kendo-multiselect-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-multiselect-messages>


                                <ng-template kendoMultiSelectTagTemplate let-dataItem>
                                    <span class="box-shadow__legend-circle flex align-items-start" [ngClass]="dataItem.class" kendoTooltip offset="8" 
                                        [title]="dataItem.name | translate">
                                    </span>
                                </ng-template>
                                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                    <div id="validation-multiple-filter" class="popup-validation-state__item min-height-28">
                                        <span class="box-shadow__legend-circle vertical-align-label margin-right-10" [ngClass]="dataItem.class"></span>
                                        <span class="popup-validation-state__label" [translate]="dataItem.name"></span>
                                        <label [id]="'validation-toggle-'+ dataItem.id" class="toggle__container popup-validation-state__switch margin-left-5 align-items-right">
                                            <div class="toggle toggle--small" [ngClass]="{'toggle--active': dataItem.enabled}" style="width: 12px !important;">
                                                <span class="toggle__indicator"></span>
                                            </div>
                                        </label>
                                    </div>
                                </ng-template>
                            </kendo-multiselect>
                        </div>
                    }
                </div>
                <div class="last-row-filter margin-top-16">
                    <div class="tab-nav tab-nav--no-border">
                        <span id="requests-opened" class="tab-nav__item font-size-13 black-60"
                            [ngClass]="{'tab-nav__item--selected': dataFilterOpts.state === REQUEST_STATE.OPEN}" (click)="onSelectFilterState(REQUEST_STATE.OPEN)">
                            <p class="tab-nav__label margin-bottom-0" [translate]="'conversations.open'"></p>
                        </span>
                        <span id="requests-closed" class="tab-nav__item font-size-13 black-60"
                            [ngClass]="{'tab-nav__item--selected': dataFilterOpts.state === REQUEST_STATE.CLOSED}" (click)="onSelectFilterState(REQUEST_STATE.CLOSED)">
                            <p class="tab-nav__label margin-bottom-0" [translate]="'conversations.closed'"></p>
                        </span>
                    </div>
                    <!-- Search by keyword filter -->
                    <div class="search-grow-container" [ngClass]="{'opened': inputSearchOpened}">
                        <input #inputSearch id="input-search" type="text" maxlength="1024" [placeholder]="'conversations.search' | translate" [(ngModel)]="dataFilterOpts.name"
                            (input)="searchRequestsByName(false)" ngModelOptions="{ debounce: 200 }" [disabled]="requestsList.data.length == 0 && !dataFilterOpts.name && !isSyncing"
                            (blur)="!dataFilterOpts.name && inputSearchOpened = !inputSearchOpened" autocomplete="off">
                        @if (inputSearchOpened && !!dataFilterOpts.name) {
                            <span class="icon icon-20 icon-remove black60" (click)="searchRequestsByName(true)"></span>
                        }
                        @if (!inputSearchOpened || (inputSearchOpened && !dataFilterOpts.name)) {
                            <span id="input-search-icon" data-cy="input-search-icon" class="icon icon-18 icon-search black-45" (click)="openInputSearch()"
                                [ngClass]="{'disabled-events': inputSearchOpened || requestsList.data.length === 0, 'disabled': requestsList.data.length === 0}">
                            </span>
                        }
                    </div>
                    <dui-popover data-cy="request-advanced-filter" [onlyOnClick]="true" class="popover-requests-filter align-self-center">
                        <ng-container popoverItem>
                            <span id="icon-menu-filter" class="icon icon-24 cursor-pointer black-45"
                            [ngClass]="filterOptionSelected.value === REQUESTS_FILTER_MENU.ALL ? 'icon-filter-lines' : filterOptionSelected.icon"></span>
                        </ng-container>
                        <div class="popover-requests-filter__container">
                            @for (option of requestsFiltersMenu; track option) {
                                <div class="popover-requests-filter__item" (click)="onSelectFilter(option)"
                                    [ngClass]="{'disabled-events': option.value === filterOptionSelected.value}">
                                    <span class="icon icon-24 black-45" [ngClass]="option.icon"></span>
                                    <span [textContent]="option.text"></span>
                                    <span class="icon icon-16" [ngClass]="{'icon-accept': option.value === filterOptionSelected.value}"></span>
                                </div>
                            }
                            <span class="divider"></span>
                            <div class="popover-requests-filter__item" (click)="onSelectListOrder(ORDER_LIST.RECENT_FIRST)">
                                <span></span>
                                <span [translate]="'conversations.filters.orderRecentFirst'"></span>
                                <span class="icon icon-16" [ngClass]="{'icon-accept': orderListSelected === ORDER_LIST.RECENT_FIRST}"></span>
                            </div>
                            <div class="popover-requests-filter__item" (click)="onSelectListOrder(ORDER_LIST.OLDER_FIRST)">
                                <span></span>
                                <span [translate]="'conversations.filters.orderOlderFirst'"></span>
                                <span class="icon icon-16" [ngClass]="{'icon-accept': orderListSelected === ORDER_LIST.OLDER_FIRST}"></span>
                            </div>
                        </div>
                    </dui-popover>
                </div>
            </div>
            <div class="requests-list__container scroll7" infiniteScroll [infiniteScrollDistance]="1.25" [infiniteScrollThrottle]="150"
                [scrollWindow]="false" (scrolled)="onScrollRequestsList()"
                [infiniteScrollDisabled]="isSyncing || (dataPagingOpts.page*dataPagingOpts.size) >= requestsList.total">
                <!-- Skeleton -->
                @if (isSyncing) {
                    @for (item of dataSkeleton; track item) {
                        <div class="request-item" [ngClass]="{'opacity-50': $index === 1, 'opacity-30': $index === 2}">
                            <section class="request-item__sender-info">
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="circle" animation="pulse" [width]="36" [height]="36"></kendo-skeleton>
                                    <kendo-skeleton class="margin-left-16" shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </section>
                            <div class="request-item__wrapper">
                                <kendo-skeleton class="request-item__last-message" shape="text" animation="pulse" [width]="350" [height]="16" style="border-radius: 4px"></kendo-skeleton>
                                <section class="request-item__motive-tray margin-top-8" style="height: 24px">
                                    <div class="flex align-items-center">
                                        <kendo-skeleton shape="text" animation="pulse" [width]="16" [height]="24" style="border-radius: 4px"></kendo-skeleton>
                                        <kendo-skeleton class="margin-left-8" shape="text" animation="pulse" [width]="120" [height]="24" style="border-radius: 4px"></kendo-skeleton>
                                    </div>
                                </section>
                            </div>
                        </div>
                    }
                }
                <!-- Requests -->
                @for (request of requestsList.data; track request; let idx = $index) {
                    <article class="request-item" (click)="selectRequest(request, idx)"
                        [ngClass]="{'no-response': isRequestWithoutResponse(request), 'selected': idx === selectedRequest.idx,
                                    'state-pending': request.validationState === REQUEST_VALIDATION_STATE.PENDING, 
                                    'state-validated': request.validationState === REQUEST_VALIDATION_STATE.VALIDATED,
                                    'state-rejected': request.validationState === REQUEST_VALIDATION_STATE.REJECTED}">
                        <section class="request-item__sender-info">
                            @if (!request.anonymous && !!request.author) {
                                <user-avatar [medium]="true" [user]="request.author"></user-avatar>
                            }
                            @if (request.anonymous || !request.author) {
                                <user-avatar [medium]="true" [user]="{name: 'global.userstates.anonymous' | translate, surname: '' }" [anonymous]="true"></user-avatar>
                            }
                            <div class="flex align-items-center direction-reverse">
                                <span class="font-size-10 black-45" [textContent]="request.lastMessageOn | date:'dd/MM/yyyy HH:mm'"></span>
                                @if (request.hasAttachments || request.hasImages) {
                                    <span class="black-45 icon icon-18 icon-attachment margin-right-8"></span>
                                }
                                @if (request.numberOfUnreadMessages) {
                                    <span class="request-item__dot-unread margin-right-8" [textContent]="request.numberOfUnreadMessages"></span>
                                }
                            </div>
                        </section>
                        <div class="request-item__wrapper">
                            <p class="request-item__last-message font-size-13 truncate" [ngClass]="{'font-medium black': request.numberOfUnreadMessages > 0 && request.closedOn == null}" [ngStyle]="{ 'font-style': !request.lastMessage ? 'italic' : 'normal' }" [innerHTML]="getTitleWithoutFormat(request)"></p>
                            <section class="request-item__motive-tray margin-top-8" kendoTooltip filter="span">
                                <div class="flex align-items-center">
                                    <div class="request-item__motive-icon margin-right-8">
                                        <span class="icon icon-16 icon-tag-fill-s" [ngStyle]="{'color': getMotiveTagHexColor(request.minimalMotive?.color)}" [title]="request.minimalMotive.alias"></span>
                                    </div>
                                    @if (request.anonymous) {
                                        <span class="icon icon-heisenberg icon-22 margin-right-4 black-60" [title]="'feedbackTrays.isAnonymous' | translate"></span>
                                    }
                                    <span class="black-45 icon icon-24" [ngClass]="{'icon-request-folder': request.numberOfUnreadMessages <= 0, 'icon-request-folder-unread': request.numberOfUnreadMessages > 0}"></span>
                                    <p class="request-item__tray-name margin-left-4" [textContent]="request.minimalTray.nameInList"></p>
                                </div>
                                @if (request.rating) {
                                    <show-rating-icon [value]="request.rating" [showTitle]="true"></show-rating-icon>
                                }
                            </section>
                        </div>
                    </article>
                }
                @if (requestsList.total == 0 && !dataFilterOpts.name && !isSyncing) {
                    <no-results [imageTitle]="'conversations.noResultsTitle'"></no-results>
                }
                @if (requestsList.total == 0 && dataFilterOpts.name && !isSyncing) {
                    <no-search-results></no-search-results>
                }
            </div>
        </article>
        <!-- Request detail (right panel) -->
        <article class="main-container__content--right-panel">
            <request-detail [requestData]="selectedRequest.data" (trayOrMotiveChanged)="onTrayOrMotiveChange($event)"
                (reloadData)="onReloadDataEvent($event)" (validationChanged)="onRequestValidationChange($event)">
            </request-detail>
        </article>
    </section>
</div>