import * as angular from 'angular';
import { downgradeInjectable, downgradeComponent } from '@angular/upgrade/static';

import { CategoriesTreeService } from './components/categories-tree/categories-tree.service';
import { FormUtilsService } from './shared/services/form-utils.service';
import { SharedDataService } from './shared/services/shared-data.service';
import { DialogService } from './shared/services/dialog.service';
import { SpinnerDialengaComponent } from './shared/components/spinner-dialenga/spinner-dialenga.component';
import { LoadingButtonComponent } from './shared/components/loading-button/loading-button.component';
import { FeedbackActionButtonComponent } from './shared/components/feedback-action-button/feedback-action-button.component';
import { SlimButtonComponent } from './shared/components/slim-button/slim-button.component';
import { SortableQuestionListComponent } from './shared/components/sortable-question-list/sortable-question-list.component';
import { DragSelectorComponent } from './shared/components/drag-selector.component';
import { RelatedLinkComponent } from './components/publication/related-link.component';
import { ToggleInputComponent } from './shared/components/toggle-input/toggle-input.component';
import { CategoryPreviewDeviceComponent } from './components/category-preview-device/category-preview-device.component';
import { CategoriesTreeComponent } from './components/categories-tree/categories-tree.component';
import { CategoriesFilterComponent } from './components/categories-filter/categories-filter.component';
import { CategoriesService } from './shared/services/categories.service';
import { UsersImportService } from './shared/services/users-import.service';
import { UtilsService } from './shared/services/utils.service';
import { PreviewButtonComponent } from './shared/components/preview-button/preview-button.component';
import { UserAvatarComponent } from './shared/components/user-avatar/user-avatar.component';
import { AvatarComponent } from './shared/components/avatar/avatar.component';
import { ItemsSelectionBoxesComponent } from './shared/components/items-selection-boxes/items-selection-boxes.component';
import { SegmentsSelectionBoxesComponent } from './shared/components/segments-selection-boxes/segments-selection-boxes.component';
import { CategoriesSelectionBoxesComponent } from './shared/components/categories-selection-boxes/categories-selection-boxes.component';
import { FilekindsSelectionBoxesComponent } from './shared/components/filekinds-selection-boxes/filekinds-selection-boxes.component';
import { NoResultsComponent } from './shared/components/no-results/no-results.component';
import { NoSearchResultsComponent } from './shared/components/no-search-results/no-search-results.component';
import { DateRangeSelectorComponent } from './shared/components/date-range-selector/date-range-selector.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { DialengaInputTextComponent } from './shared/components/dialenga-input-text/dialenga-input-text.component';
import { DialengaDatePickerComponent } from './shared/components/dialenga-date-picker/dialenga-date-picker.component';
import { DialengaTextareaComponent } from './shared/components/dialenga-textarea/dialenga-textarea.component';
import { DuiSelectMultipleSoftComponent } from './shared/components/dui-select-multiple-soft/dui-select-multiple-soft.component';
import { DialengaImageCropperComponent } from './shared/components/dialenga-image-cropper/dialenga-image-cropper.component';
import { ConversationNoteStatusTagComponent } from './components/conversation/conversation-note-status-tag.component';
import { ImportUsersComponent } from './components/import-users/import-users.component';
import { RemoteSignatureComponent } from './components/remote-signature/remote-signature.component';
import { RemoteSignatureDetailComponent } from './components/remote-signature-detail/remote-signature-detail.component';
import { DuiPopoverComponent } from './shared/components/dui-popover/dui-popover.component';
import { SignatureAreaPopoverComponent } from './components/signature-area-popover/signature-area-popover.component';
import { AvatarPopoverComponent } from './components/avatar-popover/avatar-popover.component';
import { RemoteSignatureCreationComponent } from './components/remote-signature-creation/remote-signature-creation.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { AvatarHexagonComponent } from './shared/components/avatar-hexagon/avatar-hexagon.component';
import { MentionComponent } from './shared/components/mention/mention.component';
import { GlobalSettingsComponent } from './components/global-settings/global-settings.component';
import { ChatroomsComponent } from './components/chatrooms/chatrooms.component';
import { ChatroomComponent } from './components/chatroom/chatroom.component';
import { DateRangeFilterComponent } from './shared/components/date-range-filter/date-range-filter.component';
import { MyCompanyComponent } from './components/my-company/my-company.component';
import { CompanyComponent } from './components/company/company.component';
import { DepartmentComponent } from './components/department/department.component';
import { ExternalLinkComponent } from './components/external-link/external-link.component';
import { DocumentSettingsComponent } from './components/document-settings/document-settings.component';
import { DocumentSettingsConventionsComponent } from './components/document-settings-conventions/document-settings-conventions.component';
import { TimeTrackingComponent } from './components/time-tracking/time-tracking.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { SegmentsComponent } from './components/segments/segments.component';
import { SegmentComponent } from './components/segment/segment.component';
import { TimeTrackingListComponent } from './components/time-tracking-list/time-tracking-list.component';
import { TimeTrackingListDetailComponent } from './components/time-tracking-list-detail/time-tracking-list-detail.component';
import { RequestsComponent } from './components/requests/requests.component';
import { ImportDocumentsComponent } from './components/import-documents/import-documents.component';
import { DocumentTransactionDetailComponent } from './components/document-transaction-detail/document-transaction-detail.component';
import { DocumentsHistoryComponent } from './components/documents-history/documents-history.component';
import { TimeoffSettingsComponent } from './components/timeoff-settings/timeoff-settings.component';
import { TimeoffSchemaComponent } from './components/timeoff-schema/timeoff-schema.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryComponent } from './components/category/category.component';

export const dialengaExeviBackoffice = angular.module('dialengaExeviBackoffice')
    .factory('CategoriesTreeService', downgradeInjectable(CategoriesTreeService))
    .factory('CategoriesService', downgradeInjectable(CategoriesService))
    .factory('UsersImportService', downgradeInjectable(UsersImportService))
    .factory('FormUtilsService', downgradeInjectable(FormUtilsService))
    .factory('SharedDataService', downgradeInjectable(SharedDataService))
    .factory('DialogService', downgradeInjectable(DialogService))
    .factory('UtilsService', downgradeInjectable(UtilsService))
    .directive('dragSelector', downgradeComponent({component: DragSelectorComponent}))
    .directive('spinnerDialenga', downgradeComponent({component: SpinnerDialengaComponent}))
    .directive('appSlimButton', downgradeComponent({component: SlimButtonComponent}))
    .directive('loadingButton', downgradeComponent({component: LoadingButtonComponent}))
    .directive('feedbackActionButton', downgradeComponent({component: FeedbackActionButtonComponent}))
    .directive('sortableQuestionList', downgradeComponent({component: SortableQuestionListComponent}))
    .directive('publicationRelatedLink', downgradeComponent({component: RelatedLinkComponent}))
    .directive('toggleInput', downgradeComponent({component: ToggleInputComponent}))
    .directive('categoryPreviewDevice', downgradeComponent({component: CategoryPreviewDeviceComponent}))
    .directive('categoriesTree', downgradeComponent({component: CategoriesTreeComponent}))
    .directive('categoriesFilter', downgradeComponent({component: CategoriesFilterComponent}))
    .directive('previewButton', downgradeComponent({component: PreviewButtonComponent}))
    .directive('avatar', downgradeComponent({component: AvatarComponent}))
    .directive('avatarPopover', downgradeComponent({component: AvatarPopoverComponent}))
    .directive('userAvatar', downgradeComponent({component: UserAvatarComponent}))
    .directive('itemsSelectionBoxes', downgradeComponent({component: ItemsSelectionBoxesComponent}))
    .directive('segmentsSelectionBoxes', downgradeComponent({component: SegmentsSelectionBoxesComponent}))
    .directive('categoriesSelectionBoxes', downgradeComponent({component: CategoriesSelectionBoxesComponent}))
    .directive('filekindsSelectionBoxes', downgradeComponent({component: FilekindsSelectionBoxesComponent}))
    .directive('noResults', downgradeComponent({component: NoResultsComponent}))
    .directive('noSearchResults', downgradeComponent({component: NoSearchResultsComponent}))
    .directive('dateRangeSelector', downgradeComponent({component: DateRangeSelectorComponent}))
    .directive('dateRangeFilter', downgradeComponent({component: DateRangeFilterComponent}))
    .directive('appSidemenu', downgradeComponent({component: SidemenuComponent}))
    .directive('dialengaInputText', downgradeComponent({component: DialengaInputTextComponent}))
    .directive('dialengaDatePicker', downgradeComponent({component: DialengaDatePickerComponent}))
    .directive('dialengaTextarea', downgradeComponent({component: DialengaTextareaComponent}))
    .directive('duiPopover', downgradeComponent({component: DuiPopoverComponent}))
    .directive('signatureAreaPopover', downgradeComponent({component: SignatureAreaPopoverComponent}))
    .directive('duiSelectMultipleSoft', downgradeComponent({component: DuiSelectMultipleSoftComponent}))
    .directive('dialengaImageCropper', downgradeComponent({component: DialengaImageCropperComponent}))
    .directive('conversationNoteStatusTag', downgradeComponent({component: ConversationNoteStatusTagComponent}))
    .directive('importUsers', downgradeComponent({component: ImportUsersComponent}))
    .directive('remoteSignature', downgradeComponent({component: RemoteSignatureComponent}))
    .directive('remoteSignatureDetail', downgradeComponent({component: RemoteSignatureDetailComponent}))
    .directive('documents', downgradeComponent({component: DocumentsComponent}))
    .directive('remoteSignatureCreation', downgradeComponent({component: RemoteSignatureCreationComponent}))
    .directive('avatarHexagon', downgradeComponent({component: AvatarHexagonComponent}))
    .directive('mention', downgradeComponent({component: MentionComponent}))
    .directive('globalSettings', downgradeComponent({component: GlobalSettingsComponent}))
    .directive('chatrooms', downgradeComponent({component: ChatroomsComponent}))
    .directive('chatroom', downgradeComponent({component: ChatroomComponent}))
    .directive('myCompany', downgradeComponent({component: MyCompanyComponent}))
    .directive('company', downgradeComponent({component: CompanyComponent}))
    .directive('department', downgradeComponent({component: DepartmentComponent}))
    .directive('externalLink', downgradeComponent({component: ExternalLinkComponent}))
    .directive('documentSettings', downgradeComponent({component: DocumentSettingsComponent}))
    .directive('documentSettingsConventions', downgradeComponent({component: DocumentSettingsConventionsComponent}))
    .directive('timeTracking', downgradeComponent({component: TimeTrackingComponent}))
    .directive('schedule', downgradeComponent({component: ScheduleComponent}))
    .directive('segments', downgradeComponent({component: SegmentsComponent}))
    .directive('segment', downgradeComponent({component: SegmentComponent}))
    .directive('timeTrackingList', downgradeComponent({component: TimeTrackingListComponent}))
    .directive('timeTrackingListDetail', downgradeComponent({component: TimeTrackingListDetailComponent}))
    .directive('requests', downgradeComponent({component: RequestsComponent}))
    .directive('importDocuments', downgradeComponent({component: ImportDocumentsComponent}))
    .directive('documentTransactionDetail', downgradeComponent({component: DocumentTransactionDetailComponent}))
    .directive('documentsHistory', downgradeComponent({component: DocumentsHistoryComponent}))
    .directive('timeoffSettings', downgradeComponent({component: TimeoffSettingsComponent}))
    .directive('timeoffSchema', downgradeComponent({component: TimeoffSchemaComponent}))
    .directive('categories', downgradeComponent({component: CategoriesComponent}))
    .directive('category', downgradeComponent({component: CategoryComponent}));