<div class="main-container">
    <div class="main-container__head">
        <div class="flex align-items-end justify-space-between margin-block-20 min-height-40">
            <div class="flex direction-column">
                <ul class="breadcrumb">
                    <li>
                        <a href="/global-settings" [translate]="'global.navbar.globalSettings'"></a>
                    </li>
                </ul>
                <h2 [translate]="'categories.title'"></h2>
            </div>
            <div class="flex align-items-end">
                <loading-button [classes]="'button--secondary button--small'" [iconClass]="'icon-add'"
                    [text]="'categories.newcategory'" [isValid]="true"
                    (action)="navigateTo('/settings/categories/create')"></loading-button>
                <!-- Boton exportar CSV -->
                <button id="exportCSV" class="button button--small button--only-icon margin-left-10"
                    (click)="openDatePopover = true" kendoTooltip [title]="'conversations.downloadCSV' | translate">
                    <span class="button__icon icon icon-export"></span>
                </button>
                @if (openDatePopover) {
                    <date-range-filter [anchor]="'exportCSV'" [dateRangeId]="'exportCSVFile'" [hideShortcuts]="true"
                        [isDownload]="true" (onDateSet)="downloadCSV($event)"
                        (onCancel)="cancelDownload($event)"></date-range-filter>
                }
            </div>
        </div>
    </div>
    <div class="main-container__content">
        <div class="main-container--full-width">
            <div class="flex justify-space-between margin-bottom-20">
                <div class="filters-buttons-bar-container flex vertical-center">
                    <div class="label-filters">
                        <label>
                            <input type="radio" name="categoryStatus" class="sr-only" [value]="true" [(ngModel)]="dataFilterOpts.active" (change)="getCategoriesByIsActive()">
                            <span [ngClass]="{'selected': dataFilterOpts.active}" [translate]="'feedbackTrays.titleactive'"></span>
                        </label>
                        <label>
                            <input type="radio" name="categoryStatus" class="sr-only" [value]="false" [(ngModel)]="dataFilterOpts.active" (change)="getCategoriesByIsActive()">
                            <span [ngClass]="{'selected': !dataFilterOpts.active}" [translate]="'feedbackTrays.titleinactive'"></span>
                        </label>
                    </div>
                </div>
                <div class="search-filter-container align-items-center">
                    <input type="text" class="input input--search"
                        [ngClass]="dataFilterOpts.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'" maxlength="1024"
                        [placeholder]="'global.search.placeholder' | translate" [(ngModel)]="dataFilterOpts.name"
                        ngModelOptions="{ debounce: 200 }" (input)="listItemsSearch()">
                    <div class="icon icon-remove" (click)="clearSearchInput()"></div>
                </div>
            </div>
            @if (categoriesDatagrid?.data.length > 0) {
                <kendo-grid #categoriesGrid [data]="categoriesDatagrid"
                    class="master-grid user-select-none margin-bottom-40 org-grid" [sortable]="true" [sort]="gridSort"
                    (sortChange)="onSortChange($event)" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
                    [pageable]="gridPagerSettings" (pageChange)="onPageChange($event)" [skip]="dataPagingOpts.skip"
                    [pageSize]="dataPagingOpts.size" [navigable]="true" [resizable]="false" [selectable]="false"
                    [loading]="isSyncing">

                    <kendo-grid-column field="publicationGroupingContents.name" [title]="'model.category.name' | translate"
                        [width]="350">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                @if (isVisible(dataItem.availableLanguages)) {
                                    <span class="icon-left-vertical-center icon icon-alert" kendoTooltip
                                        [title]="'language.contentTooltip.noDefaultLanguage' | translate"></span>
                                }
                                <span class="message-category truncate"
                                    [style.--message-category-bg]="getCategoryTagHexColor(dataItem.color)"
                                    [textContent]="dataItem.name"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="contentsCount" [title]="'model.category.contents' | translate"
                        [sortable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            @if (!dataItem.subfoldersCount && !dataItem.contentsCount) {
                                <div class="no-contents">
                                    <span class="no-contents--label" [translate]="'model.category.contentsEmpty'"></span>
                                    <span class="icon icon-info icon-20 margin-left-8" kendoTooltip
                                        [title]="'model.category.contentsEmptyTooltip' | translate"></span>
                                </div>
                            }
                            @if (dataItem.subfoldersCount || dataItem.contentsCount) {
                                @if (dataItem.subfoldersCount) {
                                    <span [translate]="'model.category.contentsFoldersDetail'"
                                        [translateParams]="{numFolders: dataItem.subfoldersCount}"></span>
                                }
                                @if (dataItem.subfoldersCount && dataItem.contentsCount) {
                                    <span>, </span>
                                }
                                @if (dataItem.contentsCount) {
                                    <span [translate]="'model.category.contentsDetail'"
                                        [translateParams]="{numContents: dataItem.contentsCount}"></span>
                                }
                            }
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-command-column [width]="136" [style]="{ 'text-align': 'end' }">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                            <div class="flex justify-end td-on-hover" kendoTooltip filter="a.k-master-button" offset="0">
                                <a class="k-button k-master-button" [title]="'global.form.edit' | translate"
                                    (click)="editCategory(dataItem)">
                                    <span class="icon icon-edit"></span>
                                </a>

                                @if (dataItem.deletable && dataItem.active) {
                                    <a class="k-button k-master-button" [title]="'global.form.delete' | translate"
                                        (click)="deleteCategory(dataItem)">
                                        <span class="icon icon-delete"></span>
                                    </a>
                                }

                                @if (!dataItem.deletable && dataItem.active) {
                                    <a class="k-button k-master-button" [title]="'global.form.archive' | translate"
                                        (click)="archiveCategory(dataItem)">
                                        <span class="icon icon-archive"></span>
                                    </a>
                                }

                                @if (!dataItem.active) {
                                    <a class="k-button k-master-button" [title]="'global.form.activate' | translate"
                                        (click)="activateCategory(dataItem)">
                                        <span class="icon icon-unarchive"></span>
                                    </a>
                                }
                            </div>
                        </ng-template>
                    </kendo-grid-command-column>

                    <kendo-grid-messages [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
                        [pagerOf]="'global.lists.pagerOf' | translate" [pagerItems]="'global.lists.pagerItems' | translate">
                    </kendo-grid-messages>

                    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                        <article class="dui-grid-pager">
                            <kendo-pager-info></kendo-pager-info>
                            @if (categoriesDatagrid.total > 10) {
                                @if (totalPages > 1) {
                                    <article class="dui-grid-pager__numbers-wrap">
                                        <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                        <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                        <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                                    </article>
                                }
                                <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                            }
                        </article>
                    </ng-template>
                </kendo-grid>
            }
            @if (categoriesDatagrid?.data.length == 0 && isSyncing) {
                <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid org-grid" [rowClass]="skeletonRowCallback">
                    <kendo-grid-column [title]="'model.category.name' | translate" [width]="350">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <kendo-skeleton shape="text" animation="pulse" [width]="300" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [title]="'model.category.contents' | translate">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <kendo-skeleton shape="text" animation="pulse" [width]="100" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-command-column [width]="136"></kendo-grid-command-column>
                </kendo-grid>
            }
            @if (categoriesDatagrid.total == 0 && !dataFilterOpts.name && !isSyncing) {
                <no-results
                  [imageTitle]="'categories.noResultsTitle'" [description]="dataFilterOpts.active ? 'categories.noResultsText' : 'categories.noResultsArchiveText'">
                </no-results>
            }
            @if (categoriesDatagrid.total == 0 && dataFilterOpts.name && !isSyncing) {
                <no-search-results></no-search-results>
            }
        </div>
    </div>
</div>
