<div class="dialenga-combobox">
    <div class="dialenga-combobox__avatar">
        @if (!inputModel) {
            <div class="dialenga-combobox__avatar--skeleton"></div>
        }@else {
            <avatar [small]="true" [name]="inputModel.name" [surname]="inputModel.surname"
                [thumbnails]="{thumbnail_M: inputModel.avatarThumbnailMUrl || inputModel.avatarUrl, thumbnail_S: inputModel.avatarThumbnailSUrl || inputModel.avatarThumbnailMUrl }"
                [state]="inputModel.state" [access]="inputModel.lastAccessDate">
            </avatar>
        }
    </div>
    <kendo-combobox #availableUsersCombo [data]="availableUsers" [(ngModel)]="inputModel" [valuePrimitive]="false" textField="fullName" valueField="id" 
        [filterable]="true" (filterChange)="getEmployees($event)" (selectionChange)="onEmployeeSelected($event)"
        [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-combobox__popup' }" [placeholder]="'global.form.employeeName' | translate">
        <kendo-combobox-messages [clearTitle]="('global.modal.clear' | translate)" [noDataText]="'global.form.emptyDataSet' | translate"></kendo-combobox-messages>

        <ng-template kendoDropDownListNoDataTemplate>
            <h5 class="uppercase" translate="global.form.emptyDataSet"></h5>
        </ng-template>
        <ng-template kendoDropDownListValueTemplate let-dataItem>
            <div class="select-avatar-item min-width-300" *ng-if="dataItem">
                <user-avatar [small]="true" [user]="dataItem" [showUsername]="true"></user-avatar>
            </div>
        </ng-template>
        <ng-template kendoComboBoxItemTemplate let-dataItem>
            <div class="dialenga-combobox__popup-avatar select-avatar-item min-width-300">
                <user-avatar [small]="true" [user]="dataItem" [showUsername]="true"></user-avatar>
            </div>
        </ng-template>
    </kendo-combobox>
</div>