import { Component, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { SortDescriptor} from "@progress/kendo-data-query";
import { TimeoffSettingsService } from '../timeoff-settings/timeoff-settings.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';

@Component({
  selector: 'schema-employees',
  templateUrl: './timeoff-schema-employees.component.html'
})

export class TimeoffSchemaEmployeesComponent implements OnChanges {
  @Input() schemaId: number;
  @Input() employees: Array<any>;

  public gridPagerSettings = {
    buttonCount: 5,
    pageSizes: [5,10,20,100],
  };
  public isSyncing: boolean = false;
  public schemaEmployeesDatagrid: GridDataResult = {
    data: [],
    total: 0
  };
    
  public gridPagingOps: any = {
    page: 0,
    size: 10,
    skip: 0,
    sort: ['name,asc', 'surname,asc']
  };
  public gridFilteringOps: any = {
    name: ''
  };
  public gridSort: SortDescriptor[];
  public totalPages: number = 0;
  public dataSkeleton = [{},{},{}];

  @ViewChild('schemaEmployeesGrid') schemaEmployeesGrid: GridComponent;

  constructor(
    private timeoffSettingsService: TimeoffSettingsService, public dialogService: DialogService,
    private notificationService: DuiNotificationsService, private translate: TranslateService,
    ) { 
        
    }
        
  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.employees && changes.employees.previousValue == undefined && changes.employees.firstChange ) {
      this.loadSchemaEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
    }
    if ( changes.employees && !changes.employees.firstChange ) {
      this.updateEmployees(changes.employees.currentValue);
    }
  }

  loadSchemaEmployeesGridData(pagingOptions: any, filterOptions: any) :void {
    this.isSyncing = true;

    this.timeoffSettingsService.findAllEmployees(this.schemaId, pagingOptions, filterOptions).subscribe(
      (response) => {
        this.schemaEmployeesDatagrid = {
          data: response.content,
          total: response.totalElements
        };
        this.totalPages = response.totalPages;
        this.isSyncing = false;
      },
      (error: any) => {
        this.isSyncing = false;
        // Show notification error
      }
    );
  }

  updateEmployees(users: Array<any>) {
    this.isSyncing = true;

    this.timeoffSettingsService.updateEmployees(this.schemaId, users).subscribe(
      () => {
        this.loadSchemaEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
        const message: string = this.translate.instant('schemes.employees.notification.add.message');
        this.notificationService.showSuccessNotification(message);
      },
      (onError: any) => {
        this.isSyncing = false;
        // Show notification error
      }
    );
  }

  deleteEmployee(employee: any): void {
    const dialogValues: DialogValueOptions = {
      title: 'schemes.employees.modal.delete.title',
      message: 'schemes.employees.modal.delete.message',
      messageParam: {fullName: employee.name + ' ' + employee.surname}
    };
    this.dialogService.openConfirmationDialog(dialogValues).subscribe(
      (result: any) => {
        if(result?.accept) {
          this.isSyncing = true;
          this.timeoffSettingsService.deleteEmployee(this.schemaId, employee.id).subscribe(
            () => {
              this.loadSchemaEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
              // Show success notification
              const message = this.translate.instant('schemes.employees.notification.delete.message', {fullName: employee.name + ' ' + employee.surname});
              this.notificationService.showSuccessNotification(message);
            },
            (onError: any) => {
              // Show notification error
            }
          );
        }
      }
    );
  }

  listItemsSearch() {
    if (this.gridFilteringOps.name.length >= 3 || this.gridFilteringOps.name.length === 0) {
      this.loadSchemaEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
    }
  }

  clearSearchInput() {
    this.gridFilteringOps.name = '';
    this.loadSchemaEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
  }

  skeletonRowCallback(row: RowClassArgs) {
    return {
      'opacity-50': row.index === 1,
      'opacity-30': row.index === 2 
    }
  }

  public onSortChange(sort: SortDescriptor[]): void {
    this.gridSort = sort;        
    this.gridPagingOps.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : ['name,asc', 'surname,asc'];
    this.loadSchemaEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
  }

  public onPageChange(event: PageChangeEvent): void {
    this.gridPagingOps.page = (event.skip / this.gridPagingOps.size);
    this.gridPagingOps.skip = event.skip;
    this.gridPagingOps.size = event.take;

    this.loadSchemaEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
  }
  
}
