<div class="main-container__content">
    <div class="main-container--small-width margin-top-20">
        @for (schedule of scheduleForm.schedules; track schedule; let first = $first; let last = $last; let idx = $index) {
            <section>
                <!-- Schedule name -->
                <div class="editable-wrapper flex direction-row justify-space-between align-items-center margin-bottom-24" [ngClass]="{'margin-top-32': !schedule.parent}">
                    <h3 #scheduleName id="{{idx}}" class="icon icon-edit contenteditable editable-title width-90-percent flex align-items-center"
                        [textContent]="schedule.name" ([ngModel])="schedule.name" [contentEditable]="true" prevent-event="dragover drop"
                        (blur)="onScheduleNameEvent($event)" (keydown.enter)="onScheduleNameEvent($event)" (keydown.esc)="onScheduleNameEvent($event)"
                        maxlength="64" (paste)="onScheduleNamePaste($event)">
                    </h3>
                    <!-- Remove schedule -->
                    @if (!schedule.parent) {
                        <span class="icon icon-24 icon-delete cursor-pointer" (click)="removeSchedule(idx)"></span>
                    }
                </div>
                @if (!schedule.parent && last) {
                    <div class="info-advice margin-bottom-24">
                        <p [translate]="'timeTracking.schedule.advice'"></p>
                    </div>
                }
                <!-- Schedule active period kind-->
                <section class="group-form group-form--label-250">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'timeTracking.schedule.form.activePeriod'"></span>
                    </div>
                    <div class="group-form__data direction-column align-items-start">
                        <div class="button-bar button-bar--small">
                            <button class="button" type="button"
                                [ngClass]="{'button--selected': schedule.periodKind === SCHEDULE_PERIOD_KIND.ALWAYS}"
                                (click)="setSchedulePeriodKind(schedule, SCHEDULE_PERIOD_KIND.ALWAYS)" [disabled]="!schedule.parent">
                                <span class="button__label" [translate]="'timeTracking.schedule.form.periodAlwaysBtn'"></span>
                            </button>
                            <button class="button" type="button"
                                [ngClass]="{'button--selected': schedule.periodKind === SCHEDULE_PERIOD_KIND.CUSTOM}"
                                (click)="setSchedulePeriodKind(schedule, SCHEDULE_PERIOD_KIND.CUSTOM)">
                                <span class="button__label" [translate]="'timeTracking.schedule.form.periodCustomBtn'"></span>
                            </button>
                        </div>
                        <!-- Schedule active periods -->
                        @if (schedule.periodKind === SCHEDULE_PERIOD_KIND.CUSTOM) {
                            @for (datePeriod of schedule.datePeriods; track datePeriod; let last = $last; let idx = $index) {
                                <section class="date-period margin-top-16">
                                    <section class="flex align-items-center">
                                        <span class="margin-right-8" [translate]="'timeTracking.schedule.form.from'"></span>
                                        <!-- Start period date -->
                                        <kendo-dropdownlist style="width: 60px" [defaultItem]="''" [data]="days" [(ngModel)]="datePeriod.startDay"
                                            [ngClass]="{'input--error': formHasErrorsChecked && ((!datePeriod.valid && overlapErrors) || !overlapErrors)}"
                                            class="dui-dropdown margin-right-10" valuePrimitive="true" (valueChange)="onChange()"
                                            [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                            <ng-template kendoDropDownListItemTemplate let-dataItem >
                                                <span class="padding-3" [textContent]="dataItem"
                                                    [ngClass]="{'hidden': (dataItem > datePeriod.endDay && !!datePeriod.startMonth && !!datePeriod.endMonth && datePeriod.startMonth == datePeriod.endMonth) || 
                                                    (dataItem > 28 && datePeriod.startMonth == '2') || (dataItem > 30 && [4,6,9,11].includes(datePeriod.startMonth))}">
                                                </span>
                                            </ng-template>
                                        </kendo-dropdownlist>

                                        <kendo-dropdownlist style="width: 80px" [defaultItem]="''" [data]="months" [(ngModel)]="datePeriod.startMonth" 
                                            [ngClass]="{'input--error': formHasErrorsChecked && ((!datePeriod.valid && overlapErrors) || !overlapErrors)}"
                                            class="dui-dropdown margin-right-10" valuePrimitive="true" (valueChange)="onChange()" [textField]="'abbr'" valueField="value"
                                            [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                                @if (dataItem) {
                                                    <span [textContent]="dataItem.abbr"></span>
                                                }
                                            </ng-template>
                                            <ng-template kendoDropDownListItemTemplate let-dataItem >
                                                <span class="padding-3 margin-left-5" [textContent]="dataItem.abbr"
                                                    [ngClass]="{'hidden': (!!datePeriod.endMonth && dataItem.value > datePeriod.endMonth) || 
                                                    (dataItem.value == '2' && datePeriod.startDay > 28) || ([4,6,9,11].includes(dataItem.value) && datePeriod.startDay > 30)}"></span>
                                            </ng-template>
                                        </kendo-dropdownlist>
                                        <span class="margin-right-10" [translate]="'timeTracking.schedule.form.to'"></span>
                                        <!-- End period date -->
                                        <kendo-dropdownlist style="width: 60px" [defaultItem]="''" [data]="days" [(ngModel)]="datePeriod.endDay" 
                                            [ngClass]="{'input--error': formHasErrorsChecked && ((!datePeriod.valid && overlapErrors) || !overlapErrors)}"
                                            class="dui-dropdown margin-right-10" valuePrimitive="true" (valueChange)="onChange()"
                                            [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                            <ng-template kendoDropDownListItemTemplate let-dataItem >
                                                <span class="padding-3" [textContent]="dataItem"
                                                    [ngClass]="{'hidden': (dataItem < datePeriod.startDay && !!datePeriod.startMonth && !!datePeriod.endMonth && datePeriod.startMonth == datePeriod.endMonth) || 
                                                    (dataItem > 28 && datePeriod.endMonth == '2') || (dataItem > 30 && [4,6,9,11].includes(datePeriod.endMonth))}"></span>
                                            </ng-template>
                                        </kendo-dropdownlist>

                                        <kendo-dropdownlist style="width: 80px" [defaultItem]="''" [data]="months" [(ngModel)]="datePeriod.endMonth" 
                                            [ngClass]="{'input--error': formHasErrorsChecked && ((!datePeriod.valid && overlapErrors) || !overlapErrors)}"
                                            class="dui-dropdown margin-right-10" valuePrimitive="true" (valueChange)="onChange()" [textField]="'abbr'" valueField="value"
                                            [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                                @if (dataItem) {
                                                    <span [textContent]="dataItem.abbr"></span>
                                                }
                                            </ng-template>
                                            <ng-template kendoDropDownListItemTemplate let-dataItem >
                                                <span class="padding-3 margin-left-5" [textContent]="dataItem.abbr"
                                                    [ngClass]="{'hidden': (dataItem.value < datePeriod.startMonth) || 
                                                    (dataItem.value == '2' && datePeriod.endDay > 28) || ([4,6,9,11].includes(dataItem.value) && datePeriod.endDay > 30)}"></span>
                                            </ng-template>
                                        </kendo-dropdownlist>
                                        <!-- Add period date -->
                                        @if (last) {
                                            <button type="button" class="button button--only-icon height-24 width-24" 
                                                [ngClass]="{'button--disabled': !existsOneValidPeriod(schedule) }" (click)="addPeriodDate(schedule)">
                                                <span class="button__icon icon icon-add"></span>
                                            </button>
                                        }
                                        <!-- Delete period date -->
                                        @if (schedule.datePeriods.length > 1) {
                                            <button type="button" class="button button--only-icon height-24 width-24 visible-on-hover" (click)="deletePeriodDate(schedule, idx)">
                                                <span class="button__icon icon icon-delete"></span>
                                            </button>
                                        }
                                    </section>
                                    @if (formHasErrorsChecked) {
                                        @if (!datePeriod.valid && overlapErrors) {
                                            <span  class="danger font-size-12 margin-top-8 margin-left-50" [translate]="'timeTracking.schedule.form.errors.overlapedPeriod'"></span>
                                        }
                                        @if (!overlapErrors) {
                                            <span class="danger font-size-12 margin-top-8 margin-left-50" [translate]="'timeTracking.schedule.form.errors.invalidPeriod'"></span>
                                        }
                                    }
                                </section>
                            }       
                        }
                    </div>
                </section>
                <!-- Schedule working days selection -->
                <section class="group-form group-form--label-250">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'timeTracking.schedule.form.workDays'"></span>
                    </div>
                    <div class="group-form__data">
                        <ul class="circles-list" kendoTooltip filter="li">
                            @for (day of schedule.weekDaysSelection; track day) {
                                <li class="circles-list__item" [ngClass]="{'circles-list__item--active' : day.active, 'disabled': onlyOneDayIsActive(schedule) && day.active}"
                                    (click)="setActiveOrIdleDay(schedule, day)" [textContent]="day.name" [title]="'global.form.weekDaysName' | translate:{day: day.dayOfWeek}">
                                </li>
                            }
                        </ul>
                    </div>
                </section>
                <!-- Schedule time periods list -->
                <section class="group-form group-form--label-250">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'timeTracking.schedules.grid.scheduleName'"></span>
                    </div>
                    <div class="group-form__data">
                        <div class="time-periods margin-top-16">
                            @for (timePeriodDay of schedule.workDays | sort:'dayOfWeek'; track timePeriodDay) {
                                <div class="time-periods__item">
                                    <span class="time-periods__name" [ngClass]="{'align-self-start': timePeriodDay.timePeriods.length > 1}"
                                        [translate]="'global.form.weekDaysName'" [translateParams]="{day: timePeriodDay.dayOfWeek}">
                                    </span>
                                    <div class="time-periods__period">
                                        @for (period of timePeriodDay.timePeriods; track period; let last = $last; let idx = $index) {
                                            <div class="period-item relative" kendoTooltip filter="span.icon-warning-circle-fill" offset="5">
                                                <input #startTime type="time" class="input" [(ngModel)]="period.startTime" placeholder="00:00" name="startTime"
                                                    [ngClass]="{'input--error': !period.valid && formHasErrorsChecked}"
                                                    (blur)="onChangePeriod(timePeriodDay, schedule)" (ngModelChange)="changePeriodTime($event, period, startTime)"
                                                    (copy)="onCopyTimePeriod($event, startTime)" (paste)="onPasteTimePeriod($event, period, startTime)">
                                                <div class="separator"><span></span></div>
                                                <input #endTime type="time" class="input" [(ngModel)]="period.endTime" placeholder="00:00" name="endTime"
                                                    [ngClass]="{'input--error': !period.valid && formHasErrorsChecked}"
                                                    (blur)="onChangePeriod(timePeriodDay, schedule)" (ngModelChange)="changePeriodTime($event, period, endTime)"
                                                    (copy)="onCopyTimePeriod($event, endTime)" (paste)="onPasteTimePeriod($event, period, endTime)">
                                                <!-- CTA buttons for time periods -->
                                                @if (timePeriodDay.timePeriods.length > 1 && !last) {
                                                    <button type="button" class="button button--only-icon margin-left-8 height-24 width-24 visible-on-hover"
                                                        (click)="deletePeriodTime(timePeriodDay, idx, schedule)">
                                                        <span class="button__icon icon icon-delete"></span>
                                                    </button>
                                                }
                                                @if (last) {
                                                    <button type="button" class="button button--only-icon margin-left-8 height-24 width-24" (click)="addPeriodTime(timePeriodDay)">
                                                        <span class="button__icon icon icon-add"></span>
                                                    </button>
                                                }
                                                @if (!period.valid && overlapErrors) {
                                                    <span class="icon icon-20 icon-warning-circle-fill danger opacity-50"
                                                    style="position: absolute; left: -28px" [title]="'timeTracking.schedule.form.errors.overlapedTimeRange' | translate">
                                                </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div class="time-periods__day-sum" [ngClass]="{'align-self-end': timePeriodDay.timePeriods.length > 1}">
                                        <span [textContent]="getTotalTimePerDay(timePeriodDay.dayOfWeek, schedule)"></span>
                                    </div>
                                </div>
                            }
                            <div class="time-periods__total">
                                <span [textContent]="('statistics.global.total' | translate) + ':'"></span>
                                <span [textContent]="getTotalTimeBySchedule(schedule)"></span>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- Schedule flexible -->
                <section class="group-form group-form--label-250">
                    <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" offset="10">
                        <span class="group-form__label__text margin-right-10" [translate]="'timeTracking.schedules.grid.flexible'"></span>
                        <span class="group-form__label__help icon icon-help-fill" [title]="'timeTracking.schedules.grid.flexibleTooltip' | translate"></span>
                    </div>
                    <div class="group-form__data group-form__data--column padding-top-8">
                        <toggle-input id="flexible-schedule" [inputId]="'flexible-schedule'" (change)="onChange()"
                            [(inputModel)]="schedule.scheduleMargin.flexibleSchedule">
                        </toggle-input>
                        @if (schedule.scheduleMargin.flexibleSchedule) {
                            <div class="flexible-margins margin-top-16">
                                <div class="flexible-margins__box">
                                    <span class="flexible-margins__box__header" [translate]="'timeTracking.schedule.form.entryMargin'"></span>
                                    <div class="flexible-margins__box__item">
                                        <span class="black85" [translate]="'global.form.previous'"></span>
                                        <input #priorMarginIn name="priorMarginIn" type="number" class="input text-right max-width-50" inputmode="numeric"
                                            [(ngModel)]="schedule.scheduleMargin.priorMarginIn" (blur)="onChange()"
                                            (ngModelChange)="onInputNumberChange(priorMarginIn, schedule)">
                                        <span class="black85" [translate]="'global.form.minutesAbbr'"></span>
                                    </div>
                                    <div class="flexible-margins__box__item">
                                        <span class="black85" [translate]="'global.form.later'"></span>
                                        <input #priorMarginOut name="priorMarginOut" type="number" class="input text-right max-width-50" inputmode="numeric"
                                            [(ngModel)]="schedule.scheduleMargin.priorMarginOut" (blur)="onChange()"
                                            (ngModelChange)="onInputNumberChange(priorMarginOut, schedule)">
                                        <span class="black85" [translate]="'global.form.minutesAbbr'"></span>
                                    </div>
                                </div>
                                <div class="flexible-margins__box">
                                    <span class="flexible-margins__box__header" [translate]="'timeTracking.schedule.form.exitMargin'"></span>
                                    <div class="flexible-margins__box__item">
                                        <span class="black85" [translate]="'global.form.previous'"></span>
                                        <input #laterMarginIn name="laterMarginIn" type="number" class="input text-right max-width-50" inputmode="numeric"
                                            [(ngModel)]="schedule.scheduleMargin.laterMarginIn" (blur)="onChange()"
                                            (ngModelChange)="onInputNumberChange(laterMarginIn, schedule)">
                                        <span class="black85" [translate]="'global.form.minutesAbbr'"></span>
                                    </div>
                                    <div class="flexible-margins__box__item">
                                        <span class="black85" [translate]="'global.form.later'"></span>
                                        <input #laterMarginOut name="laterMarginOut" type="number" class="input text-right max-width-50" inputmode="numeric"
                                            [(ngModel)]="schedule.scheduleMargin.laterMarginOut" (blur)="onChange()"
                                            (ngModelChange)="onInputNumberChange(laterMarginOut, schedule)">
                                        <span class="black85" [translate]="'global.form.minutesAbbr'"></span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </section>
        }
        <!-- Add schedule -->
        @if (scheduleForm.schedules) {
            <section class="group-form group-form--label-250 margin-top-40 margin-bottom-60">
                <button id="create-schedule" class="button" style="padding: 0 15px;" (click)="createSchedule()" data-cy="create-schedule">
                    <span class="button__icon icon icon-add"></span>
                    <span class="button__label" [translate]="'timeTracking.addSchedule'"></span>
                </button>
            </section>
        }
        <!-- Form action buttons -->
        @if (scheduleForm.schedules) {
            <section class="group-form group-form--label-250 margin-top-40 margin-bottom-60">
                <div class="group-form__label"></div>
                <div class="group-form__data group-form__data--inline">
                    <feedback-action-button [buttonId]="'save-button'" [classes]="'button--secondary'" [text]="'global.form.save'"
                        [tooltipText]="'formValidation.invalidSingleLanguage'" (action)="scheduleFormSave()" [isValid]="isFormDataChanged && isFormDataValid">
                    </feedback-action-button>
                    <loading-button [text]="'global.form.cancel'" class="margin-left-20" [classes]="'button'" (action)="goBack()" [isValid]="true"></loading-button>
                </div>
            </section>
        }
    </div>
</div>