<div class="modal-header">
  <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
    <span class="button__icon icon icon-close"></span>
  </button>
  <h3 class="modal-title" [translate]="modalTitle"></h3>
</div>

<div class="modal-body">
  <div class="group-form group-form--label-200">
    <div class="group-form__label">
      <span class="group-form__label__text" [translate]="'schemes.absences.modal.absenceGroup'"></span>
    </div>
    <span class="group-form__data bold" [textContent]="group?.contents[language.default]"></span>
  </div>
  <div class="flex justify-end border-bottom-black-30 padding-bottom-5">
    <language-selector [(selectedLanguage)]="language.selected" [formContent]="motive.contents" (validationFunction)="validateModel()"></language-selector>
  </div>
  <div class="group-form group-form--label-200 margin-top-30">
    <div class="group-form__label">
      <span class="group-form__label__text" [translate]="'schemes.absences.modal.absenceMotiveName'"></span>
    </div>
    <div class="group-form__data">
      <input class="input width-100-percent" [(ngModel)]="motive.contents[language.selected].name" (ngModelChange)="onChange()" 
          id="motive-name" maxlength="64" placeholder="{{'schemes.absences.modal.nameAbsencePlaceholder' | translate}}" />
    </div>
  </div>
  <div class="group-form group-form--label-200">
    <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" offset="8">
      <span class="group-form__label__text margin-right-10" [translate]="'schemes.absences.modal.motiveComments'"></span>
      <span class="group-form__label__help icon icon-help-fill" [title]="'schemes.boxes.motiveComment' | translate"></span>
    </div>
    <div class="group-form__data">
      <textarea id="motiveComment" maxlength="256" class="textarea width-100-percent" (ngModelChange)="onChange()" 
        [placeholder]="'model.absences.motiveCommentPlaceholder' | translate" style="resize: vertical;"
        [(ngModel)]="motive.contents[language.selected].comments">
      </textarea>
    </div>
  </div>
  <div class="group-form group-form--label-200">
    <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" offset="8">
      <span class="group-form__label__text margin-right-10" [translate]="'schemes.absences.modal.requiresNote'"></span>
      <span class="group-form__label__help icon icon-help-fill" [title]="'schemes.boxes.requireNote' | translate"></span>
    </div>
    <div class="group-form__data">
      <toggle-input id="requireNote" [inputId]="'requireNote'" [(inputModel)]="motive.requiresNote" (inputChanged)="onChange()"></toggle-input>
    </div>
  </div>
  @if (motive.requiresNote) {
    <div class="group-form group-form--label-200">
      <div class="group-form__label group-form__label--end">
        <span class="group-form__label__text" [translate]="'schemes.absences.modal.daysToAttach'"></span>
      </div>
      <div class="group-form__data">
        <input #daysToAttach name="daysToAttach" type="number" class="input text-right width-60" inputmode="numeric"
            [(ngModel)]="motive.daysToAttachFile" (blur)="onChange()" [min]="0" [max]="99" (ngModelChange)="onInputNumberChange(daysToAttach)" />
        <span class="group-form__label__text margin-left-10" [translate]="'model.schemes.calendarDays'"></span>
      </div>
    </div>
  }
  @if (motive.groupKind !== ABSENCE_MOTIVE_GROUPS.PERSONAL_DAYS) {
    <div class="group-form group-form--label-200">
      <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" offset="8">
        <span class="group-form__label__text margin-right-10" [translate]="'schemes.absences.modal.remunerable'"></span>
        <span class="group-form__label__help icon icon-help-fill" [title]="'schemes.boxes.remunerable' | translate"></span>
      </div>
      <div class="group-form__data">
        <toggle-input id="remunerable" [inputId]="'remunerable'" [(inputModel)]="motive.remunerable" (inputChanged)="onChange()"></toggle-input>
      </div>
    </div>
  }
</div>

<div class="modal-footer flex justify-end">
  <loading-button [buttonId]="'schema-absences-modal-accept'" [classes]="'button button--secondary margin-right-10'" 
    [text]="'global.form.save'" (action)="onAccept()" [isValid]="isValidFormMotive">
  </loading-button>
  <button class="button" (click)="onCancel()">
    <span class="button__label" [translate]="'global.modal.cancel'"></span>
  </button>
</div>