import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { SortDescriptor} from "@progress/kendo-data-query";
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { TimeoffSettingsService } from '../timeoff-settings/timeoff-settings.service';

@Component({
  selector: 'schemas-list',
  templateUrl: './timeoff-settings-schemas.component.html'
})

export class TimeoffSettingsSchemasComponent implements OnInit {

  public isSyncing: boolean = false;
  public schemasDatagrid: GridDataResult = {
    data: [],
    total: 0
  };
  public gridPagerSettings = {
    buttonCount: 5,
    pageSizes: [5,10,20,100],
  };
  public dataPagingOpts: any = {
    page: 0,
    size: 10,
    skip: 0,
    sort: ['lastModifiedDate,desc', 'name,asc']
  };
  public dataFilterOpts: any = {
    active: true,
    name: ''
  };
  public totalPages: number = 0;
  public dataSkeleton = [{},{},{}];
  public gridSort: SortDescriptor[];

  private clickedRowItem: any = '';
  private dblClickEvent: boolean;

  @ViewChild('schemasGrid') schemasGrid: GridComponent;

  constructor( private timeoffSettingsService: TimeoffSettingsService, private location: Location, private dialogService: DialogService,
               private notificationService: DuiNotificationsService, private translate: TranslateService) { }

  ngOnInit() {
      this.getSchemasGridData(this.dataPagingOpts, this.dataFilterOpts);
  }

  // PRIVATE METHODS

  private getSchemasGridData(pagingOptions: any, filterOptions: any) {
    this.isSyncing = true;
    this.timeoffSettingsService.findAllSchemas(pagingOptions, filterOptions).subscribe(
      (response) => {
        this.schemasDatagrid = {
            data: response.content,
            total: response.totalElements
        };
        this.totalPages = response.totalPages;
        this.isSyncing = false;
      },
      (error: any) => {
        this.isSyncing = false;
        // Show notification error
      }
    );
  }

  private navigateToEditView (id: string) :void {
    this.location.go('settings/timeoff/scheme/general/update/' + id);
  }

  // PUBLIC METHODS

  updateSchema(item: any): void {
    this.navigateToEditView(item.id);
  }

  archiveOrActivateSchema(item: any): void {
    const dialogValues: DialogValueOptions = {
      title: item.active ? 'schemes.modal.archive.title' : 'schemes.modal.activate.title',
      message:  item.active ? 'schemes.modal.archive.message' : 'schemes.modal.activate.message',
      messageParam: { schemaName: item.name },
      acceptButtonText: item.active ? 'global.form.archive' : 'global.form.activate'
    };

    this.dialogService.openConfirmationDialog(dialogValues).subscribe((result: any) => {
      if(result?.accept) {
        this.isSyncing = true;

        let serviceToBeenCalled = '';
        serviceToBeenCalled = item.active ? 'archiveSchema' : 'activateSchema';

        if (serviceToBeenCalled) {
          this.timeoffSettingsService[serviceToBeenCalled](item.id).subscribe(
            (onSuccess: any) => {
              const message = this.translate.instant((item.active ? 
                  'schemes.notification.archive.message' : 'schemes.notification.activate.message'), { schemaName: item.name });
              this.notificationService.showSuccessNotification(message);
              this.getSchemasGridData(this.dataPagingOpts, this.dataFilterOpts);
            },
            (onError: any) => { }
          )
        }
      }
    });
  }

  deleteSchema(item: any): void {
    const dialogValues: DialogValueOptions = {
      title: 'schemes.modal.delete.title',
      message: 'schemes.modal.delete.message',
      messageParam: { schemaName: item.name },
      acceptButtonText: 'global.form.delete'
    };
    this.dialogService.openConfirmationDialog(dialogValues).subscribe((result: any) => {
      if(result?.accept) {
        this.isSyncing = true;
        
        this.timeoffSettingsService.deleteSchema(item.id).subscribe(
          (onSuccess: any) => {
            this.getSchemasGridData(this.dataPagingOpts, this.dataFilterOpts);
            const message = this.translate.instant('schemes.notification.delete.message', { schemaName: item.name });
            this.notificationService.showSuccessNotification(message);
          },
          (onError: any) => { }
        )
      }
    });
  }

  getSchemasByIsActive(isActive: boolean) {
    this.dataFilterOpts.active = isActive;
    this.getSchemasGridData(this.dataPagingOpts, this.dataFilterOpts);
  }

  clearSearchInput(): void {    
    let updateDataGrid = this.dataFilterOpts.name.length >= 3;
    this.dataFilterOpts.name = '';
    if (updateDataGrid) { this.listItemsSearch(); }
  }

  listItemsSearch(): void {     
    if (this.dataFilterOpts.name.length >= 3 || this.dataFilterOpts.name.length === 0) {
      this.getSchemasGridData(this.dataPagingOpts, this.dataFilterOpts);
    }
  }

  onSortChange(sort: SortDescriptor[]): void {      
    this.gridSort = sort;        
    this.dataPagingOpts.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : ['lastModifiedDate,desc', 'name,asc'];
    this.getSchemasGridData(this.dataPagingOpts, this.dataFilterOpts);
  }

  onPageChange(event: PageChangeEvent): void {
    this.dataPagingOpts.page = (event.skip / this.dataPagingOpts.size);
    this.dataPagingOpts.skip = event.skip;
    this.dataPagingOpts.size = event.take;
    this.getSchemasGridData(this.dataPagingOpts, this.dataFilterOpts);
  }

  onCellClick(event: any): void {
    this.clickedRowItem = event.dataItem;
    if(this.dblClickEvent) { 
        this.dblClickEvent = false;
        this.navigateToEditView(this.clickedRowItem.id);
    }
  }

  onDblClick(event: any) : void {
    this.dblClickEvent = true;
    event.target.click();
  }

  skeletonRowCallback(row: RowClassArgs) {
    return {
        'opacity-50': row.index === 1,
        'opacity-30': row.index === 2 
    }
  }
}