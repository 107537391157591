<div class="main-container__content margin-top-40">
    <div class="main-container--medium-width">
        <h3 class="bold margin-bottom-30" [translate]="'myCompany.global.regionalSettingsTitle'"></h3>
        <!-- Available languages -->
        <div class="group-form group-form--label-300 margin-bottom-30" style="flex-wrap: nowrap;">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'myCompany.global.availableLanguages'"></span>
            </div>
            <div class="pill-list">
                @for (language of SUPPORTED_LANGUAGES; track language) {
                    @if (language.active) {
                        <span class="chip" [textContent]="language.name"></span>
                    }
                }
                <!-- Tabindex permite foco sobre este elemento -->
                <section class="supported-languages" (blur)="closeSupportedLanguages()" [tabIndex]="-1">
                    <span role="button" class="link cursor-pointer" style="user-select: none;" (click)="toggleSupportedLanguages($event)">
                        <span [translate]="'global.form.select'"></span>
                        <span class="icon nucleo icon-arrow-down"></span>
                    </span>
                    @if (!!supportedLangsToggle) {
                        <ul class="supported-languages__selector">
                        @for (language of SUPPORTED_LANGUAGES; track language) {
                            <li class="language-selector__available-language" [ngClass]="{'disabled': language.key === globalForm.defaultLanguage}">
                                <span [textContent]="language.name"></span>
                                <label class="toggle__container padding-top-5 justify-content-center">
                                    <div class="toggle toggle--small margin-right-0" [ngClass]="{ 'toggle--active': !!language.active }">
                                        <span class="toggle__indicator"
                                            [title]="(!!language.active ? 'global.form.active_female' : 'global.form.inactive_female') | translate">
                                        </span>
                                    </div>
                                    <input class="toggle__input" type="checkbox" (value)="language.active" (click)="updateAvailableLanguages(language)" />
                                </label>
                            </li>
                        }
                        </ul>
                    }
                </section>
            </div>
        </div>

        <!-- Company language default -->
        <div class="group-form group-form--label-300 margin-bottom-30">
            <div class="group-form__label">
                <span class="group-form__label__text" [textContent]="'myCompany.global.defaultLanguage' | translate"></span>
            </div>
            <div class="group-form__data dui-select">
                <kendo-dropdownlist id="language-select" name="languageSelect" class="dui-dropdown" style="width: 300px"
                    [data]="getAvailablesLanguages()" [(ngModel)]="globalForm.defaultLanguage" (valueChange)="onChangeValueLanguage()"
                    valuePrimitive="true" [valueField]="'key'" [textField]="'name'"
                    [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                    <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>
                    
                    <ng-template kendoDropDownListNoDataTemplate>
                        <h5 class="uppercase" [translate]="'global.form.emptyDataSet'"></h5>
                    </ng-template>
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                        @if (!dataItem) {
                            <span [translate]="'global.form.select'"></span>
                        }
                        @else {
                            <span [textContent]="dataItem.name"></span>
                        }
                    </ng-template>
                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <span class="dialenga-dropdownlist__popup-value" [textContent]="dataItem.name"></span>
                    </ng-template>
                </kendo-dropdownlist>
            </div>
        </div>
        <!-- Company timezone -->
        <div class="group-form group-form--label-300 margin-bottom-30">
            <div class="group-form__label">
                <span class="group-form__label__text" [textContent]="'myCompany.global.timezone' | translate"></span>
            </div>
            <div class="group-form__data">
                <div class="dialenga-combobox timezone min-width-300">
                    <kendo-combobox #timezoneSelect [data]="timezonesList" [(ngModel)]="globalForm.timeZone" [valuePrimitive]="false" textField="timezone"
                        valueField="id" [filterable]="true" [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-combobox__popup' }"
                        (filterChange)="filterTimezones($event)" (valueChange)="onChangeValueTimezone($event)" (blur)="onBlurTimezone()"
                        [placeholder]="'global.form.select' | translate" class="">
                        <kendo-combobox-messages [clearTitle]="('global.modal.clear' | translate)" [noDataText]="'global.form.emptyDataSet' | translate"></kendo-combobox-messages>

                        <ng-template kendoDropDownListNoDataTemplate>
                            <h5 class="uppercase" [translate]="'global.form.emptyDataSet'"></h5>
                        </ng-template>
                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <div class="select-avatar-item min-width-300" *ng-if="dataItem">
                                <span [textContent]="dataItem.timezone"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoComboBoxItemTemplate let-dataItem>
                            <div class="dialenga-combobox__popup-avatar select-avatar-item min-width-300">
                                <span [textContent]="dataItem.timezone"></span>
                            </div>
                        </ng-template>
                    </kendo-combobox>
                </div>
            </div>
        </div>

        <div class="group-form-separator"></div>

        <h3 class="bold margin-bottom-30" [translate]="'myCompany.global.publicationSettingsTitle'"></h3>
        <!-- Comments allowed settings -->
        <div class="group-form group-form--label-300">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'myCompany.global.commentsSettings'"></span>
            </div>
            <div class="group-form__data padding-top-5 align-items-start">
                <toggle-input id="comments-allowed" [inputId]="'comments-allowed'" (change)="enableDisableComments($event)" class="padding-top-5"
                    [(inputModel)]="commentsAllowedToggle">
                </toggle-input>
                @if (commentsAllowedToggle) {
                    <div class="radio-button-container radio-button-container--column margin-left-20 padding-top-5">
                        <div class="radio-button__item margin-bottom-16" style="align-items: flex-start;" (click)="changeCommentsSettings(GENERAL_CONFIG_STATES.ENABLED)"
                            [ngClass]="{'radio-button__item--selected' : globalForm.commentsDefault == GENERAL_CONFIG_STATES.ENABLED}">
                            <span class="radio-button__item__icon icon "
                                [ngClass]="globalForm.commentsDefault == GENERAL_CONFIG_STATES.ENABLED ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <div class="flex direction-column">
                                <span class="radio-button__item__label" [translate]="'myCompany.global.enabledByDefault'"></span>
                                <span class="radio-button__item__label-comment" [translate]="'myCompany.global.enabledByDefaultComment'"></span>
                            </div>
                        </div>
                        <div class="radio-button__item margin-bottom-16" style="align-items: flex-start;" (click)="changeCommentsSettings(GENERAL_CONFIG_STATES.DISABLED)"
                            [ngClass]="{'radio-button__item--selected' : globalForm.commentsDefault == GENERAL_CONFIG_STATES.DISABLED}">
                            <span class="radio-button__item__icon icon "
                            [ngClass]="globalForm.commentsDefault == GENERAL_CONFIG_STATES.DISABLED ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <div class="flex direction-column">
                                <span class="radio-button__item__label" [translate]="'myCompany.global.disabledByDefault'"></span>
                                <span class="radio-button__item__label-comment" [translate]="'myCompany.global.disabledByDefaultComment'"></span>
                            </div>
                        </div>
                        <div class="radio-button__item margin-bottom-0" style="align-items: flex-start;" (click)="changeCommentsSettings(GENERAL_CONFIG_STATES.FORCE_ENABLED)"
                            [ngClass]="{'radio-button__item--selected' : globalForm.commentsDefault == GENERAL_CONFIG_STATES.FORCE_ENABLED}">
                            <span class="radio-button__item__icon icon "
                            [ngClass]="globalForm.commentsDefault == GENERAL_CONFIG_STATES.FORCE_ENABLED ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <div class="flex direction-column">
                                <span class="radio-button__item__label" [translate]="'myCompany.global.forceEnabled'"></span>
                                <span class="radio-button__item__label-comment" [translate]="'myCompany.global.forceEnabledComment'"></span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        <!-- Sharing allowed settings -->
        <div class="group-form group-form--label-300">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'myCompany.global.sharingSettings'"></span>
            </div>
            <div class="group-form__data padding-top-5 align-items-start">
                <toggle-input id="sharing-allowed" [inputId]="'sharing-allowed'" (change)="enableDisableSharing($event)" class="padding-top-5"
                    [(inputModel)]="sharingAllowedToggle">
                </toggle-input>
                @if (sharingAllowedToggle) {
                    <div class="radio-button-container radio-button-container--column margin-left-20 padding-top-5">
                        <div class="radio-button__item margin-bottom-16" style="align-items: flex-start;"
                            [ngClass]="{'radio-button__item--selected' : globalForm.sharingDefault == GENERAL_CONFIG_STATES.ENABLED}"
                            (click)="changeSharingSettings(GENERAL_CONFIG_STATES.ENABLED)">
                            <span class="radio-button__item__icon icon "
                                [ngClass]="globalForm.sharingDefault == GENERAL_CONFIG_STATES.ENABLED ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <div class="flex direction-column">
                                <span class="radio-button__item__label" [translate]="'myCompany.global.enabledByDefault'"></span>
                                <span class="radio-button__item__label-comment" [translate]="'myCompany.global.enabledByDefaultComment'"></span>
                            </div>
                        </div>
                        <div class="radio-button__item margin-bottom-16" style="align-items: flex-start;"
                            [ngClass]="{'radio-button__item--selected' : globalForm.sharingDefault == GENERAL_CONFIG_STATES.DISABLED}"
                            (click)="changeSharingSettings(GENERAL_CONFIG_STATES.DISABLED)">
                            <span class="radio-button__item__icon icon "
                                [ngClass]="globalForm.sharingDefault == GENERAL_CONFIG_STATES.DISABLED ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <div class="flex direction-column">
                                <span class="radio-button__item__label" [translate]="'myCompany.global.disabledByDefault'"></span>
                                <span class="radio-button__item__label-comment" [translate]="'myCompany.global.disabledByDefaultComment'"></span>
                            </div>
                        </div>
                        <div class="radio-button__item margin-bottom-0" style="align-items: flex-start;"
                            [ngClass]="{'radio-button__item--selected' : globalForm.sharingDefault == GENERAL_CONFIG_STATES.FORCE_ENABLED}"
                            (click)="changeSharingSettings(GENERAL_CONFIG_STATES.FORCE_ENABLED)">
                            <span class="radio-button__item__icon icon "
                                [ngClass]="globalForm.sharingDefault == GENERAL_CONFIG_STATES.FORCE_ENABLED ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <div class="flex direction-column">
                                <span class="radio-button__item__label" [translate]="'myCompany.global.forceEnabled'"></span>
                                <span class="radio-button__item__label-comment" [translate]="'myCompany.global.forceEnabledComment'"></span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        <!-- Rating allowed settings -->
        <div class="group-form group-form--label-300">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'myCompany.global.ratingSettings'"></span>
            </div>
            <div class="group-form__data padding-top-5 align-items-start">
                <toggle-input id="rating-allowed" [inputId]="'rating-allowed'" (change)="enableDisableRating($event)" class="padding-top-5"
                    [(inputModel)]="ratingAllowedToggle">
                </toggle-input>
                <!-- Temporarily disabled -->
                @if (false) {
                    <!-- <ng-container *ngIf="ratingAllowedToggle"> -->
                    <div class="radio-button-container radio-button-container--column margin-left-20 padding-top-5">
                        <div class="radio-button__item margin-bottom-16" style="align-items: flex-start;"
                            [ngClass]="{'radio-button__item--selected' : globalForm.ratingDefault == GENERAL_CONFIG_STATES.ENABLED}"
                            (click)="changeRatingSettings(GENERAL_CONFIG_STATES.ENABLED)">
                            <span class="radio-button__item__icon icon "
                                [ngClass]="globalForm.ratingDefault == GENERAL_CONFIG_STATES.ENABLED ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <div class="flex direction-column">
                                <span class="radio-button__item__label" [translate]="'myCompany.global.enabledByDefault'"></span>
                                <span class="radio-button__item__label-comment" [translate]="'myCompany.global.enabledByDefaultComment'"></span>
                            </div>
                        </div>
                        <div class="radio-button__item margin-bottom-16" style="align-items: flex-start;"
                            [ngClass]="{'radio-button__item--selected' : globalForm.ratingDefault == GENERAL_CONFIG_STATES.DISABLED}"
                            (click)="changeRatingSettings(GENERAL_CONFIG_STATES.DISABLED)">
                            <span class="radio-button__item__icon icon "
                                [ngClass]="globalForm.ratingDefault == GENERAL_CONFIG_STATES.DISABLED ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <div class="flex direction-column">
                                <span class="radio-button__item__label" [translate]="'myCompany.global.disabledByDefault'"></span>
                                <span class="radio-button__item__label-comment" [translate]="'myCompany.global.disabledByDefaultComment'"></span>
                            </div>
                        </div>
                        <div class="radio-button__item margin-bottom-0" style="align-items: flex-start;"
                            [ngClass]="{'radio-button__item--selected' : globalForm.ratingDefault == GENERAL_CONFIG_STATES.FORCE_ENABLED}"
                            (click)="changeRatingSettings(GENERAL_CONFIG_STATES.FORCE_ENABLED)">
                            <span class="radio-button__item__icon icon "
                                [ngClass]="globalForm.ratingDefault == GENERAL_CONFIG_STATES.FORCE_ENABLED ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <div class="flex direction-column">
                                <span class="radio-button__item__label" [translate]="'myCompany.global.forceEnabled'"></span>
                                <span class="radio-button__item__label-comment" [translate]="'myCompany.global.forceEnabledComment'"></span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        @if (ratingAllowedToggle) {
            <div class="group-form group-form--label-300 margin-bottom-40">
                <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'myCompany.global.ratingKind'"></span>
                </div>
                <div class="group-form__data padding-top-5 align-items-start">
                    <!-- <div style="width: 56px"></div> -->
                    <div class="radio-button-container radio-button-container--column padding-top-5">
                        <div class="radio-button__item margin-bottom-16" style="align-items: flex-start;" (click)="changeRatingKind(RATING_KIND.EMOJI)"
                            [ngClass]="{'radio-button__item--selected' : globalForm.ratingKind == RATING_KIND.EMOJI}">
                            <span class="radio-button__item__icon icon "
                                [ngClass]="globalForm.ratingKind == RATING_KIND.EMOJI ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <div class="flex direction-column">
                                <span class="radio-button__item__label" [translate]="'myCompany.global.ratingKindEmoji'"></span>
                                <div class="flex justify-space-between margin-top-10 width-75-percent">
                                    @for (number of [1,2,3,4,5]; track number) {
                                        <span class="rating-icon rating-image" [ngClass]="'emoji-' + number"></span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="radio-button__item margin-top-0 margin-bottom-0" style="align-items: flex-start;" (click)="changeRatingKind(RATING_KIND.STAR)"
                            [ngClass]="{'radio-button__item--selected' : globalForm.ratingKind == RATING_KIND.STAR}">
                            <span class="radio-button__item__icon icon "
                                [ngClass]="globalForm.ratingKind == RATING_KIND.STAR ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <div class="flex direction-column">
                                <span class="radio-button__item__label" [translate]="'myCompany.global.ratingKindStars'"></span>
                                <div class="flex justify-space-between width-75-percent">
                                    @for (number of [1,2,3,4,5]; track number) {
                                        <span class="icon icon-32" [ngClass]="number == 5 ? 'icon-star' : 'icon-star-fill'"></span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
</div>