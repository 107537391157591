import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Constants } from '../../constants';

@Component({
    templateUrl: './timeoff-schema-absences-modal.component.html'
  })

  export class TimeoffSchemaAbsencesModalComponent implements OnInit, AfterViewInit {

    public ABSENCE_MOTIVE_GROUPS: any;
    public modalTitle: string = '';
    public language: any = {
        selected: this.data.selectedLanguage,
        default: this.data.defaultLanguage
    }
    public motive: any = {
        contents: {}
    };
    public group: any = this.data.group;
    public isValidFormMotive: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<TimeoffSchemaAbsencesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) 
    {
        this.ABSENCE_MOTIVE_GROUPS = Constants.ABSENCE_MOTIVE_GROUPS;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.language.selected = this.data.selectedLanguage;
        });
    }

    ngOnInit(): void {
        if (!!this.data.motive) {
            this.motive = { ... this.data.motive};
            this.modalTitle = 'schemes.absences.modal.titleUpdate';
            this.validateModel();
        } else {
            this.motive.contents[this.language.default] = {
                name: ''
            }
            this.modalTitle = 'schemes.absences.modal.titleCreate';
        }
    }

    validateModel(): void {
        const isValidName = this.motive.contents[this.language.selected] && this.motive.contents[this.language.selected].name !== '';
        const isValidrequireNote = this.motive.requiresNote ? (this.motive.daysToAttachFile !== null && this.motive.daysToAttachFile >= 0) : true;
        this.isValidFormMotive = isValidName && isValidrequireNote;
    }

    onInputNumberChange(field: any): void {
        let minValue = parseInt(field.getAttribute('min'));
        let maxValue = parseInt(field.getAttribute('max'));
        let value: number = parseInt(field.value) <= minValue ? minValue : (parseInt(field.value) >= maxValue ? maxValue : parseInt(field.value));
    
        field.value = value;
        this.motive.daysToAttachFile = value;
        this.onChange();
    }

    onChange(): void {
        this.validateModel();
    }

    onAccept(): void {
        this.dialogRef.close({accept: true, motive: this.motive});
    }

    onCancel(): void {
        this.dialogRef.close({cancel: true});
    }

}