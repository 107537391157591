import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { Constants } from '../../constants';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { ChatroomAdminsModalComponent } from '../chatroom/chatroom-admins-modal.component';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { TimeTrackingService } from '../time-tracking/time-tracking.service';

@Component({
    selector: 'schedule',
    templateUrl: './schedule.component.html'
})
export class ScheduleComponent implements OnInit {

    public SCHEDULE_TABS: any;
    public SCHEDULE_PERIOD_KIND: any;
    
    public selectedTab: number;
    private _route: any;
    private scheduleId: number;
    
    public scheduleForm: schedule = {
        name: '',
        periodKind: Constants.SCHEDULE_PERIOD_KIND.ALWAYS,
        datePeriods: [],
        scheduleMargin: {
            flexibleSchedule: false,
            priorMarginIn: 0,
            laterMarginIn: 0,
            priorMarginOut: 0,
            laterMarginOut: 0
        },
        workDays: []
    };
    private lastScheduleName: string = '';

    @ViewChild('scheduleParentName') scheduleNameRef: ElementRef;

    constructor (
        private location: Location, private upgrade: UpgradeModule, private titleService: Title, private timeTrackingService: TimeTrackingService, 
        private translate: TranslateService, public dialogService: DialogService, public formUtilsService: FormUtilsService, private Dialog: MatDialog,
        private notificationService: DuiNotificationsService

    ) { 
        this.SCHEDULE_TABS = Constants.SCHEDULE_TABS;
        this.SCHEDULE_PERIOD_KIND = Constants.SCHEDULE_PERIOD_KIND;
        this._route = this.upgrade.$injector.get('$route');
        this.scheduleId = this._route.current.params.id;

        this.selectedTab = this._route.current.locals.selectedTab;
    }

    ngOnInit() {
        this.timeTrackingService.findSchedule(this.scheduleId).subscribe(
            (response: any) => {
                this.scheduleForm = response;
                this.lastScheduleName = response.name;
            },
            (onError: any) => {
                // Show notification error
            }
        );
    }

    private checkScheduleNameAndSave(newName: string) :void {
        if(!newName) {
            this.scheduleNameRef.nativeElement.textContent = this.scheduleForm.name;
            return;
        } else if( newName !== this.scheduleForm.name) {
            this.scheduleForm.name = this.scheduleNameRef.nativeElement.textContent;
            this.scheduleFormSave();
        }
    }

    private scheduleFormSave() :void {
        this.timeTrackingService.updateScheduleName(this.scheduleId, this.scheduleForm.name).subscribe(
            (response: any) => {
                this.scheduleForm.name = response.name;
                this.lastScheduleName = response.name;

                this.notificationService.showSuccessNotification();
            },
            (respError: any) => {
                this.scheduleNameRef.nativeElement.focus();
                // Reset schedule name to last name
                this.scheduleForm.name = this.lastScheduleName;
                this.scheduleNameRef.nativeElement.textContent = this.lastScheduleName;
                // Select the name
                var range = document.createRange();
                range.selectNodeContents(this.scheduleNameRef.nativeElement);
                var sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
                
            }
        );
    }

    onInputScheduleNameEvent(event: any) {
        if(event.type === 'keydown') {
            event.preventDefault();
            if (event.code === 'Escape') {
                this.scheduleNameRef.nativeElement.textContent = this.scheduleForm.name;
            }
            this.scheduleNameRef.nativeElement.blur();
        }
        this.checkScheduleNameAndSave(this.scheduleNameRef.nativeElement.textContent);
    }

    onInputScheduleNamePaste(event: any) :void {
        event.preventDefault();

        var selectedText = window.getSelection().toString();
        if (selectedText) {
            this.scheduleNameRef.nativeElement.textContent = this.scheduleNameRef.nativeElement.textContent.replace(selectedText, event.clipboardData.getData('text'));
        } else {
            this.scheduleNameRef.nativeElement.textContent += event.clipboardData.getData('text');
        }
        this.checkScheduleNameAndSave(this.scheduleNameRef.nativeElement.textContent);
    }

    navigateTo(url: string): void {
        this.location.go(url);
    }

    loadTabContent( tab: number): void {
        if (this.selectedTab !== tab) {
            this.selectedTab = tab;
            if (tab === this.SCHEDULE_TABS.GENERAL) {
                this.titleService.setTitle(this.translate.instant('timeTracking.schedule.title'));
                this._route.updateParams({ tabName: 'general' });
            } else if (tab === this.SCHEDULE_TABS.RECORD_KINDS) {
                this._route.updateParams({ tabName: 'record-kinds'});
            } else if (tab === this.SCHEDULE_TABS.USERS) {
                this._route.updateParams({ tabName: 'users'});
            }
        }
    }

    deleteSchedule(): void {
        const dialogValues: DialogValueOptions = {
            title: 'timeTracking.schedules.modal.delete.title',
            message: 'timeTracking.schedules.modal.delete.message',
            messageParam: { scheduleName: this.scheduleForm.name }
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe(result => {
            if(result?.accept) {
                this.timeTrackingService.delete(this.scheduleForm.id).subscribe(
                    (onSuccess) => {
                        const message = this.translate.instant('timeTracking.schedules.notification.delete.message', { scheduleName: this.scheduleForm.name });
                        this.notificationService.showSuccessNotification(message);
                        // Go back to chatrooms list
                        this.navigateTo('/settings/time-tracking/schedules');
                    },
                    (onError) => { }
                )
            }
        });
    }

    archiveOrRestore(): void {

    }

    addUsersToSchedule(): void {
        // get all the employees from schedule for pre-charge modal
        this.timeTrackingService.findAllEmployees(this.scheduleId, {page: 0, size: 20000}, null).subscribe(
            (response) => {
                this.formUtilsService.finishSubmitAction();

                const dialogSettings = new MatDialogConfig();
                dialogSettings.data = {
                    title: this.translate.instant('timeTracking.schedule.employees.modal.title'),
                    users: response.content
                    };
                dialogSettings.width = '65vw';
                dialogSettings.position = { top: '4vh'};
                dialogSettings.panelClass = ['animated','slideInDown'];

                this.Dialog.open(ChatroomAdminsModalComponent, dialogSettings).afterClosed().subscribe(result => {
                    this.formUtilsService.finishSubmitAction();

                    if (result?.arePendingChanges && result?.addedUsers) {
                        this.scheduleForm.employees = result.addedUsers;

                        this.notificationService.showSuccessNotification();
                    }
                });
                },
            (error: any) => {
                // Show notification error
            }
        );
    }
}

export interface periodItem {
    startDay: number,
    startMonth: number,
    endDay: number,
    endMonth: number
}

export interface scheduleWorkDay {
    id?: number,
    dayOfWeek: number,
    timePeriods: Array<any>
}

export interface schedule {
    id?: number,
    name?: string,
    periodKind?: number,
    datePeriods?: Array<periodItem>,
    workDays?: Array<scheduleWorkDay>,
    scheduleMargin?: {
        flexibleSchedule: boolean,
        priorMarginIn?: number,
        laterMarginIn?: number,
        priorMarginOut?: number,
        laterMarginOut?: number
    },
    schedules?: Array<any>,
    employees?: Array<any>,
    active?: boolean,
    deletable?: boolean
}