import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Constants } from "../../constants";

@Injectable({ providedIn: "root" })
export class TimeoffSettingsService {

  public apiUrl: string;
  public schemasApiUrl: string;
  public timeoffglobalSettingsApiUrl: string;

  constructor(private httpClient: HttpClient) {
    this.apiUrl = Constants.apiUrl;
    this.schemasApiUrl = "/timeoff/schemas";
    this.timeoffglobalSettingsApiUrl = "/timeoff/config/my-company";
  }

  // SCHEMAS METHODS CRUD

  findAllSchemas(pagerOptions: any, filterOptions: any) {  
    const httpOptions = {
      params: {
        page: pagerOptions.page || 0,
        size: pagerOptions.size || 10,
        active: filterOptions.active
      },
      reportProgress: true
    };
    if (pagerOptions?.sort) { httpOptions.params['sort'] = pagerOptions.sort; }
    if (filterOptions?.name) { httpOptions.params['name'] = filterOptions.name; }
    
    return this.httpClient.get<any>(`${this.apiUrl}${this.schemasApiUrl}`, httpOptions);
  }

  findSchema(id: number) {
    return this.httpClient.get<any>(`${this.apiUrl}${this.schemasApiUrl}/${id}`);
  }

  createSchema(data: any) {
    return this.httpClient.post(`${this.apiUrl}${this.schemasApiUrl}`, data);
  }

  updateSchema(id: number, schemaData: any) {
    return this.httpClient.put<any>(`${this.apiUrl}${this.schemasApiUrl}/${id}`, schemaData);
  }

  updateSchemaName(id: number, newName: any) {
    return this.httpClient.put<any>(`${this.apiUrl}${this.schemasApiUrl}/${id}`, {name: newName});
  }

  archiveSchema(id: number) {
    return this.httpClient.put(`${this.apiUrl}${this.schemasApiUrl}/${id}/archive`, null);
  }

  activateSchema(id: number) {
    return this.httpClient.put(`${this.apiUrl}${this.schemasApiUrl}/${id}/activate`, null);
  }

  deleteSchema(id: number) {
    return this.httpClient.delete<any>(`${this.apiUrl}${this.schemasApiUrl}/${id}`);
  }

  // SCHEMAS ABSENCES MOTIVES METHODS

  findSchemaAbsences(id: number) {
    return this.httpClient.get<any>(`${this.apiUrl}${this.schemasApiUrl}/${id}/absence-motives`);
  };

  updateMotiveStatus(schemaId: number, groupId: number, motiveId: number, motiveEnabled: boolean) {
    let updateStatusUrl = this.apiUrl + this.schemasApiUrl + '/' + schemaId + '/absence-motives/' + groupId + '/motives/' + motiveId;
    updateStatusUrl += motiveEnabled ? '/enable' : '/disable';
    
    return this.httpClient.put(updateStatusUrl, null);

  };

  createAbsenceMotiveInSchema(schemaId: number, groupId: number, motive: any) {
    return this.httpClient.post(`${this.apiUrl}${this.schemasApiUrl}/${schemaId}/absence-motives/${groupId}/motives`, motive);
  }
  
  updateAbsenceMotiveInSchema(schemaId: number, groupId: number, motive: any) {
    return this.httpClient.put(`${this.apiUrl}${this.schemasApiUrl}/${schemaId}/absence-motives/${groupId}/motives/${motive.id}`, motive);
  }

  // SCHEMAS EMPLOYEES METHODS

  findAllEmployees(schemaId: number, pagerOptions: any, filterOptions: any) {
    const httpOptions = {
      params: {
        page: pagerOptions.page || 0,
        size: pagerOptions.size || 10
      },
      reportProgress: true
    };
    if (pagerOptions?.sort) { httpOptions.params['sort'] = pagerOptions.sort; }
    if (filterOptions?.name) { httpOptions.params['name'] = filterOptions.name; }
    
    return this.httpClient.get<any>(`${this.apiUrl}${this.schemasApiUrl}/${schemaId}/employees`, httpOptions);
  }

  updateEmployees(schemaId: number, employees: Array<any>) {
    let employeesIds: Array<any> = [... employees];
    employeesIds = employeesIds.length > 0 ? employeesIds.map((employee: any) => {
      return employee.hasOwnProperty('id') ? employee['id'] : employee['user'];
    }) : [];
    return this.httpClient.post<any>(`${this.apiUrl}${this.schemasApiUrl}/${schemaId}/employees`, employeesIds);
  }

  deleteEmployee(schemaId: number, employeeId: number) {
    return this.httpClient.delete<any>(`${this.apiUrl}${this.schemasApiUrl}/${schemaId}/employees/${employeeId}`);
  }

  // TIMEOFF GLOBAL SETTINGS CONFIGURATION METHODS

  getTimeoffGlobalSettings() {
    return this.httpClient.get<any>(`${this.apiUrl}${this.timeoffglobalSettingsApiUrl}`);
  }

  setTimeoffGlobalSettings(data: any) {
    return this.httpClient.put<any>(`${this.apiUrl}${this.timeoffglobalSettingsApiUrl}`, data);
  }
    
}
