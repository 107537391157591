<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="'companies.newCompany' | translate"></h3>
</div>

<div class="modal-body">
    <!-- Company name -->
    <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text" [textContent]="'companies.modal.name' | translate"></span>
        </div>
        <div class="group-form__data">
            <input #companyName 
                id="companyName" 
                name="companyName"
                data-cy="company-name"
                type="text"
                class="input" 
                [ngClass]="{'input--error': isNameDuplicated}" 
                maxlength="64" 
                [(ngModel)]="companyForm.name" 
                (ngModelChange)="onChange()" 
                (keyup)="isNameDuplicated = false" 
                (keyup.enter)="onEnterPressed()" 
                [placeholder]="'companies.grid.companyName' | translate" />
        </div>
    </div>
    <!-- Company CIF -->
    <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text" [textContent]="'companies.grid.cif' | translate"></span>
            <span class="group-form__label__text group-form__label__text--optional lowercase" style="font-size: 80%;">({{'global.form.optional' | translate}})</span>
        </div>
        <div class="group-form__data">
            <input #CompanyCif id="companyCif" class="input width-50-percent" data-cy="company-cif" [(ngModel)]="companyForm.cif" 
                (ngModelChange)="onChange()" (keyup.enter)="onEnterPressed()" maxlength="12" [placeholder]="'companies.modal.cifPlaceholder' | translate" />
        </div>
    </div>
    <!-- Company managing director -->
    <div class="group-form group-form--label-150 margin-bottom-0">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'companies.grid.director'"></span>
            <span class="group-form__label__text group-form__label__text--optional lowercase" style="font-size: 80%;">({{'global.form.optional' | translate}})</span>
        </div>
        <div class="group-form__data">
            <user-dropdown class="min-width-100-percent" [(inputModel)]="companyForm.director" (onSelection)="onChange()"></user-dropdown>
        </div>
    </div>
</div>

<div class="modal-footer flex justify-end">
    <loading-button [buttonId]="'company-create'" [classes]="'button--secondary margin-right-10'" [text]="'companies.modal.createCompany'" 
        (action)="onAccept()" [isValid]="isFormValid">
    </loading-button>
    <button id="company-cancel" data-cy="company-cancel" type="button" class="button" (click)="onCancel()">
        <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
    </button>
</div>
