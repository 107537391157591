<div class="request-detail" [ngClass]="{'justify-start': selectedTab === REQUEST_TABS.FILES || !(selectedTab !== REQUEST_TABS.FILES && !request?.closedOn) || (selectedTab === REQUEST_TABS.NOTES && !!request?.closedOn)}">
    @if (!!request) {
        <section class="request-detail__header">
            <div class="first-row">
                @if (request?.anonymous) {
                    <user-avatar [medium]="true" [user]="{name: 'global.userstates.anonymous' | translate, surname: '' }" [anonymous]="true"></user-avatar>
                } @else {
                    <div class="flex align-items-center" kendoTooltip filter="a.request-detail__header__sender-name">
                        <avatar-popover class="margin-right-10 cursor-default" [nationalId]="request?.author?.nationalIdNumber" [username]="request?.author?.username" [internalId]="request?.author?.companyInternalId">
                            <avatar [name]="request?.author?.name" [surname]="request?.author?.surname" [medium]="true" [thumbnails]="{thumbnail_M: request?.author?.avatarThumbnailMUrl || request?.author?.avatarUrl, thumbnail_S: request?.author?.avatarThumbnailSUrl || request?.author?.avatarThumbnailMUrl}"></avatar>
                        </avatar-popover>
                        <a class="request-detail__header__sender-name text-decoration-none cursor-pointer non-selectable" [title]="'users.linkToUser' | translate" [textContent]="request?.author?.fullName" [href]="'/user/personaldata/update/' + request?.author?.user"></a>
                    </div>
                }
                <!-- Actions -->
                <div class="flex align-items-center" kendoTooltip filter="span">
                    <!-- Validate request -->
                    @if (isAdmin && request && !!request?.validationState && !!request?.closedOn && request?.validationState === REQUEST_VALIDATION_STATE.PENDING) {
                        <span id="validate-request" class="icon icon-ok-fill warning-light-intermediate-color icon-28 margin-right-16 cursor-pointer" (click)="validationRequest()" [title]="'conversations.pendingValidation' | translate"></span>
                    }
                    <!-- Discard request validation -->
                    @if (isAdmin && request && !!request?.validationState && request?.validationState !== REQUEST_VALIDATION_STATE.PENDING) {
                        <dui-popover [onlyOnClick]="true" class="popover-discard-validation align-self-center height-28">
                            <ng-container popoverItem>
                                <span id="dismiss-request-validation" class="icon icon-28 margin-right-16 cursor-pointer" [ngClass]="{ 'icon-ok-fill green-icon-color': request?.validationState === REQUEST_VALIDATION_STATE.VALIDATED, 'icon-remove-fill advice-color': request?.validationState === REQUEST_VALIDATION_STATE.REJECTED }" [title]="(request?.validationState === REQUEST_VALIDATION_STATE.VALIDATED ? 'conversations.validatedConversation' : 'conversations.rejectedConversation') | translate"></span>
                            </ng-container>
                            <div class="popover-discard-validation__container">
                                <button id="discard-validation" class="button button--small" (click)="discardValidation()">
                                    <span class="button__label" [translate]="'conversations.preview.tooltips.discardValidation'"></span>
                                </button>
                            </div>
                        </dui-popover>
                    }
                    <!-- Change Request tray or motive. Request metadata -->
                    <dui-popover [onlyOnClick]="true" class="popover-metadata align-self-center">
                        <ng-container popoverItem>
                            <span #requestMetadata id="request-metadata" class="icon icon-info icon-28 secondary-color icon-clickable" [title]="'conversations.preview.tooltips.details' | translate"></span>
                        </ng-container>
                        <div class="popover-metadata__container">
                            <!-- Tray -->
                            <div class="popover-metadata__item">
                                <span class="popover-metadata__label" [translate]="'conversations.tray'"></span>
                                <kendo-dropdownlist #trayRequest [data]="traysItems" [(ngModel)]="requestTray" [disabled]="request?.anonymous" 
                                    class="kendo-angular-dropdown margin-left-8 width-70-percent" [valuePrimitive]="false" (valueChange)="onTrayChanged()" 
                                    [textField]="'alias' || 'name'" [valueField]="'id'" [popupSettings]="{ width: 'auto'}">
                                    <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                                        <span class="truncate" [textContent]="dataItem?.alias || dataItem?.name"></span>
                                    </ng-template>
                                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                                        <div class="flex align-items-center min-height-28">
                                            <span class="truncate padding-3" [textContent]="dataItem?.alias || dataItem?.name"></span>
                                        </div>
                                    </ng-template>
                                </kendo-dropdownlist>
                            </div>
                            <!-- Motive -->
                            <div class="popover-metadata__item">
                                <span class="popover-metadata__label" [translate]="'conversations.motiveLabel'"></span>
                                <kendo-dropdownlist #motiveRequest [data]="motivesItems" [(ngModel)]="requestMotive" [disabled]="request?.anonymous" 
                                    class="kendo-angular-dropdown margin-left-8 width-70-percent" [valuePrimitive]="false" (valueChange)="onMotiveChanged()" 
                                    [textField]="'motive'" [valueField]="'id'" [popupSettings]="{ width: 'auto'}">
                                    <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                                        @if (dataItem?.id) {
                                            <span class="icon icon-20 icon-tag-fill-s margin-right-10" [ngStyle]="{'color': getMotiveTagHexColor(dataItem.color)}"></span>
                                        }
                                        <span class="truncate" [textContent]="dataItem?.motive"></span>
                                    </ng-template>
                                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                                        <div class="flex align-items-center min-height-28 width-300">
                                            @if (dataItem?.id) {
                                                <span class="icon icon-20 icon-tag-fill-s margin-right-10" [ngStyle]="{'color': getMotiveTagHexColor(dataItem.color)}"></span>
                                            }
                                            <span class="truncate" [textContent]="dataItem?.motive"></span>
                                        </div>
                                    </ng-template>
                                </kendo-dropdownlist>
                            </div>
                            <div class="popover-metadata__data">
                                <p class="black-30 font-size-12 margin-top-15" [translate]="'conversations.openOn_Angular'" [translateParams]="{date: requestConversation.openOn | date: 'dd/MM/yyyy HH:mm'}"></p>
                                @if (requestConversation.lastResponsibleEditionDate && !requestConversation.closedOn) {
                                    <p class="black-30 font-size-12 truncate" [translate]="'conversations.modifiedOn_Angular'" [translateParams]="{date: requestConversation.lastResponsibleEditionDate | date: 'dd/MM/yyyy HH:mm', editor: requestConversation.lastResponsibleEditor}"></p>
                                }
                                @if (requestConversation.closedOn) {
                                    <p class="black-30 font-size-12 truncate" [translate]="'conversations.closedOnBy_Angular'" [translateParams]="{date: requestConversation.closedOn | date: 'dd/MM/yyyy HH:mm', editor: requestConversation.lastResponsibleEditor}"></p>
                                }
                            </div>
                        </div>
                    </dui-popover>
                    <!-- Close request -->
                    @if (request && !request?.closedOn) {
                        <button id="close-request" class="button button--small margin-left-10" (click)="request?.id && closeRequest()">
                            <span class="button__icon icon icon-archive"></span>
                            <span class="button__label" [translate]="'global.modal.close'"></span>
                        </button>
                    }
                    @if (request && request?.closedOn) {
                        <span id="reopen-request" class="icon icon-unarchive icon-24 secondary-color margin-left-16 icon-clickable"
                            (click)="request?.id && reopenRequest()" [title]="'conversations.modal.reopen.title' | translate">
                        </span>
                    }
                </div>
            </div>
            <div class="last-row">
                <div class="tab-nav tab-nav--no-border">
                    <span id="detail-tab" class="tab-nav__item font-size-13 black-60" [ngClass]="{'tab-nav__item--selected': selectedTab === REQUEST_TABS.DETAIL}" 
                        (click)="loadTabContent(REQUEST_TABS.DETAIL)">
                        <p class="tab-nav__label margin-bottom-0" [translate]="'timeoffRequests.conversation'"></p>
                    </span>
                    <span id="files-tab" class="tab-nav__item font-size-13 black-60" [ngClass]="{'tab-nav__item--selected': selectedTab === REQUEST_TABS.FILES}" 
                        (click)="loadTabContent(REQUEST_TABS.FILES)">
                        <p class="tab-nav__label margin-bottom-0" [translate]="'conversations.files'"></p>
                        @if (requestConversation.attachmentsCount) {
                            <p class="tab-nav__dot-number margin-left-8" [textContent]="requestConversation.attachmentsCount"></p>
                        }
                    </span>
                    <span id="notes-tab" class="tab-nav__item font-size-13 black-60" [ngClass]="{'tab-nav__item--selected': selectedTab === REQUEST_TABS.NOTES}" 
                        (click)="loadTabContent(REQUEST_TABS.NOTES)">
                        <p class="tab-nav__label margin-bottom-0" [translate]="'conversations.notes'"></p>
                        @if (requestConversation.notesCount) {
                            <p class="tab-nav__dot-number margin-left-8" [textContent]="requestConversation.notesCount"></p>
                        }
                    </span>
                </div>
            </div>
        </section>
    }
    
    @if (!!request) {
        <section class="request-detail__body">
            <!-- TAB DETAIL: Show request conversation messages-->
            @if (selectedTab === REQUEST_TABS.DETAIL) {
                <div #conversationContainer id="conversation-container" class="request-conversation scroll7">
                    @for (message of requestConversation.messages; track message; let idx = $index; let isLast = $last) {
                        <!-- Show grouped questionnaire -->
                        @if (message.automaticConversation) {
                            <article class="auto-conversation__item">
                                @if ((idx == 0 || (idx > 0 && !requestConversation.messages[idx - 1].automaticConversation))) {
                                    <section class="auto-conversation__sender-info" kendoTooltip filter="time">
                                        @if (!!requestConversation.author) {
                                            <h6 class="auto-conversation__author-name" [textContent]="requestConversation.author.fullName"></h6>
                                        }
                                        @if (!requestConversation.author && requestConversation.anonymous) {
                                            <h6 class="auto-conversation__author-name" [translate]="'global.userstates.anonymous'"></h6>
                                        }
                                        <time class="auto-conversation__timestamp" [textContent]="utilsService.getTimeElapsedFrom(message.sentOn)" [title]="message.sentOn | date: 'dd/MM/yyyy HH:mm'"></time>
                                    </section>
                                }
                                @if ((idx == 0 || (idx > 0 && !requestConversation.messages[idx - 1].automaticConversation))) {
                                    <section class="auto-conversation__avatar margin-top-2">
                                        @if (!!requestConversation.author) {
                                            <avatar [name]="requestConversation.author.name" [surname]="requestConversation.author.surname" [small]="true" [thumbnails]="{thumbnail_M: requestConversation.author.avatarThumbnailMUrl || requestConversation.author.avatarUrl, thumbnail_S: requestConversation.author.avatarThumbnailSUrl || requestConversation.author.avatarThumbnailMUrl}"></avatar>
                                        }
                                        @if (!requestConversation.author && requestConversation.anonymous) {
                                            <avatar [user]="{name: '', surname: '' }" [small]="true" [anonymous]="true"></avatar>
                                        }
                                    </section>
                                }
                                <section class="auto-conversation__content" [ngClass]="getDialogQuestionnaireItemPositionClass(idx)">
                                    @if ((idx == 0 || (idx > 0 && !requestConversation.messages[idx - 1].automaticConversation))) {
                                        <h3 class="auto-conversation__title margin-bottom-32 margin-top-1" [translate]="'conversations.automaticConversation'"></h3>
                                    }
                                    @if (message.message) {
                                        @if (!message.author && !message.anonymousSender) {
                                            <p class="auto-conversation__question margin-top-24" [innerHTML]="message.message"></p>
                                        }
                                        @if (!!message.author || message.anonymousSender) {
                                            <p class="auto-conversation__answer margin-top-8" [innerHTML]="message.message"></p>
                                        }
                                    }
                                    @if (!message.message && !message.resource) {
                                        <p class="auto-conversation__answer auto-conversation__answer--skip-step margin-top-8">
                                            @if (!!requestConversation.author && !requestConversation.anonymous) {
                                                <span [translate]="'conversations.senderSkipStep'" [translateParams]="{sender: message.author?.fullName || ''}"></span>
                                            }
                                            @if (!requestConversation.author && requestConversation.anonymous) {
                                                <span [translate]="'conversations.anonymousSkipStep'"></span>
                                            }
                                        </p>
                                    }
                                    @if (message.resource) {
                                        <div class="cursor-pointer margin-top-8" [ngClass]="{'message-resource__image': isImage(message.resource.mimetype), 'message-resource__file': !isImage(message.resource.mimetype)}" (click)="downloadResource(message.resource.url, message.resource.nameWithExtension)">
                                            @if (isImage(message.resource.mimetype)) {
                                                <img [src]="message.resource.url" alt="" onerror="this.src='/ng1/assets/img/mime_image.svg';this.style.border='none'">
                                            } @else {
                                                <img [src]="utilsService.getIconFromMimeType(message.resource.mimetype)" class="message-resource__file-icon"/>
                                                <div class="message-resource__file-name">
                                                    <span [textContent]="message.resource.nameWithExtension"></span>
                                                    <span [textContent]="getResourceMetadata(message.resource)"></span>
                                                </div>
                                            }
                                        </div>
                                    }
                                </section>
                            </article>
                        }
                        <!-- Show messages -->
                        @if (!message.automaticConversation) {
                            <article class="dialog-conversation__item" [ngClass]="{'dialog-conversation__item--mine': !!message.mine}">
                                @if (showMessageSentOnInfo(message, idx)) {
                                    <section class="dialog-conversation__sender-info" kendoTooltip filter="time">
                                        @if (!message.mine) {
                                            @if (!!message.author) {
                                                <h6 class="dialog-conversation__author-name" [textContent]="message.author.fullName"></h6>
                                            }
                                            @if (!message.author && message.anonymousSender) {
                                                <h6 class="dialog-conversation__author-name" [translate]="'global.userstates.anonymous'"></h6>
                                            }
                                        }
                                        <time class="dialog-conversation__timestamp" [textContent]="utilsService.getTimeElapsedFrom(message.sentOn)" [title]="message.sentOn | date: 'dd/MM/yyyy HH:mm'"></time>
                                    </section>
                                }
                                @if (showMessageSentOnInfo(message, idx) && !message.mine) {
                                    <section class="dialog-conversation__avatar">
                                        @if (!!message.author) {
                                            <avatar [name]="message.author.name" [surname]="message.author.surname" [small]="true" [thumbnails]="{thumbnail_M: message.author.avatarThumbnailMUrl || message.author.avatarUrl, thumbnail_S: message.author.avatarThumbnailSUrl || message.author.avatarThumbnailMUrl}"></avatar>
                                        }
                                        @if (!message.author && message.anonymousSender) {
                                            <avatar [user]="{name: '', surname: '' }" [small]="true" [anonymous]="true"></avatar>
                                        }
                                    </section>
                                }
                                <section class="dialog-conversation__content" [ngClass]="getDialogItemPositionClass(message, idx)">
                                    @if (!!message.message) {
                                        <p id="message-{{idx}}" class="dialog-conversation__message" [innerHTML]="message.message"></p>
                                    }
                                    @if (message.linkedPublications && message.linkedPublications.length > 0) {
                                        <article class="message-links__container">
                                            @for (link of message.linkedPublications; track link) {
                                                <div class="message-links__item" [ngClass]="{'expired': link.state === MESSAGE_STATES.EXPIRED}">
                                                    @if (link.state !== MESSAGE_STATES.EXPIRED) {
                                                        @if (link.imageURL) {
                                                            <figure class="content-image content-image--xl" [style.--content-image-background]="'url(' + link.imageURL + ')'">
                                                                @if (link.gallerySize !== 0) {
                                                                    <figcaption class="content-kind-badge">
                                                                        <span [textContent]="'+' + (link.gallerySize - 1)"></span>
                                                                    </figcaption>
                                                                }
                                                                @if (link.kind === CONTENT_KIND.SURVEY) {
                                                                    @if (link.anonymousSurvey) {
                                                                        <span class="icon icon-heisenberg anonymous-badge"></span>
                                                                    }
                                                                    <figcaption class="content-kind-badge">
                                                                        <span class="icon icon-questionnaire"></span>
                                                                    </figcaption>
                                                                }
                                                            </figure>
                                                        } @else {
                                                            @if (link.youtubeURL) {
                                                                <figure class="content-image content-image--xl" [style.--content-image-background]="'url(' + getVideoImgPreview(link.youtubeURL) + ')'">
                                                                    <figcaption class="content-kind-badge">
                                                                        <span class="icon icon-play"></span>
                                                                    </figcaption>
                                                                </figure>
                                                            } @else {
                                                                <figure class="content-image content-image--xl" data-is-category [style.--content-image-background]="link.category.imageURL ? 'url(' + link.category.imageURL + ')' : null" [style.--content-image-overlay-color]="utilsService.getCategoryColor(link.category)">
                                                                    @if (link.kind === CONTENT_KIND.SURVEY) {
                                                                        @if (link.anonymousSurvey) {
                                                                            <span class="icon icon-heisenberg anonymous-badge"></span>
                                                                        }
                                                                        <figcaption class="content-kind-badge">
                                                                            <span class="icon icon-questionnaire"></span>
                                                                        </figcaption>
                                                                    }
                                                                </figure>
                                                            }
                                                        }
                                                        <div class="message-links__data">
                                                            <span class="message-links__title" [textContent]="link.title"></span>
                                                            <span class="message-links__category truncate" [textContent]="link.category.name" [ngStyle]="{'background-color': utilsService.getCategoryColor(link.category)}"></span>
                                                        </div>
                                                    } @else {
                                                        <div class="message-links__not-available-img">
                                                            <img src="/ng1/assets/img/dialenguin-no-matches.svg">
                                                            <p [translate]="'publication.notAvailable'"></p>
                                                        </div>
                                                        <div class="message-links__data">
                                                            <span class="message-links__title" [textContent]="link.title"></span>
                                                            <span class="message-links__category truncate" [textContent]="link.category.name"></span>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </article>
                                    }
                                    @if (message.resource) {
                                        <div class="cursor-pointer" [ngClass]="{'message-resource__image message-resource__image--right': isImage(message.resource.mimetype), 'message-resource__file': !isImage(message.resource.mimetype)}" (click)="downloadResource(message.resource.url, message.resource.nameWithExtension)">
                                            @if (isImage(message.resource.mimetype)) {
                                                <img [src]="message.resource.url" alt="" onerror="this.src='/ng1/assets/img/mime_image.svg';this.style.border='none'" (load)="scrollMessagesToBottom()">
                                            } @else {
                                                <img [src]="utilsService.getIconFromMimeType(message.resource.mimetype)" class="message-resource__file-icon"/>
                                                <div class="message-resource__file-name">
                                                    <span [textContent]="message.resource.nameWithExtension"></span>
                                                    <span [textContent]="getResourceMetadata(message.resource)"></span>
                                                </div>
                                            }
                                        </div>
                                    }
                                </section>
                            </article>
                        }
                        <!-- Resource file template -->
                    }
                    <!-- Request closed info -->
                    @if (request?.closedOn) {
                        <section class="dialog-closed margin-block-32">
                            <h5 class="dialog-closed__metadata">
                                @if (requestConversation.resolver) {
                                    <span [textContent]="requestConversation.resolver?.fullName"></span>
                                }
                                <span [ngClass]="{'margin-left-8': requestConversation.resolver}" [textContent]="utilsService.getTimeElapsedFrom(requestConversation.closedOn)"></span>
                            </h5>
                            <div class="dialog-closed__wrapper margin-top-6">
                                <article class="dialog-closed__state">
                                    <span class="icon-16 icon icon-state-locked"></span>
                                    <p class="dialog-closed__label" [translate]="'conversations.closedConversation'"></p>
                                </article>
                            </div>
                        </section>
                    }
                    <!-- Request rated info -->
                    @if (request.rating) {
                        <article class="dialog-conversation__item">
                            <section class="dialog-conversation__sender-info" kendoTooltip filter="time">
                                <h6 class="dialog-conversation__author-name">
                                    @if (!!request.author) {
                                        <span [textContent]="request.author.fullName"></span>
                                    }
                                    @if (!request.author && request.anonymous) {
                                        <span [translate]="'global.userstates.anonymous'"></span>
                                    }
                                </h6>
                                <time class="dialog-conversation__timestamp" [textContent]="utilsService.getTimeElapsedFrom(request.ratedOn)" [title]="request.ratedOn | date: 'dd/MM/yyyy HH:mm'"></time>
                            </section>
                            <section class="dialog-conversation__avatar">
                                @if (!!request.author) {
                                    <avatar [name]="request.author.name" [surname]="request.author.surname" [small]="true" [thumbnails]="{thumbnail_M: request.author.avatarThumbnailMUrl || request.author.avatarUrl, thumbnail_S: request.author.avatarThumbnailSUrl || request.author.avatarThumbnailMUrl}"></avatar>
                                }
                                @if (!request.author && request.anonymous) {
                                    <avatar [user]="{name: '', surname: '' }" [small]="true" [anonymous]="true"></avatar>
                                }
                            </section>
                            <section class="dialog-conversation__content dialog-conversation__content--first">
                                <p class="dialog-conversation__message" [translate]="'conversations.ratedMessage'"></p>
                            </section>
                        </article>
                        <article class="dialog-conversation__item">
                            <div class="dialog-conversation__content dialog-conversation__content--last" style="width: fit-content;">
                                <show-rating-icon [value]="request.rating" [showTitle]="false"> </show-rating-icon>
                            </div>
                        </article>
                    }
                </div>
            }
            <!-- TAB RESOURCES: Show resources files -->
            @if (selectedTab === REQUEST_TABS.FILES) {
                <div class="detail-files-list scroll scroll7 margin-0">
                    <!-- Skeleton -->
                    @if (isLoadingData) {
                        @for (item of dataSkeleton; track item; let idx = $index) {
                            <div class="detail-files-list__item" [ngClass]="{'opacity-50': idx == 1, 'opacity-30': idx ===2}">
                                <div class="detail-files-list__type-container">
                                    <kendo-skeleton shape="circle" animation="pulse" [width]="50" [height]="50"></kendo-skeleton>
                                </div>
                                <div class="detail-files-list__texts-container">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                    <kendo-skeleton shape="text" animation="pulse" [width]="100" [height]="15" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </div>
                        }
                    }
                    <!-- Resources -->
                    @if (!isLoadingData) {
                        @for (resource of requestResources | sort: 'createdDate'; track resource; let idx = $index) {
                            <div class="detail-files-list__item">
                                <div class="detail-files-list__type-container">
                                    @if (!isImage(resource.mimetype)) {
                                        <span class="detail-files-list__icon-doc icon icon-doc"></span>
                                    }
                                    @if (isImage(resource.mimetype)) {
                                        <img [src]="resource.url" class="detail-files-list__thumbnail" alt="" />
                                    }
                                </div>
                                <div class="detail-files-list__texts-container">
                                    <p class="detail-files-list__name" [textContent]="resource.name + resource.extension"></p>
                                    <p class="detail-files-list__date" [textContent]="resource.createdDate | date:'dd/MM/yyyy HH:mm'"></p>
                                </div>
                                <span class="detail-files-list__icon-download icon icon-download" (click)="downloadResource(resource.url, resource.nameWithExtension)"></span>
                            </div>
                        }
                        @if (requestResources.length == 0 && !isLoadingData) {
                            <no-results [imageTitle]="'conversations.noResultsAttachementsTitle'"></no-results>
                        }
                    }
                </div>
            }
            <!-- TAB NOTES: Show request notes -->
            @if (selectedTab === REQUEST_TABS.NOTES) {
                <div #notesContainer id="notes-container" class="request-notes__list scroll scroll7">
                    @if ((!requestNotes || requestNotes.length == 0) && !isLoadingData) {
                        <no-results [imageTitle]="'conversations.noResultsNotesTitle'"></no-results>
                    }
                    @for (note of requestNotes; track note) {
                        <div class="request-notes__item" [ngClass]="{ 'bg-black-5': note.kind === CONVERSATION_NOTES.CLOSING || note.kind === CONVERSATION_NOTES.REOPENING || note.kind === CONVERSATION_NOTES.DISCARDED_VALIDATION }">
                            <div class="request-notes__item__header">
                                <div class="flex" kendoTooltip filter="div.avatar">
                                    <avatar [name]="note.sender?.name" [surname]="note.sender?.surname" [small]="true" [title]="note.sender?.name + ' ' + note.sender?.surname" class="margin-right-10" [thumbnails]="{thumbnail_M: note.sender?.avatarThumbnailMUrl || note.sender?.avatarUrl, thumbnail_S:  note.sender?.avatarThumbnailMUrl || note.sender?.avatarThumbnailSUrl}"></avatar>
                                    <span class="request-notes__item__date" [textContent]="note.sentOn | date:'dd/MM/yyyy HH:mm'"></span>
                                </div>
                                <conversation-note-status-tag [status]="note.kind"></conversation-note-status-tag>
                            </div>
                            @if (note.note) {
                                <p class="request-notes__item__text" [innerHTML]="note.note"></p>
                            }
                            @if (note.resources && note.resources.length > 0) {
                                <div class="flex flex-wrap margin-horizontal-16 margin-top-16 margin-left-40">
                                    @for (attachment of note.resources; track attachment) {
                                        <div class="flex align-items-baseline margin-bottom-10 margin-right-10">
                                            @if (!isImage(attachment.mimetype)) {
                                                <a [href]="attachment.url" class="attachment" target="_blank">
                                                    <span class="attachment__icon icon icon-attachment"></span>
                                                    <span class="attachment__filename">{{attachment.name}}{{attachment.extension | lowercase}}</span>
                                                </a>
                                            }
                                            @if (isImage(attachment.mimetype)) {
                                                <a [href]="attachment.url" class="request-notes__image-container" target="_blank">
                                                    <img class="request-notes__image" [src]="attachment.url" alt="" />
                                                </a>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </section>
    }
    
    @if (!isLoadingData && ((selectedTab !== REQUEST_TABS.FILES && !request?.closedOn) || (selectedTab === REQUEST_TABS.NOTES && !!request?.closedOn))) {
        <section class="request-detail__footer" [ngClass]="{'request-detail__footer--text-editor-options-visible' : textEditorOptionsVisible}">
            @if (selectedTab === REQUEST_TABS.DETAIL) {
                <dialenga-textarea #requestMsg textareaId="request-message" [(inputModel)]="newMessage.message" placeholder="message.captionPlaceholder" (inputModelChange)="validateModel()" [isRichFormat]="true" [showLinkBtns]="true" [showListsBtns]="true" [hideActionsBtns]="true"></dialenga-textarea>
            }
            @if (selectedTab === REQUEST_TABS.NOTES) {
                <dialenga-textarea #requestNote textareaId="request-note" [(inputModel)]="newNote" placeholder="message.captionPlaceholder" (inputModelChange)="validateModel()" [isRichFormat]="true" [showLinkBtns]="true" [showListsBtns]="true" [hideActionsBtns]="true"></dialenga-textarea>
            }
            <div class="flex flex-wrap margin-horizontal-16 conversation-detail__footer__attachments-container scroll scroll3">
                <!-- Files / Images / Linked Publications attached in request conversation (detail)-->
                @if (selectedTab === REQUEST_TABS.DETAIL) {
                    @for (attachment of requestDetailAttachments.files; track attachment; let idx = $index) {
                        <div class="flex align-items-baseline margin-bottom-10 margin-right-10">
                            <a [href]="attachment.url" class="attachment" target="_blank">
                                <span class="attachment__icon icon icon-attachment"></span>
                                <span class="attachment__filename">{{attachment.name}}{{attachment.extension | lowercase}}</span>
                            </a>
                            <span class="attachment__icon attachment__icon--remove icon nucleo icon-remove-fill margin-left-5" (click)="removeFile(idx)"></span>
                        </div>
                    }
                    @for (image of requestDetailAttachments.images; track image; let idx = $index) {
                        <div class="flex flex-wrap">
                            <div class="image-attachment">
                                @if (image.src) {
                                    <img [src]="image.src" class="image-attachment__image" alt="">
                                }
                                <span class="attachment__icon attachment__icon--remove icon nucleo icon-remove-fill margin-left-5" (click)="removeImage(idx)"></span>
                            </div>
                        </div>
                    }
                    @for (link of newMessage.linkedPublications; track $index) {
                        <div class="link-publication">
                            @if (link.imageURL) {
                                <figure class="content-image content-image--sm" [style.--content-image-background]="'url(' + link.imageURL + ')'">
                                    @if (link.gallerySize !== 0) {
                                        <figcaption class="content-kind-badge content-kind-badge--md">
                                            <span [textContent]="'+' + (link.gallerySize - 1)"></span>
                                        </figcaption>
                                    }
                                    @if (link.kind === CONTENT_KIND.SURVEY) {
                                        <figcaption class="content-kind-badge content-kind-badge--md">
                                            <span class="icon icon-questionnaire"></span>
                                        </figcaption>
                                    }
                                </figure>
                            } @else {
                                @if (link.youtubeURL) {
                                    <figure class="content-image content-image--sm" [style.--content-image-background]="'url(' + getVideoImgPreview(link.youtubeURL) + ')'">
                                        <figcaption class="content-kind-badge content-kind-badge--md">
                                            <span class="icon icon-play"></span>
                                        </figcaption>
                                    </figure>
                                } @else {
                                    <figure class="content-image content-image--sm" data-is-category [style.--content-image-background]="link.category.imageURL ? 'url(' + link.category.imageURL + ')' : null" [style.--content-image-overlay-color]="utilsService.getCategoryColor(link.category)">
                                        @if (link.kind === CONTENT_KIND.SURVEY) {
                                            <figcaption class="content-kind-badge content-kind-badge--md">
                                                <span class="icon icon-questionnaire"></span>
                                            </figcaption>
                                        }
                                    </figure>
                                }
                            }
                            <div class="link-publication__info">
                                <span class="link-publication__title" [textContent]="link.title"></span>
                                <span class="link-publication__category truncate" [textContent]="link.category.name" [ngStyle]="{'background-color': utilsService.getCategoryColor(link.category)}"></span>
                                @if (link.kind === CONTENT_KIND.SURVEY && link.anonymousSurvey) {
                                    <span class="icon icon-heisenberg anonymous-badge anonymous-badge--small-card"></span>
                                }
                            </div>
                            <span class="link-publication__icon-remove icon nucleo icon-remove-fill margin-left-5" (click)="removeLink($index)"></span>
                        </div>
                    }
                }
                <!-- Files / Images attached in request Notes -->
                @if (selectedTab === REQUEST_TABS.NOTES) {
                    @for (attachment of requestNotesAttachments.files; track attachment; let idx = $index) {
                        <div class="flex align-items-baseline margin-bottom-10 margin-right-10">
                            <a [href]="attachment.url" class="attachment" target="_blank">
                                <span class="attachment__icon icon icon-attachment"></span>
                                <span class="attachment__filename">{{attachment.name}}{{attachment.extension | lowercase}}</span>
                            </a>
                            <span class="attachment__icon attachment__icon--remove icon nucleo icon-remove-fill margin-left-5" (click)="removeFile(idx)"></span>
                        </div>
                    }
                    @for (image of requestNotesAttachments.images; track image; let idx = $index) {
                        <div class="flex flex-wrap">
                            <div class="image-attachment">
                                @if (image.src) {
                                    <img [src]="image.src" class="image-attachment__image" alt="">
                                }
                                <span class="attachment__icon attachment__icon--remove icon nucleo icon-remove-fill margin-left-5" (click)="removeImage(idx)"></span>
                            </div>
                        </div>
                    }
                }
            </div>
            <div class="flex justify-space-between align-items-center padding-16 margin-right-16">
                <div class="flex align-items-center">
                    <span class="icon icon-24 icon-clickable" [ngClass]="textEditorOptionsVisible ? 'icon-text-format-filled' : 'icon-text-format black-45'" (click)="textEditorOptionsVisible = !textEditorOptionsVisible"></span>
                    <span class="icon icon-24 icon-attachment black-45 icon-clickable margin-left-16" (click)="openFileSelection()"></span>
                    <input #attachFile type="file" title class="upload" style="display: none;" (change)="onFileSelected($event.target.files)" />
                    @if (selectedTab === REQUEST_TABS.DETAIL) {
                        <span class="icon icon-24 icon-related-content black-45 icon-clickable margin-left-16" (click)="openInternalLinkSelection()"></span>
                    }
                </div>
                @if (selectedTab === REQUEST_TABS.DETAIL) {
                    <loading-button [text]="'global.form.send'" class="margin-right-10" [buttonId]="'send-message'" [iconClass]="'icon-send'" [classes]="'button--cta width-100-percent'" (action)="sendMessage()" [isValid]="isValidMessage"></loading-button>
                }
                @if (selectedTab === REQUEST_TABS.NOTES) {
                    <loading-button [text]="'global.form.save'" [buttonId]="'send-note'" [classes]="'button button--secondary'" (action)="sendMessage()" [isValid]="isValidNote"></loading-button>
                }
            </div>
        </section>
    }
</div>