import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';

import { Constants } from '../../constants';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { SharedDataService } from '../../shared/services/shared-data.service';
import { CategoryService } from './category.service';

@Component({
    selector: 'category',
    templateUrl: 'category.component.html',
    styleUrls: ['_category.component.scss']
})

export class CategoryComponent implements OnInit {

    public IMAGE_CROPPER_CATEGORY: { [key: string]: number };
    public CATEGORY_AVAILABILITY: { [key: string]: number };

    private _route: any;
    private userSession: any;

    public defaultLanguage: string;
    public selectedLanguage: string;
    public availableCompanyLanguages: Array<string>;

    public isLanguageInfoVisible: any;

    public categoryId: number;
    public categoryForm: CategoryForm;
    public initialCategoryForm: any;

    public isCategoryPreviewOpened: any;

    public colorSelectorActive: boolean;
    public isValid: boolean;

    public disabledTooltipText: string;
    public availableColors: any;

    constructor(
        private location: Location,
        private upgrade: UpgradeModule,
        private formUtilsService: FormUtilsService,
        private sharedDataService: SharedDataService,
        private categoryService: CategoryService,
    ) {
        this.IMAGE_CROPPER_CATEGORY = Constants.IMAGE_CROPPER_CATEGORY;
        this.CATEGORY_AVAILABILITY = Constants.CATEGORY_AVAILABILITY;

        this._route = this.upgrade.$injector.get('$route');
        this.userSession = window['getUserSession']();

        this.defaultLanguage = this.userSession.companyDefaultLanguage;
        this.selectedLanguage = this.userSession.companyDefaultLanguage;
        this.availableCompanyLanguages = this.userSession.companyLanguages;

        this.disabledTooltipText = (this.availableCompanyLanguages && this.availableCompanyLanguages.length > 1) ? 'formValidation.invalidMultipleLanguages' : 'formValidation.invalidSingleLanguage';
        this.availableColors = Object.values(this.categoryService.availableColors);

        this.categoryId = this._route.current.params.id;
        this.categoryForm = {
            category: {
                color: this.availableColors[0],
                availability: this.CATEGORY_AVAILABILITY.ALL,
                contents: {
                    [this.defaultLanguage]: {
                        name: ''
                    }
                }
            },
            images: []
        };
        this.initialCategoryForm = JSON.parse(JSON.stringify(this.categoryForm));

        this.isValid = false;
        this.colorSelectorActive = false;
    }

    ngOnInit(): void {
        if (this.categoryId) {
            this.categoryService.findOne(this.categoryId).subscribe(
                (response) => {
                    this.categoryForm.category = response;
                    this.selectedLanguage = this.sharedDataService.getMainContentLanguage(this.categoryForm.category.contents);
                    if (!this.categoryForm.category.contents[this.defaultLanguage]) {
                        this.categoryForm.category.contents[this.defaultLanguage] = {
                            name: ''
                        };
                    }
                    this.initialCategoryForm = JSON.parse(JSON.stringify(this.categoryForm));
                    this.validateModel();
                },
                (error) => {
                    console.error('Error fetching category:', error);
                }
            );
        }
    }

    openCategoryPreview() {
        this.isCategoryPreviewOpened = !this.isCategoryPreviewOpened;
    }

    toggleColorSelector() {
        this.colorSelectorActive = !this.colorSelectorActive;
    }

    isCurrentLanguagePublicationValid(contents: any): boolean {
        for (const key in contents) {
            if (contents.hasOwnProperty(key)) {
                const content = contents[key];
                if (content && content.name && content.name.trim().length > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    dataHasChanged() {
        return JSON.stringify(this.categoryForm) !== JSON.stringify(this.initialCategoryForm);
    }

    validateModel() {
        let hasChanges = this.dataHasChanged();
        let nonEmptyLanguagesValid = this.isCurrentLanguagePublicationValid(this.categoryForm.category.contents);
        this.isValid = this.categoryForm.category.color && nonEmptyLanguagesValid && hasChanges;
    }

    selectCategoryAvailability(availability: number) {
        this.categoryForm.category.availability = availability;
        this.onChange();
    }

    selectColor(color: string) {
        this.categoryForm.category.color = color;
        this.colorSelectorActive = false;
        this.onChange();
    }

    removeEmptyLanguages(dataContent: any) {
        var languages = Object.keys(dataContent);
        languages.forEach(language => {
            if (!dataContent[language].name || dataContent[language].name.trim().length === 0) {
                delete dataContent[language];
            }
        });
        this.selectedLanguage = this.sharedDataService.getMainContentLanguage(this.categoryForm.category.contents);
    }

    onChange() {
        this.validateModel();
    }

    categoryFormSave(): void {
        const isFormDirty = this.dataHasChanged();
        if (isFormDirty) {
            this.removeEmptyLanguages(this.categoryForm.category.contents);

            let promise: Promise<any>;
            if (this.categoryForm.category.id) {
                promise = this.categoryService.update(this.categoryForm).toPromise();
            } else {
                promise = this.categoryService.create(this.categoryForm).toPromise();
            }

            promise.then(
                (response) => {
                    this.location.go('/settings/categories');
                },
                () => {
                    this.formUtilsService.finishSubmitAction();
                }
            );
        } else {
            this.location.go('/settings/categories');
        }
    }
}

interface CategoryForm {
    category: {
        color: string;
        availability: number;
        contents: {
            [key: string]: {
                name: string;
                subtitle?: string;
            };
        };
        id?: number;
        name?: string;
        subtitle?: string;
        active?: boolean;
        language?: string;
        availableLanguages?: string[];
        deletable?: boolean;
        contentsCount?: number;
        knowledgeCenterContentsCount?: number;
        subfoldersCount?: number;
        imageURL?: string;
        imageName?: string;
        imageExtension?: string;
        imageWeight?: number;
        description?: string | null;
    };
    images?: any[];
    croppedImageSrc?: string;
}