import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../constants';
import { RequestsService } from './requests.service';
import { RequestCreateModalComponent } from '../request-create/request-create-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { FileSaverService } from 'ngx-filesaver';
import { UtilsService } from '../../shared/services/utils.service';

declare var moment: any;

@Component({
    selector: 'requests',
    templateUrl: './requests.component.html',
    styleUrls: ['./_requests.scss'],
    encapsulation: ViewEncapsulation.None
})

export class RequestsComponent implements OnInit {

    public isSyncing: boolean = false;
    public REQUEST_STATE: any;
    public REQUEST_VALIDATION_STATE: any;
    public REQUESTS_FILTER_MENU: any = {
        ALL: 0,
        UNREAD: 1,
        PENDING_RESPONSE: 2,
        HAS_ATTACHMENTS: 3,
        HAS_NOTES: 4
    };
    public ORDER_LIST: any = {
        RECENT_FIRST: 1,
        OLDER_FIRST: 2
    };
    public LAST_MESSAGE_KIND: any = {
        MESSAGE: 1,
        CONTENT: 2,
        IMAGE: 3,
        FILE: 4
    };

    public requestsStats: any = {
        newConversations: null,
        withoutAnswer: null,
        avgRating: null
    };
    public requestsList: any = {
        data: [], 
        total: null
    };

    public selectedRequest: any = {
        idx: 0,
        data: null,
    }

    public dataPagingOpts: any = {
        page: 0,
        size: 20,
        skip: 0,
        sort: ['lastMessageOn,desc']
    };
    public dataFilterOpts: any = {
        name: '',
        state: '',
        validationState: '',
        tray: null,
        motive: null,
        withoutResponse: null, 
        unread: null,
        hasAttachments: null,
        hasNotes: null
    };
    public requestsFiltersMenu: any = [
        { text: this.translate.instant('documents.documentsKind.filterTagNames.all'), value: this.REQUESTS_FILTER_MENU.ALL, icon: 'icon-request-folder'},
        { text: this.translate.instant('conversations.filters.unread'), value: this.REQUESTS_FILTER_MENU.UNREAD, icon: 'icon-request-folder-unread'},
        { text: this.translate.instant('conversations.filters.withoutResponse'), value: this.REQUESTS_FILTER_MENU.PENDING_RESPONSE, icon: 'icon-warning-circle'},
        { text: this.translate.instant('conversations.filters.withAttachments'), value: this.REQUESTS_FILTER_MENU.HAS_ATTACHMENTS, icon: 'icon-attachment'},
        { text: this.translate.instant('conversations.filters.withNotes'), value: this.REQUESTS_FILTER_MENU.HAS_NOTES, icon: 'icon-note'}
    ];
    public filterOptionSelected: any = this.requestsFiltersMenu[0];
    public orderListSelected: number = this.ORDER_LIST.RECENT_FIRST;
    public defaultTrayOption: any;
    public trayOptions: any;
    public traySelected: any;
    public defaultMotiveOption: any;
    public motiveOptions: any;
    public inputSearchOpened: boolean = false;
    public dataSkeleton = [{},{},{}];
    public isValidationActive: boolean = false;
    public applyFilterValidation: boolean = false;
    public selectedValidationStates: Array<any>;
    public validationStateOptions: Array<any>; 
    public validationStateDisabled(itemArgs: { dataItem: any }) {
        return itemArgs.dataItem.itemDisabled;
      }

    private mentionFullRegex = /<mention.*?>(.*?)<\/mention>/g;
    private mentionRegex = /^<mention id="(.+)">(.*?)<\/mention>(.*)$/;

    @ViewChild('inputSearch') inputSearchRef: ElementRef;
    // @ViewChild('motiveFilter', { static: true }) public motiveFilterRef: any;

    constructor ( private requestsService: RequestsService,
                  private fileSaverService: FileSaverService, private formUtilsService: FormUtilsService, private dialog: MatDialog,
                  private notificationService: DuiNotificationsService, private utilsService: UtilsService, private translate: TranslateService ) 
    {
        this.REQUEST_STATE = Constants.REQUEST_STATE;
        this.REQUEST_VALIDATION_STATE = Constants.REQUEST_VALIDATION_STATE;
        this.validationStateOptions = [{
            id: this.REQUEST_VALIDATION_STATE.NO_REQUIRED,
            name: 'conversations.noValidationRequired',
            enabled: true,
            class: 'bg-white box-shadow__legend-circle--border',
            itemDisabled: false
        }, {
            id: this.REQUEST_VALIDATION_STATE.PENDING,
            name: 'conversations.pendingValidation',
            enabled: true,
            class: 'bg-pending-color',
            itemDisabled: false
        }, {
            id: this.REQUEST_VALIDATION_STATE.VALIDATED,
            name: 'conversations.validatedConversation',
            enabled: true,
            class: 'bg-approved-color',
            itemDisabled: false
        },{
            id: this.REQUEST_VALIDATION_STATE.REJECTED,
            name: 'conversations.rejectedConversation',
            enabled: true,
            class: 'bg-rejected-color',
            itemDisabled: false
        }];
        this.selectedValidationStates = [this.REQUEST_VALIDATION_STATE.NO_REQUIRED, this.REQUEST_VALIDATION_STATE.PENDING, this.REQUEST_VALIDATION_STATE.VALIDATED, this.REQUEST_VALIDATION_STATE.REJECTED];
        this.dataFilterOpts.state = Constants.REQUEST_STATE.OPEN;
        this.defaultTrayOption = {id: null, tray: this.translate.instant('conversations.allTrays')};
        this.defaultMotiveOption = {id: null, motive: this.translate.instant('conversations.allMotives'), color: 0};
    }

    ngOnInit() {
        this.isSyncing = true;
        this.getRequestsStats();
        this.getTrayOptions();
        this.getMotiveOptions();
        this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
        // Redirected from Home, open new request modal
        let newRequestFromHome = sessionStorage.getItem('navigateFromHomeToRequests');
        if (newRequestFromHome) {
            this.createNewRequest();
            sessionStorage.removeItem('navigateFromHomeToRequests');
        }
        // Redirected from user profile, open new request and send user name for set in modal
        let newRequestFromUserProfile = sessionStorage.getItem('navigateFromUserProfileToRequests');
        if (newRequestFromUserProfile) {
            this.createNewRequest(JSON.parse(newRequestFromUserProfile));
            sessionStorage.removeItem('navigateFromUserProfileToRequests');
        }
    }

    //** API methods **//

    private getRequestsStats(): void {
        this.requestsService.getRequestsStatistics().subscribe(
            (response) => {
                this.requestsStats = response;
            },
            (error) => { }
        )
    }

    private getTrayOptions(): void {
        let includeAnonymous = true;
        this.requestsService.getAllTraysForResponsible(includeAnonymous).subscribe(
            (response) => {
                this.trayOptions = response;
                this.isValidationActive = this.someTrayHasValidation();
            },
            (error) => { }
        )
    }

    private getMotiveOptions(): void {
        this.requestsService.getAllMotivesForResponsible().subscribe(
            (response) => {
                this.motiveOptions = response;
            },
            (error) => { }
        )
    }

    getRequestsList(pagingOptions: any, filterOptions: any) {
        this.isSyncing = true;
        this.requestsList.data = [];
        this.dataPagingOpts.page = 0;     

        if (this.isValidationActive && this.dataFilterOpts.state === this.REQUEST_STATE.OPEN) {
            this.dataFilterOpts.validationState = this.applyFilterValidation ? ['VALIDATED', 'REJECTED'] : [];
        }

        if (this.isValidationActive && this.dataFilterOpts.state === this.REQUEST_STATE.CLOSED) {
            this.dataFilterOpts.validationState = this.setValidationStatesFilters();
        }

        this.requestsService.findAll(pagingOptions, filterOptions).subscribe(
            (response) => {
                this.requestsList = {
                    data: response.content,
                    total: response.totalElements
                };
                this.selectedRequest.idx = response.totalElements > 0 ? 0 : null;
                this.selectedRequest.data = response.totalElements > 0 ? response.content[0] : null;

                this.isSyncing = false;
                this.formUtilsService.finishSubmitAction();
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    //** Public methods */

    createNewRequest(user?: any): void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {};
        if (!!user) {
            dialogSettings.data = {
                user: user
            }
        }
        dialogSettings.width = '900px';
        dialogSettings.position = { top: '4vh'};
        dialogSettings.panelClass = ['animated','slideInDown'];

        this.dialog.open(RequestCreateModalComponent, dialogSettings).afterClosed().subscribe(result => {
            if (result?.success) {
                const message =  this.translate.instant('conversations.newRequestMessage', { user: result.success.author.fullName });
                this.notificationService.showSuccessNotification(message);

                this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
            }
        });
    }

    onTrayFilterSelected(value: any): void {
        if (value && value.id) {
            this.isValidationActive = value.requiresValidation;
        } else {
            this.isValidationActive = this.someTrayHasValidation();
        }
        this.dataFilterOpts.tray = value.id;
        this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
    }

    onSelectFilterState(newState: number): void {
        this.dataFilterOpts.state = newState;
        this.dataPagingOpts.page = 0;
        this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
    }

    onScrollRequestsList(): void {
        this.dataPagingOpts.page++;
        this.isSyncing = true;

        this.requestsService.findAll(this.dataPagingOpts, this.dataFilterOpts).subscribe(
            (response) => {
                this.requestsList.data = this.requestsList.data.concat(response.content);
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    selectRequest(request: any, idx: number): void {
        this.selectedRequest.idx = idx;
        this.selectedRequest.data = request;
    }

    onReloadDataEvent(value: any): void {
        if (value) {
            this.selectedRequest.data.lastResponsibleMessageDate = value.sentOn;
            this.selectedRequest.data.lastMessageOn = value.sentOn;
            this.selectedRequest.data.lastMessage = value.message;
            this.selectedRequest.data.lastSender = value.author.fullName;
        } else {
            // Reload complete list
            this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
        }
    }

    onSelectFilter(option: any): void {
        this.filterOptionSelected = option;
        switch (this.filterOptionSelected.value) {
            case this.REQUESTS_FILTER_MENU.ALL:
                this.clearDataFilters(['unread','withoutResponse','hasAttachments','hasNotes']);
                break;
            case this.REQUESTS_FILTER_MENU.UNREAD:
                this.dataFilterOpts.unread = true;
                this.clearDataFilters(['withoutResponse','hasAttachments','hasNotes']);
                break;
            case this.REQUESTS_FILTER_MENU.PENDING_RESPONSE:
                this.dataFilterOpts.withoutResponse = true;
                this.clearDataFilters(['unread','hasAttachments','hasNotes']);
                break;
            case this.REQUESTS_FILTER_MENU.HAS_ATTACHMENTS:
                this.dataFilterOpts.hasAttachments = true;
                this.clearDataFilters(['unread','withoutResponse','hasNotes']);
                break;
            case this.REQUESTS_FILTER_MENU.HAS_NOTES:
                this.dataFilterOpts.hasNotes = true;
                this.clearDataFilters(['unread','withoutResponse','hasAttachments']);
                break;
            default:
                this.clearDataFilters(['unread','withoutResponse','hasAttachments','hasNotes']);
                break;
        }
        this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
        document.getElementById('icon-menu-filter').click();
    }
    
    onSelectListOrder(order: number): void {
        this.orderListSelected = order;
        this.dataPagingOpts.sort = this.orderListSelected === this.ORDER_LIST.RECENT_FIRST ? ['lastMessageOn,desc'] : ['lastMessageOn,asc'];
        this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
        document.getElementById('icon-menu-filter').click();
    }

    onTrayOrMotiveChange(value: any) {
        this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
    }

    onRequestValidationChange(newValidationState: any) {
        let newStateIsFiltered = this.selectedValidationStates.findIndex((element) => { return element == newValidationState }) !== -1;

        if (newStateIsFiltered) {
            this.selectedRequest.data.validationState = newValidationState;
        } else {
            this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
        }
    }

    onApplyFilterValidationChange(): void {
        this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
    }

    onChangeValidationStates(currentStates: any): void {
        this.validationStateOptions.forEach((option: any, index: number) => {
            option.enabled = currentStates.indexOf(index) !== -1;
            option.itemDisabled = currentStates.indexOf(index) !== -1 && currentStates.length === 1;
        });
        this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
    }

    exportRequests(): void {
        this.notificationService.showDownloadNotification();

        this.requestsService.exportRequests(this.dataFilterOpts.tray).subscribe(
            (response: any) => {
                let fileName = new Date().toISOString().split('.')[0];
                fileName = fileName.replaceAll(':', '_');
                this.fileSaverService.save(response.body, fileName + '.csv');

                setTimeout(() => {
                    this.notificationService.closeAllNotifications();
                }, 1000);
            }   
        )
    }

    //** Search input functions **//

    openInputSearch(): void {
        this.inputSearchOpened = !this.inputSearchOpened;
        setTimeout(() => {
            this.inputSearchRef.nativeElement.focus();
        }, 500);
    }

    searchRequestsByName(clearInput: boolean) {
        if (clearInput) { this.dataFilterOpts.name = ''; this.inputSearchRef.nativeElement.focus();}
       
        if (this.dataFilterOpts.name.length >= 3 || this.dataFilterOpts.name.length === 0) {
            this.getRequestsList(this.dataPagingOpts, this.dataFilterOpts);
        }
    }

    //** Miscelaneous methods */

    private getAttachmentTranslationKey(lastMessageKind: number): string {
        switch (lastMessageKind) {
            case this.LAST_MESSAGE_KIND.IMAGE:
                return 'conversations.senderAttachImage';
            case this.LAST_MESSAGE_KIND.FILE:
                return 'conversations.senderAttachFileAngular';
            default:
                return 'conversations.senderAttachContent';
        }
    }

    getTitleWithoutFormat(request: any): string {
        if (!request.lastMessage) {
            const attachmentTranslation = this.getAttachmentTranslationKey(request.lastMessageKind);
            return this.translate.instant(attachmentTranslation, { sender: request.lastSender });
        } else {
            let title = request.lastMessage;
            let textParagraphs = title.split('<p>');
            textParagraphs = textParagraphs.filter(function(item: any) {
                return item !== '';
            })
            let plainText = textParagraphs.join(" ");
            title = plainText.replace('</p>', '');
    
            // Clean text for mention tag an replace it for @
            var mentions = title.match(this.mentionFullRegex);
            
            if (mentions) {
            mentions.forEach(mention => {
                title = title.replace(mention, '@' + mention.match(this.mentionRegex)[2]);
            });
            }

            var element = document.createElement("div");
            element.innerHTML = title;
    
            var text = element.textContent || element.innerText || "";
            element.remove();
            return text;
        }
    };

    getMotiveTagHexColor(color: any): string {
        return this.utilsService.decimalToHex(color);
    };

    isRequestWithoutResponse(request: any): boolean {
        return moment().subtract(7,'d').isSameOrAfter(request.lastMessageOn) && 
              (request.lastResponsibleMessageDate == null || request.lastResponsibleMessageDate < request.lastMessageOn);
    };

    private clearDataFilters(filters: Array<string>): void {
        filters.forEach((filter: string) => {
            this.dataFilterOpts[filter] = null;
        })
    }

    private someTrayHasValidation(): boolean {
        let trayIndex = this.trayOptions.findIndex((tray: any) => {
            return tray.requiresValidation;
        });
        return trayIndex !== -1;
    }

    private setValidationStatesFilters(): Array<string> {
        let states = [];
        this.selectedValidationStates.forEach((selectedState: number) => {
            switch (selectedState) {
                case this.REQUEST_VALIDATION_STATE.NO_REQUIRED:
                    states.push('null');
                    break;
                case this.REQUEST_VALIDATION_STATE.PENDING:
                    states.push('PENDING');
                    break;
                case this.REQUEST_VALIDATION_STATE.VALIDATED:
                    states.push('VALIDATED');
                    break;
                case this.REQUEST_VALIDATION_STATE.REJECTED:
                    states.push('REJECTED');
                    break;
                default:
                    break;
            }
        });
        return states;
    }
}