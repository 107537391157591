import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { TimeoffSettingsService } from '../timeoff-settings/timeoff-settings.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { isEqual, cloneDeep } from 'lodash';
import { Constants } from '../../constants';

@Component({
  selector: 'schema-general',
  templateUrl: './timeoff-schema-general.component.html',
  styleUrls: ['./_timeoff-schema-general.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeoffSchemaGeneralComponent implements OnChanges {

  @Input() schemaForm: any;
  @Input() schemaId: number;
  @Input() selectedLanguage: string;
  @Output() schemaFormChange: EventEmitter<any> = new EventEmitter<any>();

  public SCHEMA_KIND_DAYS: any;
  public SCHEMA_PERIOD_ACTIVATION_KIND: any;
  public PERIOD_STATES: any;
  public days: Array<number> = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
  public months: Array<any>;
  public isFormDataValid: boolean;
  public isFormDataChanged: boolean = false;
  public workingDaysSelection: Array<any>;
  public schemaPeriods: any = {
    currentPeriod: {},
    nextPeriod: {}
  }

  private _rootScope: any;
  private initialDataForm: any = {};
  private schemasPath: string = '/settings/timeoff/schemes';

  constructor(
    private upgrade: UpgradeModule, private timeoffSettingsService: TimeoffSettingsService, private notificationService: DuiNotificationsService,
    private translate: TranslateService, private formUtilsService: FormUtilsService) 
  { 
    this._rootScope = this.upgrade.$injector.get('$rootScope');
    this.SCHEMA_KIND_DAYS = Constants.SCHEMA_KIND_DAYS;
    this.SCHEMA_PERIOD_ACTIVATION_KIND = Constants.SCHEMA_PERIOD_ACTIVATION_KIND;
    this.PERIOD_STATES = Constants.PERIOD_STATES;
      
    this.workingDaysSelection = [
      { name: this.translate.instant('global.form.weekDaysAbbr', {day: 1}), dayOfWeek: 1, workable: false, schemaField: 'mondayEnabled'},
      { name: this.translate.instant('global.form.weekDaysAbbr', {day: 2}), dayOfWeek: 2, workable: false, schemaField: 'tuesdayEnabled'},
      { name: this.translate.instant('global.form.weekDaysAbbr', {day: 3}), dayOfWeek: 3, workable: false, schemaField: 'wednesdayEnabled'},
      { name: this.translate.instant('global.form.weekDaysAbbr', {day: 4}), dayOfWeek: 4, workable: false, schemaField: 'thursdayEnabled'},
      { name: this.translate.instant('global.form.weekDaysAbbr', {day: 5}), dayOfWeek: 5, workable: false, schemaField: 'fridayEnabled'},
      { name: this.translate.instant('global.form.weekDaysAbbr', {day: 6}), dayOfWeek: 6, workable: false, schemaField: 'saturdayEnabled'},
      { name: this.translate.instant('global.form.weekDaysAbbr', {day: 7}), dayOfWeek: 7, workable: false, schemaField: 'sundayEnabled'}
    ];
    if (window['getLocalizedMonthsNames']) {
      this.months = window['getLocalizedMonthsNames']();
    }
  }

  // PRIVATE METHODS

  private setInitialFormData() :void {
    this.initialDataForm = cloneDeep(this.schemaForm);
    this.schemaFormChange.emit(this.schemaForm);
    this.onChange();
  }

  private initNoticeContents(): void {
    const advice = this.schemaForm.timeOffSchema.noticeContents;

    this.schemaForm.timeOffSchema.showNoticeContents = !!advice && !!advice[this.selectedLanguage] && !!advice[this.selectedLanguage].notice;

    if (!this.schemaForm.timeOffSchema.noticeContents[this.selectedLanguage]) {
      this.schemaForm.timeOffSchema.noticeContents[this.selectedLanguage] = {
        notice: ''
      }
    }
  }

  private checkSchemaPeriods(): void {
    if (this.schemaForm.currentPeriod) {
      const currentPeriodStartYear = new Date(this.schemaForm.currentPeriod.firstDayOfYear).getFullYear() || null;
      const currentPeriodEndYear = new Date(this.schemaForm.currentPeriod.lastDayOfYear).getFullYear() || null;
      this.schemaPeriods.currentPeriod.period = currentPeriodStartYear !== currentPeriodEndYear ? 
        currentPeriodStartYear.toString() + '-' + currentPeriodEndYear.toString() : currentPeriodStartYear.toString();
    }

    if (this.schemaForm.nextPeriod) {
      const nextPeriodStartYear = new Date(this.schemaForm.nextPeriod.firstDayOfYear).getFullYear() || null;
      const nextPeriodEndYear = new Date(this.schemaForm.nextPeriod.lastDayOfYear).getFullYear() || null;
      var defaultNextPeriod = nextPeriodStartYear ? nextPeriodStartYear.toString() : null;
      this.schemaPeriods.nextPeriod.period = 
        nextPeriodStartYear !== nextPeriodEndYear ? nextPeriodStartYear.toString() + '-' + nextPeriodEndYear.toString() : defaultNextPeriod;
    }
  }

  private checkAndParseDataBeforeSave(): void {
    if (!this.schemaForm.timeOffSchema.showNoticeContents) {
      this.schemaForm.timeOffSchema.noticeContents = {};
    } else {
      const schemaNoticeContents: Array<string> = Object.keys(this.schemaForm.timeOffSchema.noticeContents);
      schemaNoticeContents.forEach((language: any) => {
          if (!this.schemaForm.timeOffSchema.noticeContents[language].notice || this.schemaForm.timeOffSchema.noticeContents[language].notice === '') {
              delete this.schemaForm.timeOffSchema.noticeContents[language];
          }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.schemaForm && changes.schemaForm.currentValue && changes.schemaForm.previousValue !== changes.schemaForm.currentValue) {
      this.initNoticeContents();
      this.checkSchemaPeriods();
      this.setInitialFormData();
    }
  }

  // PUBLIC METHODS

  checkIfDataFormAreChanged() :void {
    this.isFormDataChanged = !isEqual(this.initialDataForm, this.schemaForm);
  }

  checkIfDataFormAreValid() : void {
    let validAnnualLeaveDays: boolean = 
      this.schemaForm.timeOffSchema.annualLeaveDays !== null && this.schemaForm.timeOffSchema.annualLeaveDays >= 0;
        
    let validBearDays: boolean = 
      !this.schemaForm.timeOffSchema.allowBearRequests || 
        (this.schemaForm.timeOffSchema.bearDaysAllowed !== null && this.schemaForm.timeOffSchema.bearDaysAllowed >= 0);
    
    let validPendingDays: boolean = 
      !this.schemaForm.timeOffSchema.allowPendingDays ||
      (this.schemaForm.timeOffSchema.pendingDaysAllowed !== null && this.schemaForm.timeOffSchema.pendingDaysAllowed >= 0 
        && !!this.schemaForm.timeOffSchema.limitPendingDay && !!this.schemaForm.timeOffSchema.limitPendingMonth);
    
    let validPersonalDays: boolean =
      !this.schemaForm.timeOffSchema.allowPersonalDays ||
        (this.schemaForm.timeOffSchema.personalDaysAllowed !== null && this.schemaForm.timeOffSchema.personalDaysAllowed > 0);

    this.isFormDataValid = validAnnualLeaveDays && validBearDays && validPendingDays && validPersonalDays;
  }

  onChange() {
    this.checkIfDataFormAreChanged();
    this.checkIfDataFormAreValid();
  }

  goBack() :void {
    this._rootScope.goBackAndCheckForModifications(this.isFormDataChanged, this.schemasPath);
  }

  scheduleFormSave() :void {
    this.checkAndParseDataBeforeSave();
    this.timeoffSettingsService.updateSchema(this.schemaId, this.schemaForm.timeOffSchema).subscribe(
      (response: any) => {
        this.schemaForm.timeOffSchema = response;
        this.initNoticeContents();
        this.checkSchemaPeriods();
        this.setInitialFormData();
        this.formUtilsService.finishSubmitAction();

        this.notificationService.showSuccessNotification();
      },
      (onError: any) => { }
    );
  }

  setSchemaKindDays(kind: number): void {
    this.schemaForm.timeOffSchema.daysKind = kind;
    this.onChange();
  }

  setSchemaWorkingDays(day: any): void {
    this.schemaForm.timeOffSchema.workDays[day.schemaField] = !this.schemaForm.timeOffSchema.workDays[day.schemaField];
    this.onChange();
  }

  onInputNumberChange(field: any): void {
    let minValue = parseInt(field.getAttribute('min'));
    let maxValue = parseInt(field.getAttribute('max'));
    let value: number = parseInt(field.value) <= minValue ? minValue : (parseInt(field.value) >= maxValue ? maxValue : parseInt(field.value));

    field.value = value;
    this.onChange();
  }

  onChangeAllowBearDays(): void {
    if (!this.schemaForm.timeOffSchema.allowBearRequests) {
      this.schemaForm.timeOffSchema.bearDaysAllowed = this.initialDataForm.timeOffSchema.bearDaysAllowed;
    }
    this.onChange();
  }

  onChangeAllowPendingDays(): void {
    if (!this.schemaForm.timeOffSchema.allowPendingDays) {
      this.schemaForm.timeOffSchema.pendingDaysAllowed = this.initialDataForm.timeOffSchema.pendingDaysAllowed;
      this.schemaForm.timeOffSchema.limitPendingDay = this.initialDataForm.timeOffSchema.limitPendingDay;
      this.schemaForm.timeOffSchema.limitPendingMonth = this.initialDataForm.timeOffSchema.limitPendingMonth;
    }
    this.onChange();
  }            

  onChangeAllowPersonalDays(): void {
    if (!this.schemaForm.timeOffSchema.allowPersonalDays) {
      this.schemaForm.timeOffSchema.personalDaysAllowed = this.initialDataForm.timeOffSchema.personalDaysAllowed;
    }
    this.onChange();
  }

  onChangeShowNoticeContents(): void {
    if (this.schemaForm.timeOffSchema.showNoticeContents && !this.schemaForm.timeOffSchema.noticeContents) {
      this.schemaForm.timeOffSchema.noticeContents = {};
    }
    this.onChange();
  }
}