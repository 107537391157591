import { environment } from '../environments/environment';

export class Constants {
    public static get apiUrl(): string { return environment.apiUrl; }
    public static get backofficeUrl(): string { return environment.backofficeUrl; }
    public static get webappUrl(): string { return environment.webappUrl; }
    public static get environmentName(): string { return environment.name; }
    public static MAX_SUBCATEGORIES_LEVELS = 6;
    public static defaultLanguage = 'es';
    public static PAGE_SIZE = 10;
    // TODO: get values from constants.js
    public static LANGUAGES = [
        'es',
        'en',
        'fr',
        'it',
        'ca_ES',
        'eu_ES',
        'sk',
        'zh',
        'ro'
    ];
    public static EDITION_ALLOWED_KEYS = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'ArrorUp',
        'ArrowDown',
        'ControlLeft',
        'ControlRight',
        'Escape',
        'Enter', 
        'Home',
        'End'
    ];
    public static USER_ROLES = {
        all: '*',
        admin: 'ROLE_ADMIN',
        manager: 'ROLE_MANAGER',
        user: 'ROLE_USER',
        platformAdmin: 'ROLE_PLATFORM_ADMIN',
        ftrayResponsible: 'ROLE_FTRAY_RESPONSIBLE',
        documentsManager: 'ROLE_DOCUMENTS_MANAGER',
        publisher: 'ROLE_PUBLISHER',
        teamResponsible: 'ROLE_TEAM_RESPONSIBLE',
        humanResources: 'ROLE_HUMAN_RESOURCES',
        employeeManager: 'ROLE_EMPLOYEE_MANAGER',
        ftrayManager: 'ROLE_FTRAY_MANAGER',
        chatroomManager: 'ROLE_CHATROOM_MANAGER'
    };
    public static MODULES = {
        CONVERSATIONS: 'conversations',
        EXPERIENCES: 'experiences',
        KNOWLEDGE_CENTER: 'knowledgeCenter',
        DOCUMENTS: 'documents',
        HOLIDAYS: 'timeOff',
        SIGNATURE: 'remoteSignature',
        CHAT: 'chat',
        TIME_TRACKING: 'timeTracking'
    };
    public static MODULES_STATES = {
        DISABLED: 0,
        ENABLED: 1,
        EMPLOYEE_DISABLED: 2
    };
    public static MESSAGE_STATES = {
        DRAFT: 1,
        PENDING: 2,
        PUBLISHED: 3,
        EXPIRED: 4,
        DELETED: 5,
        PLANNED: 6,
        PUBLISHING_NOW: 7
    };
    public static CONTENT_KIND = {
        MESSAGE: 1,
        DIALOGUE: 2,
        KNOWLEDGE_PILL: 3,
        SURVEY: 4
    };
    public static DOCUMENT_TRANSACTION_STATE = {
        SUCCESSFUL: 1,
        SOME_ERRORS: 2,
        FAILURE: 3
    };
    public static CATEGORY_CONTAINER_STATE = {
        CLOSED: 1,
        OPENED: 2,
        EDITION: 3
    };
    public static CATEGORY_AVAILABILITY = {
        ALL: 1,
        TIMELINE: 2,
        KNOWLEDGE_CENTER: 3
    };
    public static REQUEST_STATE = {
        OPEN: 'OPEN',
        CLOSED: 'CLOSED',
        MESSAGE_PENDING: 'MESSAGE_PENDING'
    }
    public static REQUEST_VALIDATION_STATE = {
        NO_REQUIRED: 0,
        PENDING: 1,
        VALIDATED: 2,
        REJECTED: 3 
    }
    public static CONVERSATION_NOTES = {
        NORMAL: 1,
        CLOSING: 2,
        REOPENING: 3,
        VALIDATION: 4,
        REJECTION: 5,
        DISCARDED_VALIDATION: 6
    };
    public static AVAILABLE_FEEDS = {
        ALL: 1,
        TIMELINE: 2,
        KNOWLEDGE_CENTER: 3
    };
    public static USER_STATES = {
        ALL: 0,
        ANONYMOUS: 1,
        PENDING_ACTIVATION: 2,
        REGISTERED: 3,
        EXPIRED: 4,
        DISABLED: 5,
        EXPIRED_CREDENTIALS: 6,
        LOCKED: 7
    }
    public static DOCUMENTS_TABS = {
        ALL_DOCUMENTS : 1,
        REMOTE_SIGNATURE: 2
    };
    public static CHATROOM_TABS = {
        GENERAL : 1,
        USERS: 2
    };
    public static MY_COMPANY_TABS = {
        GLOBAL : 1,
        COMPANIES: 2,
        WORKPLACES: 3,
        DEPARTMENTS: 4,
        TEAMS: 5,
        EXTERNAL_LINKS: 6
    };
    public static COMPANY_TABS = {
        GENERAL : 1,
        USERS: 2
    };
    public static DOCUMENT_TABS = {
        GLOBAL : 1,
        CONVENTIONS: 2
    };
    public static TIME_TRACKING_TABS = {
        SCHEDULES : 1,
        GLOBAL: 2,
        NOTIFICATIONS: 3
    };
    public static SCHEDULE_TABS = {
        GENERAL : 1,
        RECORD_KINDS: 2,
        USERS: 3
    };
    public static USER_STATES_FILTER = {
        ANONYMOUS: 'ANONYMOUS',
        PENDING_ACTIVATION: 'PENDING_ACTIVATION',
        REGISTERED: 'REGISTERED',
        EXPIRED: 'EXPIRED',
        DISABLED: 'DISABLED',
        EXPIRED_CREDENTIALS: 'EXPIRED_CREDENTIALS',
        LOCKED: 'LOCKED'
    };
    public static SEGMENTED_USER_ORIGIN = {
        MANAGED: 1,
        DIALOGUE: 2,
        SYNCHRONIZED: 3
    };
    public static SIGNATURE_BATCH_STATES = {
        SENT: 1,
        IN_PROGRESS: 2,
        FINISHED: 3,
        EXPIRED: 4,
        CANCELLED: 5
    };
    public static SIGNATURE_MASSIVE_BATCH_STATES = {
        CANCELLABLE: 1,
        CANCELLING: 2,
        CANCELLED: 3
    };
    public static BATCH_STATES_FILTER = {
        SENT: 'SENT',
        IN_PROGRESS: 'IN_PROGRESS',
        FINISHED: 'FINISHED',
        EXPIRED: 'EXPIRED',
        CANCELLED: 'CANCELLED'
    };
    public static DOCUMENT_STATES = {
        UNSIGNED: 1,
        SIGNED: 2,
        REJECTED: 3,
        EXPIRED: 4,
        CANCELLED: 5
    };
    public static DOCUMENT_KIND = {
        ALL: 0,
        PAYROLL: 1,
        EXTRA: 2,
        CERT: 3,
        TIME_REGISTRATION: 4, 
        OTHER: 5
    };
    public static UPLOAD_FILE_MODE = {
        MULTIPLE: 1,
        SINGLE: 2
    };
    public static CHATROOM_STATES = {
        ACTIVE: 1,
        INACTIVE: 2,
        ARCHIVED: 3
    };
    public static TOAST_DEFAULTS = {
        DELAY: 3500
    };
    public static IMAGE_CROPPER = {
        MINIMUM_HEIGHT: 202,
        RECOMMENDED_HEIGHT: 402,
        MINIMUM_WIDTH: 360,
        RECOMMENDED_WIDTH: 720,
        ASPECT_RATIO: 16 / 9
    };
    public static IMAGE_CROPPER_GALLERY = {
        MINIMUM_HEIGHT: 202,
        MINIMUM_WIDTH: 360,
        ASPECT_RATIO: 'none'
    };
    public static IMAGE_CROPPER_CATEGORY = {
        MINIMUM_HEIGHT: 135,
        MINIMUM_WIDTH: 360,
        RECOMMENDED_HEIGHT: 270,
        RECOMMENDED_WIDTH: 720,
        ASPECT_RATIO: 8 / 3
    };
    public static IMAGE_CROPPER_CHATROOM = {
        MINIMUM_HEIGHT: 202,
        MINIMUM_WIDTH: 202,
        ASPECT_RATIO: 1 / 1
    };
    public static IMAGE_FREE_CROPPER = {
        MINIMUM_HEIGHT: '',
        MINIMUM_WIDTH: '',
        ASPECT_RATIO: 'none'
    };
    public static GENERAL_CONFIG_STATES = {
        ENABLED: 1,
        DISABLED: 2,
        FORCE_ENABLED: 3,
        FORCE_DISABLED: 4
    };
    public static RATING_KIND = {
        EMOJI: 1,
        STAR: 2
    };
    public static SIGNERS_TYPE = {
        SINGLE: 0,
        MULTIPLE: 1
    };    
    public static DOCUMENT_OPENER = {
        INTERNAL_VIEWER: 1,
        USER_DESIRED_APP: 2
    }
    public static FILE_KINDS = {
        PAYROLL: 1,
        EXTRA: 2,
        CERTIFICATE: 3,
        TIME_REGISTRATION: 4,
        PAYROLL_ADVANCE: 5,
        AUTHORIZATION: 6,
        CONTRACT: 7,
        MANUALS: 8
    }
    public static CHATROOM_USERS_SYNCHRO_KIND = {
        MANUAL: 1,
        SEGMENTS: 2
    }
    public static SEGMENTATION_KIND = {
        INTERSECTION: 1,
        UNION: 2
    }
    public static POPULATION = {
        ALL: 1,
        SELECTED_USERS: 2,
        SEGMENTS: 3,
        NO_GROUP: 4
    }
    public static SCHEDULE_PERIOD_KIND = {
        ALWAYS: 1,
        CUSTOM: 2
    }
    public static TIME_TRACKING_MODE = {
        CLOCK_OR_MANUAL: 1,
        MANUAL: 2,
        CLOCK: 3
    }
    public static START_SHIFT_LIMITATION = {
        NO_LIMIT: 1,
        WARNING: 2,
        LIMITED: 3,
    }
    public static SHIFT_EDITION_PERIOD = {
        DISABLED: 0,
        ONGOING_MONTH: 1,
        ONGOING_WORKDAY: 2,
        LAST_7_DAYS: 3,
        CUSTOMIZED: 4,
    }
    public static TIMEOFF_SETTINGS_TABS = {
        SCHEMAS: 1,
        GLOBAL: 2
    }
    public static TIMEOFF_VALIDATION_KIND = {
        SIMPLE: 1,
        DOUBLE: 2
    }
    public static TIMEOFF_SCHEMA_TABS = {
        GENERAL: 1,
        ABSENCES: 2,
        EMPLOYEES: 3
    }
    public static SCHEMA_KIND_DAYS = {
        WORKING: 1,
        CALENDAR: 2
    }
    public static SCHEMA_PERIOD_ACTIVATION_KIND = {
        AUTOMATIC: 1,
        MANUAL: 2
    }
    public static PERIOD_STATES = {
        ENABLED: 1,
        DISABLED: 2
    }
    public static ABSENCE_MOTIVE_GROUPS = {
        HEALTH: 1,
        PERSONAL: 2,
        PROFESSIONAL: 3,
        NO_REMUNERABLE: 4,
        OTHER: 5,
        PERSONAL_DAYS: 6
    }
}
