import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { DepartmentsService } from '../departments/departments.service';

@Component({
    templateUrl: './department-create-modal.component.html'
  })
  export class DepartmentCreateModalComponent implements AfterViewInit {

    public isFormValid: boolean = false;
    public isNameDuplicated: boolean = false;
    public departmentForm: any = {
        name: ''
    };
    @ViewChild('departmentName') departmentNameRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<DepartmentCreateModalComponent>, private departmentsService: DepartmentsService,
        private _elementRef: ElementRef
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.departmentNameRef.nativeElement.focus(), 200);
    }

    onChange(): void {
        this.isFormValid = this.departmentForm.name.length > 0;
    }

    onEnterPressed(): void {
        if(this.isFormValid) {
            const createChatBtn: HTMLElement = (this._elementRef.nativeElement).querySelector('.button--secondary');
            createChatBtn.click();
        }
    }

    onAccept(): void {
        this.departmentsService.create(this.departmentForm).subscribe(
            (onSuccess: any) => {
                this.dialogRef.close({success: onSuccess});
            },
            (onError: any) => {
                if (onError.status === 422 && onError.error?.error === 'IllegalOperationException') {
                    this.isNameDuplicated = true;
                }
                this.departmentNameRef.nativeElement.focus();
            }
        );
    }

    onCancel(): void {
        this.dialogRef.close({closed: true});
    }

}