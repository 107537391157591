import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../constants';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { DocumentPreviewModalComponent } from '../documents-list/document-preview-modal.component';
import { RemoteSignatureService } from './remote-signature.service';
import { ContextMenuComponent } from "@progress/kendo-angular-menu";
import { UtilsService } from '../../shared/services/utils.service';
import { DocumentsService } from '../documents/documents.service';
import { FileSaverService } from 'ngx-filesaver';

declare const moment: any;

@Component({
    selector: 'remote-signature',
    templateUrl: './remote-signature.component.html',
    styleUrls: ['../remote-signature-detail/_remote-signature-detail.scss']
})
export class RemoteSignatureComponent implements OnInit, OnDestroy{

    private _route: any;
    private userSession: any;

    public isMassiveBatchView: boolean;
    public massiveBatchId: string;
    public userLoggedHasAdminRole: boolean;
    private requestBatchStatus: any;
    
    public massiveBatch: any = {
        fileKind: null
    };
    public isSignatureProductionMode: boolean;
    public rowsExpanded: any[] = [];
    public isSyncing: boolean = false;
    public MODULES = Constants.MODULES;
    public DOCUMENT_STATES = Constants.DOCUMENT_STATES;
    public SIGNATURE_MASSIVE_BATCH_STATES = Constants.SIGNATURE_MASSIVE_BATCH_STATES;
    public SIGNATURE_BATCH_STATES_ALL: number = 0;
    public SIGNATURE_BATCH_STATES = Constants.SIGNATURE_BATCH_STATES;
    public BATCH_FILTER_DATE = {
        LAST_30: 30,
        LAST_60: 60,
        LAST_90: 90
    };
    public DATE_FILTER = {
        LAST_30_DAYS: 4,
        LAST_60_DAYS: 5,
        LAST_90_DAYS: 6
    };
    public BATCH_KIND_FILTER: any = {
        ALL: 0,
        SINGLE: 1,
        MASSIVE: 2
    };
    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5,10,20,100],
    };
    public signatureBatchsDatagrid: GridDataResult = {
        data: [],
        total: 0
    };
    public datagridPagingOptions: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: 'requestDate,desc'
    };
    public datagridFilteringOptions: any = {
        state: this.SIGNATURE_BATCH_STATES_ALL,
        hasRejectedFiles: false,
        startDate: moment().subtract(this.BATCH_FILTER_DATE.LAST_60, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        name: '',
        batchKind: this.BATCH_KIND_FILTER.ALL
    };

    // public selectedState: any;
    // public states: any[];

    public submenuAvailableOptions: any[] = [];
    public infoRowData: any;
    public dataSkeleton = [{},{},{}];

    public filterDateOptions: Array<object> = [];
    public batchKindOptions: Array<object> = [];

    public filterStateBatchOptions: any = [
        {text: 'global.messages.stateFilters.all', state: this.SIGNATURE_BATCH_STATES_ALL, value: 0},
        {text: 'remoteSignature.batch.stateLabel.sent', state: this.SIGNATURE_BATCH_STATES.SENT, value: 0},
        {text: 'remoteSignature.batch.stateLabel.inProgress', state: this.SIGNATURE_BATCH_STATES.IN_PROGRESS, value: 0},
        {text: 'remoteSignature.batch.stateFilters.finished', state: this.SIGNATURE_BATCH_STATES.FINISHED, value: 0},
        {text: 'remoteSignature.batch.stateFilters.expired', state: this.SIGNATURE_BATCH_STATES.EXPIRED, value: 0}
    ];
    public filterStateBatchRejected = {text: 'remoteSignature.batch.stateFilters.rejected', state: this.SIGNATURE_BATCH_STATES_ALL, value: 0};

    @ViewChild('masterDocumentsGrid') masterDocumentsGrid: GridComponent;
    @ViewChild("gridmenu") public gridContextMenu: ContextMenuComponent;

    constructor(private translate: TranslateService, private location: Location, private documentsService: DocumentsService,  public documentViewModal: MatDialog, 
                private dialogService: DialogService, private remoteSignatureService: RemoteSignatureService, private utilsService: UtilsService, 
                private fileSaverService: FileSaverService, private upgrade: UpgradeModule, private notificationService: DuiNotificationsService) {
                    
        this.translate = translate;
        this._route = this.upgrade.$injector.get('$route');
        this.userSession = window['getUserSession']();
        this.userLoggedHasAdminRole = this.userSession.roles.indexOf(Constants.USER_ROLES.admin) !== -1;

        this.isSignatureProductionMode = this.userSession.activeModules[this.MODULES.DOCUMENTS].productionMode;
        this.massiveBatchId = this._route.current.params?.id || null;
        this.isMassiveBatchView = !!this.massiveBatchId && this._route.current.$$route.path.includes('/massive-detail/');
    }

    ngOnInit() {

        this.filterDateOptions = [
            {name: this.translate.instant('remoteSignature.batch.dateFilterLabel.last30'), value: this.BATCH_FILTER_DATE.LAST_30},
            {name: this.translate.instant('remoteSignature.batch.dateFilterLabel.last60'), value: this.BATCH_FILTER_DATE.LAST_60},
            {name: this.translate.instant('remoteSignature.batch.dateFilterLabel.last90'), value: this.BATCH_FILTER_DATE.LAST_90}
        ];

        this.batchKindOptions = [
            {name: this.translate.instant('global.messages.stateFilters.all'), value: this.BATCH_KIND_FILTER.ALL},
            {name: this.translate.instant('remoteSignature.batch.batchKindFilterLabel.single'), value: this.BATCH_KIND_FILTER.SINGLE},
            {name: this.translate.instant('remoteSignature.batch.batchKindFilterLabel.massive'), value: this.BATCH_KIND_FILTER.MASSIVE}
        ];

        if( this.isMassiveBatchView && this.massiveBatchId ) {
            this.remoteSignatureService.findOneMassiveBatch(this.massiveBatchId).subscribe(
                (response) => {
                    this.massiveBatch = response;
                    if (!this.massiveBatch.fileKind) {
                        this.massiveBatch.fileKind = {
                            name: this.translate.instant('documents.documentsKind.filterTagNames.other')
                        }
                    }
                }
            )
        }
        this.loadBatchsSummary();
        this.loadSignatureBatchsGridData();
    }

    ngOnDestroy() {
        if( this.requestBatchStatus) {
            clearInterval(this.requestBatchStatus);
        }
    }

    // private getSelectedStatesId(data: any[]) {
    //     return data.reduce(function(selected, item) {
    //             selected.push(item.id);
    //         return selected;
    //     }, []);
    // }

    // private setItemsDisabledOrEnabled(selectedItemsId: any[]) {
    //     if (selectedItemsId.length === 1) {
    //         this.states.forEach(state => {
    //             state.itemDisabled = selectedItemsId.indexOf(state.id) !== -1;
    //         });
    //     } else {
    //         this.states.forEach(state => {
    //             state.itemDisabled = false;
    //         }); 
    //     }
    // }

    // onChangeState(statesSelected: any) {
    //     let selectedItemsId = this.getSelectedStatesId(statesSelected);
    //     this.states.forEach(state => {
    //         state.enabled = selectedItemsId.indexOf(state.id) !== -1;
    //     });
    //     this.setItemsDisabledOrEnabled(selectedItemsId);
    // }
    
    // stateDisabled(itemArgs: { dataItem: any; index: number }): boolean {
    //     return itemArgs.dataItem.itemDisabled;
    // }

    navigateTo(url: string) {
        this.location.go(url);
    }

    loadSignatureBatchsGridData() {
        this.isSyncing = true;

        this.remoteSignatureService.findAll(this.datagridPagingOptions, this.datagridFilteringOptions, this.massiveBatchId).subscribe(
            (response) => {
                this.signatureBatchsDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    loadBatchsSummary() {
        this.remoteSignatureService.getBatchSignatureSummary(this.datagridFilteringOptions.startDate, this.datagridFilteringOptions.endDate, this.datagridFilteringOptions.batchKind, this.massiveBatchId).subscribe(
            (response) => {
                this.setSummaryData(response);
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    getSubmenuOptions() {
        this.submenuAvailableOptions = [{ text: 'global.modal.download', icon: 'icon icon-download', action: 'download'}];
        if (this.infoRowData?.dataItem.state === this.SIGNATURE_BATCH_STATES.SENT) {
            this.submenuAvailableOptions.push({ text: 'global.form.delete', icon: 'icon icon-delete', action: 'delete'})
        }
        return this.submenuAvailableOptions;
    }

    onPageChange(event: PageChangeEvent): void {
        this.datagridPagingOptions.page = (event.skip / this.datagridPagingOptions.size);
        this.datagridPagingOptions.skip = event.skip;
        this.datagridPagingOptions.size = event.take;

        this.loadSignatureBatchsGridData();
    }

    onExpandOrCollapse(event: any) {
        event.preventDefault();
        this.showDetailRow(event.index, false);
    }

    showIfIsNotMassiveBatch(dataItem: any): boolean {
        return !dataItem.massiveBatch;
    }

    onCellClick(e: any): void {
        if (e.columnIndex === 4) {

            const originalEvent = e.originalEvent;
            originalEvent.preventDefault();

            if (originalEvent.target.parentNode.id === 'menuMore') {
                this.infoRowData = {
                    dataItem: e.dataItem,
                    rowIndex: e.rowIndex
                }
                e.dataItem.submenuOpened = true;

                setTimeout(() :void =>  {
                    this.gridContextMenu.show({
                        left: originalEvent.pageX - 100,
                        top: originalEvent.pageY + 15,
                    });
                }, 100)
            }
        }
    }

    onSelectSubmenuOption(optionSelected: any): void {
        switch (optionSelected.action) {
            case 'download':
                this.remoteSignatureService.downloadBatchDocuments(this.infoRowData.dataItem.id).subscribe(
                    (response) => {
                        let downloadName: string;
                        if (this.infoRowData.dataItem.documentsCount > 1) {
                            downloadName = this.infoRowData.dataItem.employee.name + '_' + (this.infoRowData.dataItem.employee.surname).replace(' ', '_') + '_' +
                            (this.infoRowData.dataItem.batchDate).substr(0,10);
                        } else {
                            downloadName = this.infoRowData.dataItem.documents[0].resource.name;
                        }
                        this.fileSaverService.save(response.body, downloadName);
                    },
                    (error: any) => {
                        // Show notification error
                    }
                );
                break;
            case 'delete':
                break;
            default:
                break;
        }
    }

    onSubmenuClose(): void {
        let data: any = this.masterDocumentsGrid.data['data'];
        for (const item of data) {
            item.submenuOpened = false
        }
        this.submenuAvailableOptions = [];
    }

    onSelectStateBatchFilter(stateBatch: any, hasRejectedFiles: any) {
        this.datagridFilteringOptions.hasRejectedFiles = hasRejectedFiles;
        if (stateBatch.state === this.SIGNATURE_BATCH_STATES.FINISHED) {
            this.datagridFilteringOptions.state = [this.SIGNATURE_BATCH_STATES.FINISHED, this.SIGNATURE_BATCH_STATES.CANCELLED];
        } else {
            this.datagridFilteringOptions.state = stateBatch.state;
        }

        this.loadSignatureBatchsGridData();
    }

    onSelectFilters(): void {
        this.loadBatchsSummary();
        this.loadSignatureBatchsGridData();
    }

    onDateSet(event: any): void {
        this.datagridFilteringOptions.startDate = event.startDate;
        this.datagridFilteringOptions.endDate = event.endDate;
        this.loadBatchsSummary();
        this.loadSignatureBatchsGridData();
    };

    showDetailRow(rowIndex: any, isMassiveBatch: boolean) {
        if (isMassiveBatch) {
            return;
        }
        if (this.rowsExpanded.indexOf(rowIndex) !== -1) {
            this.rowsExpanded.splice(this.rowsExpanded.indexOf(rowIndex), 1);
            this.masterDocumentsGrid.collapseRow(rowIndex);
        } else {
            this.rowsExpanded.push(rowIndex);
            this.masterDocumentsGrid.expandRow(rowIndex);
        }
    }

    cancelMassiveBatch() :void {
        const dialogValues: DialogValueOptions = {
            title: 'remoteSignature.batch.modal.massive.title',
            message: 'remoteSignature.batch.modal.massive.message',
            acceptButtonTextClass: 'button--cancel'
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe(result => {
            if(result?.accept) {
                this.remoteSignatureService.cancelMassiveBatch(this.massiveBatchId).subscribe(
                    (success: any) => {
                        this.massiveBatch.cancellable = false;
                        this.massiveBatch.cancellationState = this.SIGNATURE_MASSIVE_BATCH_STATES.CANCELLING;

                        const notificationOptions: NotificationOptions = {
                            kind: 'info',
                            message: this.translate.instant('remoteSignature.batch.modal.massive.cancelSuccess')
                        }
                        this.notificationService.showNotification(notificationOptions);

                        // set an interval for request batch state every 30 secs.
                        this.requestBatchStatus = setInterval(() => {
                            //  Call endpoint for batch data, and check batch state. If state is Cancelled, clear interval and update values
                            this.remoteSignatureService.findOneMassiveBatch(this.massiveBatchId).subscribe(
                                (response) => {
                                    if (response.cancellationState === this.SIGNATURE_MASSIVE_BATCH_STATES.CANCELLED) {
                                        clearInterval(this.requestBatchStatus);
                                        this.massiveBatch.cancellationState = response.cancellationState;
                                        this.loadSignatureBatchsGridData();
                                    }
                                }
                            )
                        }, 3000);
                    },
                    (onError: any) => { }
                )
            }
        });
    }

    actionDownloadDocument(document: any): void {
        this.documentsService.downloadDocument(document.resource.url).subscribe(
            (response) => {
                this.fileSaverService.save(response.body, document.resource.nameWithExtension);
            });
    }

    actionDownloadEvidenceReport(document: any): void {
        this.remoteSignatureService.downloadEvidenceReport(document.reportResource.url).subscribe(
            (response) => {
                this.fileSaverService.save(response.body, document.reportResource.nameWithExtension);
            });
    }

    actionViewDocument(document: any) {
        document.iconMimeType = this.utilsService.getIconFromMimeType(document.resource.mimetype);
        const modalSettings = new MatDialogConfig();
        modalSettings.data = document;
        modalSettings.width = '1200px';
        modalSettings.height = '90vh';
        this.documentViewModal.open(DocumentPreviewModalComponent, modalSettings);
    }

    detailRemoteSignatureBatch(dataItem: any): void {
        let urlDetailBatch = '/remote-signature/' + (dataItem.massiveBatch ? 'massive-detail/' : 'detail/') + dataItem.id;
        this.location.go(urlDetailBatch);
    }

    getBatchStateColor(state: any): string {
        return this.remoteSignatureService.batchStateColor(state);
    }

    getBatchStateTextLabel(state: any): string {
        return this.remoteSignatureService.batchStateTextLabel(state);
    }

    private setSummaryData(data) {
        this.filterStateBatchOptions.forEach(stateOption => {
            switch (stateOption.state) {
                case this.SIGNATURE_BATCH_STATES_ALL:
                    stateOption.value = data.total;
                    break;
                case this.SIGNATURE_BATCH_STATES.SENT:
                    stateOption.value = data.sent;
                    break;
                case this.SIGNATURE_BATCH_STATES.IN_PROGRESS:
                    stateOption.value = data.inProgress;
                    break;
                case this.SIGNATURE_BATCH_STATES.FINISHED:
                    stateOption.value = data.finished;
                    break;
                case this.SIGNATURE_BATCH_STATES.EXPIRED:
                    stateOption.value = data.expired;
                    break;
                default:
                    break;
            }
        });
        this.filterStateBatchRejected.value = data.rejected;
    }

    signatureBatchsSearchInput() {
        if (this.datagridFilteringOptions.name.length >= 3 || this.datagridFilteringOptions.name.length === 0) {
            this.loadSignatureBatchsGridData();
        }
    }

    clearSearchInput() {
        this.datagridFilteringOptions.name = '';
        this.loadSignatureBatchsGridData();
    }

    getDocumentTagHexColor(color: any): string {
        return this.utilsService.decimalToHex(color);
    }

    getDocumentStateBgColor(docState: number): string {
        return this.remoteSignatureService.documentStateBgColor(docState);
    }
    
    getDocumentLabelText(state: number): string {
        return this.translate.instant(this.remoteSignatureService.documentStateLabelText(state));
    }

    rowCallback(row: RowClassArgs) {
        return {
            'selected': row.dataItem.submenuOpened,
            'massive-batch': row.dataItem.massiveBatch
        };
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2 
        }
    }

    showNewHireInfo(): void {
        const dialogValues: DialogValueOptions = {
            title: 'activeFeatures.signature.newHireModal.title',
            message: 'activeFeatures.signature.newHireModal.message',
            acceptButtonText: 'global.form.back',
            acceptButtonTextClass: ' ',
            hideCancelButton: true
        };
        this.dialogService.openConfirmationDialog(dialogValues);
    }
}
