<div class="main-container__content">
  <div class="main-container--small-width">
    <article class="info-advice filter-container">
        <p [translate]="'globalSettings.advice'"></p>
    </article>
    <!-- Approval flow setting -->
    <div class="group-form group-form--label-300 margin-top-20">
      <div class="group-form__label">
          <span class="group-form__label__text" [translate]="'timeoff.global.approvalFlow'"></span>
      </div>
      <div class="group-form__data padding-top-5 align-items-start">
        <fieldset class="radio-button-container padding-top-5">

          <input type="radio" name="simpleFlow" id="simpleFlow" class="sr-only"
            [value]="TIMEOFF_VALIDATION_KIND.SIMPLE" [(ngModel)]="timeoffSettingsGlobalForm.approvalFlow" (ngModelChange)="onChange()">

            <label for="simpleFlow" class="radio-button__item" 
              [ngClass]="{'radio-button__item--selected': timeoffSettingsGlobalForm.approvalFlow === TIMEOFF_VALIDATION_KIND.SIMPLE}">
              <span class="radio-button__item__icon icon"
                [ngClass]="timeoffSettingsGlobalForm.approvalFlow === TIMEOFF_VALIDATION_KIND.SIMPLE ? 'icon-circle-selected' : 'icon-circle'">
              </span>
              <div class="flex direction-column">
                <span class="radio-button__item__label" [translate]="'timeoff.global.simpleValidation'"></span>
                <span class="radio-button__item__label-comment" [translate]="'timeoff.global.simpleValidationAdvice'"></span>
              </div>
            </label>

          <input type="radio" name="doubleFlow" id="doubleFlow" class="sr-only"
            [value]="TIMEOFF_VALIDATION_KIND.DOUBLE" [(ngModel)]="timeoffSettingsGlobalForm.approvalFlow" (ngModelChange)="onChange()">

            <label for="doubleFlow" class="radio-button__item"
              [ngClass]="{'radio-button__item--selected': timeoffSettingsGlobalForm.approvalFlow === TIMEOFF_VALIDATION_KIND.DOUBLE}">
              <span class="radio-button__item__icon icon"
                [ngClass]="timeoffSettingsGlobalForm.approvalFlow === TIMEOFF_VALIDATION_KIND.DOUBLE ? 'icon-circle-selected' : 'icon-circle'">
              </span>
              <div class="flex direction-column">
                <span class="radio-button__item__label" [translate]="'timeoff.global.doubleValidation'"></span>
                <span class="radio-button__item__label-comment" [translate]="'timeoff.global.doubleValidationAdvice'"></span>
              </div>
            </label>

        </fieldset>
      </div>
    </div>
    <!-- Allow half day setting -->
    <div class="group-form group-form--label-300 margin-top-20">
      <div class="group-form__label">
          <span class="group-form__label__text" [translate]="'timeoff.global.allowHalfDay'"></span>
      </div>
      <div class="group-form__data">
          <toggle-input [inputId]="'allow-half-days'" 
            [(inputModel)]="timeoffSettingsGlobalForm.allowsHalfDay" (inputChanged)="onChange()">
          </toggle-input>
      </div>
    </div>
    <!-- Responsible can manage absences setting -->
    <div class="group-form group-form--label-300 margin-top-20">
      <div class="group-form__label">
          <span class="group-form__label__text" [translate]="'timeoff.global.allowApproveRequestsByResponsible'"></span>
      </div>
      <div class="group-form__data">
          <toggle-input [inputId]="'responsible-manage-absences'" 
            [(inputModel)]="timeoffSettingsGlobalForm.responsibleCanManageAbsences" (inputChanged)="onChange()">
          </toggle-input>
      </div>
    </div>
  </div>
</div>