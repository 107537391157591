import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { TimeoffSettingsService } from '../timeoff-settings/timeoff-settings.service';

@Component({
  templateUrl: './timeoff-schema-create-modal.component.html'
})

export class TimeoffSchemaCreateModalComponent implements AfterViewInit {
  public isFormValid: boolean = false;
  public isNameDuplicated: boolean = false;
  public schemaForm: any = {
    name: ''
  };

  @ViewChild('schemaName') schemaNameRef: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<TimeoffSchemaCreateModalComponent>, 
    private timeoffSettingsService: TimeoffSettingsService,
    private _elementRef: ElementRef
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.schemaNameRef.nativeElement.focus(), 200);
  }

  onChange(): void {
    this.isFormValid = this.schemaForm.name.length > 0;
  }

  onEnterPressed(): void {
    if(this.isFormValid) {
      const createSchemaBtn: HTMLElement = (this._elementRef.nativeElement).querySelector('.button--secondary');
      createSchemaBtn.click();
    }
  }

  onAccept(): void {
    this.timeoffSettingsService.createSchema(this.schemaForm).subscribe(
      (response: any) => {
        this.dialogRef.close({success: true, newSchema: response});
      },
      (onError: any) => {
        if (onError.status === 422 && onError.error?.error === 'IllegalOperationException') {
          this.isNameDuplicated = true;
        }
        this.schemaNameRef.nativeElement.focus();
      }
    );
  }

  onCancel(): void {
    this.dialogRef.close({closed: true});
  }

}