<div class="modal-header">
  <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
    <span class="button__icon icon icon-close"></span>
  </button>
  <h3 class="modal-title" [translate]="'schemes.newSchema'"></h3>
</div>

<div class="modal-body">
  <!-- Schema name -->
  <div class="group-form group-form--label-100 margin-bottom-15">
    <div class="group-form__label">
      <span class="group-form__label__text" [translate]="'model.schemes.newName'"></span>
    </div>
    <div class="group-form__data">
      <input #schemaName
        id="schemaName"
        name="schemaName"
        data-cy="schema-name"
        type="text"
        class="input"
        [ngClass]="{'input--error': isNameDuplicated}"
        maxlength="64"
        autocomplete="off"
        [(ngModel)]="schemaForm.name"
        (ngModelChange)="onChange()"
        (keyup)="isNameDuplicated = false"
        (keyup.enter)="onEnterPressed()"
        [placeholder]="'schemes.placeholderTitle' | translate">
    </div>
  </div>
</div>

<div class="modal-footer flex justify-end">
  <loading-button
    [buttonId]="'schema-create'"
    [classes]="'button--secondary margin-right-10'"[text]="'model.schemes.createSchema'" 
    (action)="onAccept()" [isValid]="isFormValid">
  </loading-button>
  <button id="schema-cancel" data-cy="schema-cancel" type="button" class="button" (click)="onCancel()">
    <span class="button__label" [translate]="'global.modal.cancel'"></span>
  </button>
</div>