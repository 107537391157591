<div class="main-container__content margin-top-40">
    <div class="main-container--medium-width">
        <!-- Time tracking records edition in App -->
        <section>
            <h3 class="bold margin-bottom-24" [translate]="'timeTracking.global.timeTrackingEdition'"></h3>

            <div class="group-form group-form--label-300">
                <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'timeTracking.global.editablePeriod'"></span>
                </div>
                <div class="group-form__data align-items-start direction-column">
                    <kendo-dropdownlist class="dui-dropdown max-width-208" [data]="editablePeriodOptions"
                        [(ngModel)]="timeTrackingGlobalForm.shiftEditionPeriod" valuePrimitive="true"
                        (valueChange)="onChangeEditablePeriod()" [textField]="'name'" [valueField]="'value'"
                        [popupSettings]="{ popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                        <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                            @if (!!dataItem) {
                                <span [innerHTML]="dataItem.name"></span>
                            }
                        </ng-template>
                        <ng-template kendoDropDownListItemTemplate let-dataItem>
                            <span [innerHTML]="dataItem.name"></span>
                        </ng-template>
                    </kendo-dropdownlist>
                    @if (!!hasExtraMargin) {
                        <section class="margin-top-16 flex align-items-center">
                            @if (this.timeTrackingGlobalForm.shiftEditionPeriod !== this.SHIFT_EDITION_PERIOD.CUSTOMIZED) {
                                <span class="black-45" [translate]="'timeTracking.global.editingMargin'"></span>
                                <kendo-slider #editMarginDaysSlider class="margin-left-16 margin-right-16"
                                    [tickPlacement]="'after'" [showButtons]="false" [min]="0" [max]="5" [smallStep]="1" [fixedTickWidth]="30"
                                    (mouseup)="onChangeEditMarginSlider(editMarginDaysSlider)" (mouseleave)="onChangeEditMarginSlider(editMarginDaysSlider)"
                                    [(ngModel)]="daysMarginToEditSlider">
                                </kendo-slider>
                                <span [translate]="'global.form.nWorkingDays'" [translateParams]="{days: daysMarginToEditSlider, daysToShow: daysMarginToEditSlider}"></span>
                            }
                            @if (this.timeTrackingGlobalForm.shiftEditionPeriod === this.SHIFT_EDITION_PERIOD.CUSTOMIZED) {
                                <input #editMarginDaysInput id="edit-margin-days-input" type="number" inputmode="numeric" class="input text-right max-width-48 margin-right-10"
                                    [(ngModel)]="daysMarginToEditInput" (ngModelChange)="onChangeEditMarginInput(editMarginDaysInput)" />
                                <span [translate]="'global.form.nWorkingDays'" [translateParams]="{days: timeTrackingGlobalForm.daysMarginToEdit, daysToShow: ''}"></span>
                            }
                        </section>
                    }
                </div>
            </div>
        </section>
    </div>
</div>