import { Component, OnInit } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { Location } from '@angular/common';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { cloneDeep, isEqual } from 'lodash';
import { ExternalLinksService, externalLink } from '../external-links/external-links.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { UtilsService } from '../../shared/services/utils.service';
import { ParamsModalComponent } from './params-modal/params-modal.component';
import { SegmentsManagementModalComponent } from '../../shared/components/segments-management-modal/segments-management-modal.component'

@Component({
	selector: 'external-link',
	templateUrl: 'external-link.component.html',
	styleUrls: ['./_external-link.component.scss']
})

export class ExternalLinkComponent implements OnInit {

	private _route: any;
	private _rootScope: any;
	private path: string;
	private initialDataForm: any = {};

	public userSession = window['getUserSession']();
	public defaultLanguage = this.userSession.companyDefaultLanguage;
	public selectedLanguage: any;

	public externalLinkId: any;
	public externalLinksPath: string = '/settings/my-company/external-links';

	public isFormDataValid: boolean = false;
	public isFormDataChanged: boolean = false;
	public isValidURL: boolean = false;

	public EXTERNAL_LINKS_ICONS: string[] = [
		"gallery-external-link", 
		"separator",		
		"gallery-timer", "gallery-calendar", "gallery-payroll", "gallery-folder", "gallery-card",
		"gallery-certificate", "gallery-attachment", "gallery-download", "gallery-faqs", "gallery-handbook", "gallery-document",
		"gallery-settings", "gallery-phone-icon", "gallery-email", "gallery-survey", "gallery-location", "gallery-call-center",
		"separator",
		"gallery-app-teams", "gallery-app-slack", "gallery-app-meet", "gallery-app-linkedin", "gallery-app-instagram", "gallery-app-x",
		"gallery-app-payflow", "gallery-app-google-drive", "gallery-app-youtube", "gallery-app-facebook", "gallery-app-whatsapp", "gallery-app-telegram", "gallery-app-spotify",
	];

	public EXT_LINK_AVAILABILITY: any = {
		ALL: 1,
		APP: 2,
		WEBAPP: 3
	};
	public SEGMENTATION_KIND: any = Constants.SEGMENTATION_KIND;
	public POPULATION: any = Constants.POPULATION;

	public extLinkForm: externalLink = {
		availabilityKind: this.EXT_LINK_AVAILABILITY.ALL,
		contents: {},
		url: '',
		externalApp: true,
		icon: 'gallery-external-link',
		populationKind: this.POPULATION.ALL,
		segmentationKind: this.SEGMENTATION_KIND.UNION,
		segments: []
	};

	constructor (
		private Dialog: MatDialog, private externalLinksService: ExternalLinksService, private translate: TranslateService, private notificationService: DuiNotificationsService,
		private upgrade: UpgradeModule, private utilsService: UtilsService, private location: Location, private formUtilsService: FormUtilsService) 
	{

		this._route = this.upgrade.$injector.get('$route');
		this.path = this._route.current.$$route.path;
		this._rootScope = this.upgrade.$injector.get('$rootScope');

		this.externalLinkId = this._route.current.params && this.path.includes('/update') ? this._route.current.params.id : null;
		this.selectedLanguage = this.defaultLanguage;
		
		if(!this.externalLinkId) {
			this.extLinkForm.contents[this.defaultLanguage] = {
				name: ''
			};
		}
	}

	ngOnInit() {
		if (!!this.externalLinkId) {
			this.externalLinksService.findOne(this.externalLinkId).subscribe(
				(response: externalLink) => {
					this.setFormData(response);
				},
				(onError: any) => {	}
			);
		} else {
			this.initialDataForm = cloneDeep(this.extLinkForm);
		}
	}

	setFormData(response: externalLink) {
		this.extLinkForm = Object.assign(this.extLinkForm, response);
		this.initialDataForm = cloneDeep(this.extLinkForm);
		this.validateURL(this.extLinkForm.url);
	}

	removeEmptyLanguages(dataContent: any) {
        var languages = Object.keys(dataContent);
		languages.forEach(language => {
			if (language !== this.defaultLanguage && !dataContent[language].name) {
				delete dataContent[language];
				this.selectedLanguage = this.defaultLanguage;
			}
		});
	}

	extLinkFormSave(): void {
		this.extLinkForm.segments = this.extLinkForm.populationKind === this.POPULATION.ALL ? [] : this.extLinkForm.segments;
		this.extLinkForm.externalApp = this.extLinkForm.availabilityKind === this.EXT_LINK_AVAILABILITY.WEBAPP ? true : this.extLinkForm.externalApp;
		this.removeEmptyLanguages(this.extLinkForm.contents);

		let saveContentService = !!this.externalLinkId ? 'update' : 'create';

		this.externalLinksService[saveContentService](this.extLinkForm).subscribe(
			(response: any) => {
				if (!this.externalLinkId) {
					this.location.go(this.externalLinksPath);
				} else {
					this.formUtilsService.finishSubmitAction();
					this.setFormData(response);
					// Show feedback for changes successfully
					this.notificationService.showSuccessNotification();
				}
            },
            (onError: any) => { }
		);
	}

	checkIfDataFormAreChanged(): void {
		this.isFormDataChanged = !isEqual(this.initialDataForm, this.extLinkForm);
	}

	checkIfDataFormAreValid(): void {
		this.isFormDataValid = !!this.extLinkForm.contents[this.defaultLanguage]?.name && !!this.isValidURL;
	}

	onChange() {
		this.checkIfDataFormAreChanged();
		this.checkIfDataFormAreValid();
	}

	setPopulation(kind: number): void {
		this.extLinkForm.populationKind = kind;
		this.onChange();
	}

	validateURL(dataUrl: string) {
		if (dataUrl && dataUrl.length > 6) {
			this.isValidURL = true;
			this.extLinkForm.url = this.utilsService.concatURLPrefixIfNotExists(dataUrl);
		} else {
			this.isValidURL = false;
		}
		this.onChange();
	};

	addSegmentsToLink(): void {
		const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {
            title: this.translate.instant('chatrooms.modal.segments.title'),
            segments: [... this.extLinkForm.segments],
            segmentationKind: this.extLinkForm.segmentationKind,
            showSegmentationSelection: true,
            population: this.extLinkForm.populationKind};
        dialogSettings.width = '1100px';
        dialogSettings.minWidth = '600px';
        dialogSettings.maxWidth = '90vw';
        dialogSettings.position = { top: '4vh'};
        dialogSettings.panelClass = ['animated','slideInDown'];

        this.Dialog.open(SegmentsManagementModalComponent, dialogSettings).afterClosed().subscribe(result => {
            if (result?.arePendingChanges || result?.segmentationKindChanged) {
                if (result?.arePendingChanges && result?.addedSegments) { this.extLinkForm.segments = result.addedSegments; }
                if (result?.segmentationKindChanged) { this.extLinkForm.segmentationKind = result.segmentationKind; }
                this.onChange();
            }
        });
	}

	removeSegmentFromLink(idx: number): void {
		this.extLinkForm.segments.splice(idx, 1);
		this.onChange();
	}

	goBack(): void {
		this._rootScope.goBackAndCheckForModifications(this.isFormDataChanged, this.externalLinksPath);
	}

	openParamsModal(): void {
		const dialogSettings = new MatDialogConfig();
		dialogSettings.data = {
			title: this.translate.instant('externalLinks.paramsModal.title')
		};
		dialogSettings.width = '690px';
		dialogSettings.position = { top: '4vh' };
		dialogSettings.panelClass = ['animated', 'slideInDown'];
		this.Dialog.open(ParamsModalComponent, dialogSettings);
	};
}
