<div class="modal-header" style="border-bottom: none">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="data.title"></h3>
</div>
<div class="modal-body padding-top-0" [ngClass]="{'padding-bottom-0': (!!hasInvalidUsers && !!requiresEmployeeSignature)}">
    <div class="boxes-groups-container margin-top-10">
        <div class="group">
            <div class="filter-search-bar">
                <kendo-dropdownlist #segmentsList style="width: 55%" [defaultItem]="{id: null, name: 'segments.allUsers' | translate}" [data]="userSegments"
                    [(ngModel)]="originGroup" valuePrimitive="true" (valueChange)="originGroupChange($event)"
                    [textField]="'name'" [valueField]="'id'" [popupSettings]="{ width: '450px' }">
                    <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>
                    
                    <ng-template kendoDropDownListNoDataTemplate>
                        <h5 class="uppercase" [translate]="'global.form.emptyDataSet'"></h5>
                    </ng-template>
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <div class="flex align-items-center list-item-height" style="width: -webkit-fill-available">
                            <span class="icon icon-20 margin-right-10" 
                                [ngClass]="!dataItem.id ? 'icon-globe icon-group-rounded' : 'icon-user-groups-filled icon-group-rounded'">
                            </span>
                            <span class="truncate" [textContent]="dataItem.name"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoDropDownListItemTemplate let-dataItem >
                        <div class="flex align-items-center list-item-height">
                            <span class="icon icon-20 margin-right-10" 
                                [ngClass]="!dataItem.id ? 'icon-globe icon-group-rounded' : 'icon-user-groups-filled icon-group-rounded'">
                            </span>
                            <span class="truncate" style="max-width: 400px" [textContent]="dataItem.name"></span>
                        </div>
                    </ng-template>
                </kendo-dropdownlist>
                <div class="search-grow-container align-items-center" [ngClass]="{'opened': globalSearchOpened}">
                    <input #globalSearch id="global-search" type="text" maxlength="1024" [placeholder]="'segments.searchPlaceholder' | translate" [(ngModel)]="filter.availableUsers"
                        (input)="searchAvailableUsers(false)" ngModelOptions="{ debounce: 200 }" [disabled]="availableUsers.length == 0 && !filter.availableUsers && !isSyncing"
                        (blur)="!filter.availableUsers && globalSearchOpened = !globalSearchOpened" autocomplete="off" />
                    @if (globalSearchOpened && !!filter.availableUsers) {
                        <span class="icon icon-20 icon-remove black60" (click)="searchAvailableUsers(true)"></span>
                    }
                    @if (!globalSearchOpened || (globalSearchOpened && !filter.availableUsers)) {
                        <span id="global-search-icon" data-cy="global-search-icon"
                            class="icon icon-20 icon-search black60" (click)="globalSearchOpen()"
                            [ngClass]="{'disabled-events': globalSearchOpened || availableUsers.length === 0, 'disabled': availableUsers.length === 0}">
                        </span>
                    }
                </div>
            </div>
        </div>
        <div class="group">
            <div class="filter-search-bar justify-content-end">
                <div class="search-grow-container search-grow-container--right align-items-center" [ngClass]="{'opened': groupSearchOpened}">
                    <input #groupSearch id="group-search" type="text" maxlength="1024" [placeholder]="'segments.searchPlaceholder' | translate" [(ngModel)]="filter.addedUsers"
                        ngModelOptions="{ debounce: 200 }" [disabled]="addedUsers.length == 0 && !filter.addedUsers && !isSyncing"
                        (blur)="!filter.addedUsers && groupSearchOpened = !groupSearchOpened" autocomplete="off">
                    @if (groupSearchOpened && !!filter.addedUsers) {
                        <span class="icon icon-20 icon-remove black60" (click)="clearGroupSearch()"></span>
                    }
                    @if (!groupSearchOpened || (groupSearchOpened && !filter.addedUsers)) {
                        <span id="group-search-icon" data-cy="group-search-icon"
                            class="icon icon-20 icon-search black60" (click)="groupSearchOpen()"
                            [ngClass]="{'disabled-events': groupSearchOpened || addedUsers.length === 0, 'disabled': addedUsers.length === 0}">
                        </span>
                    }
                </div>
            </div>
        </div>
    </div>
    <items-selection-boxes [availableUsers]="availableUsers" [addedUsers]="addedUsers" [filterAvailable]="filter.availableUsers" [filterAdded]="filter.addedUsers"
        [isSyncing]="isSyncing" [(addedUsersIds)]="addedUsersIds" (checkingUsersData)="checkForInvalidUsers($event)" [remoteSignatureErrors]="true">
    </items-selection-boxes>

    @if (!!hasInvalidUsers && !!requiresEmployeeSignature) {
        <section class="notification-box notification-box--warning margin-top-20 margin-bottom-0 flex direction-column align-items-start">
            <span class="notification-box__text bold" translate="remoteSignature.creation.modal.warningUsers.title"></span>
            <span class="notification-box__text" translate="remoteSignature.creation.modal.warningUsers.message"></span>
        </section>
    }
</div>
<div class="modal-footer flex justify-end" style="border-top: none">
    <loading-button [buttonId]="'add-admins'" [classes]="'button--secondary margin-right-10'" [text]="'global.modal.accept'"
        (action)="onAccept()" [isValid]="isValid()">
    </loading-button>
    <button type="button" class="button" (click)="onCancel()">
        <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
    </button>
</div>