<div class="main-container">

  <section class="main-container__header" style="min-width: 891px">

    <div class="flex direction-column width-100-percent" spellcheck="false">

      <ul class="breadcrumb">
        <li><a href="/global-settings" [translate]="'global.navbar.globalSettings'"></a></li>
        <li><a id="goToDepartments" href="/settings/my-company/departments"
        [translate]="'myCompany.tabs.departments'"></a></li>
      </ul>

      <div class="editable-wrapper">
        <h2 #departmentName class="icon icon-edit margin-top-5 contenteditable"
          [textContent]="departmentForm.name" ([ngModel])="departmentForm.name" [contentEditable]="true" prevent-event="dragover drop"
          (blur)="onInputCompanyNameEvent($event)" (keydown.enter)="onInputCompanyNameEvent($event)" (keydown.esc)="onInputCompanyNameEvent($event)"
          maxlength="64" (paste)="onInputCompanyNamePaste($event)"></h2>
        <span class="bottom-line"></span>
      </div>

    </div>

    <div class="action-buttons-container margin-left-20 text-right">
      <div class="flex align-items-center">
        <button id="add-department-employees" class="button button--secondary button--small"
          (click)="openAddUsersModal()" data-cy="create-company">
          <span class="button__icon icon icon-add"></span>
          <span class="button__label" [translate]="'schemes.employees.addEmployees'"></span>
        </button>
      </div>
    </div>

  </section>

  <section class="main-container__content">

    <div class="main-container--full-width">

      <!-- Responsible selector -->
      <div class="group-form group-form--label-250">

        <div class="group-form__label">
          <span id="label-team-leader" class="group-form__label__text"
          [translate]="'timeoff.teams.approvalResponsible'"></span>
        </div>

        <div class="group-form__data direction-column align-items-start">

          <div class="radio-button-container radio-button-container--column margin-top-5">

            <div id="check-no-leader" class="radio-button__item"
              [ngClass]="{'radio-button__item--selected': !optionResponsible}" (click)="setResponsible()">
              <span class="radio-button__item__icon icon"
              [ngClass]="!optionResponsible ? 'icon-circle-selected' : 'icon-circle'"></span>
              <span class="radio-button__item__label" [translate]="'departments.optionNoResponsible'"></span>
            </div>

            <div id="check-leader" class="radio-button__item"
              [ngClass]="{'radio-button__item--selected' : !!optionResponsible}" (click)="setResponsible()">
              <span class="radio-button__item__icon icon"
              [ngClass]="!!optionResponsible ? 'icon-circle-selected' : 'icon-circle'"></span>
              <span class="radio-button__item__label" [translate]="'departments.optionResponsible'"></span>
            </div>

          </div>

          @if (!!optionResponsible) {
            <div id="responsible-dropdown" class="margin-left-30" style="width: 344px;">
              <user-dropdown class="min-width-100-percent" id="departmentLeader-dropdown"
              [(inputModel)]="departmentForm.responsible" (onSelection)="onChange()"></user-dropdown>
            </div>
          }

        </div>

      </div>

      <div class="flex align-items-start justify-space-between margin-bottom-8" [style]="'margin-top: -3px;'">

        <div class="group-form group-form--label-100 margin-bottom-0">

          <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'model.schemes.employees'"></span>
          </div>

          <div class="group-form__data">
            <div class="blue-counter">
              <span class="blue-counter__label" [textContent]="departmentEmployeesDatagrid.total"></span>
            </div>
          </div>

        </div>

        <div class="search-filter-container justify-self-end">

          <input type="text" class="input input--search"
            [ngClass]="dataFilterOpts.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'" maxlength="1024"
            [placeholder]="'documents.list.filters.searchPlaceholder' | translate" [(ngModel)]="dataFilterOpts.name"
            ngModelOptions="{ debounce: 200 }" (input)="listItemsSearch()">
            <div class="icon icon-remove" (click)="clearSearchInput()"></div>

          </div>
        </div>

        @if (departmentEmployeesDatagrid?.data.length > 0) {
          <kendo-grid #departmentEmployeesGrid class="master-grid department-grid user-select-none margin-bottom-40 org-grid"
            [sortable]="true"
            [sort]="gridSort"
            [data]="departmentEmployeesDatagrid"
            (sortChange)="onSortChange($event)"
            [pageable]="gridPagerSettings"
            (pageChange)="onPageChange($event)"
            [skip]="dataPagingOpts.skip"
            [pageSize]="dataPagingOpts.size"
            [navigable]="true"
            [resizable]="false"
            [selectable]="false"
            [loading]="isSyncing">
            <kendo-grid-messages [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
              [pagerOf]="'global.lists.pagerOf' | translate" [pagerItems]="'global.lists.pagerItems' | translate">
            </kendo-grid-messages>
            <!-- Employee name -->
            <kendo-grid-column field="name">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center cursor-pointer">
                  <span class="truncate" [textContent]="'users.name' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <!-- <div class="flex align-items-center">
                <span class="truncate cursor-default" [textContent]="dataItem.name"></span>
              </div> -->
              @if (dataItem) {
                <div class="flex align-items-center">
                  <avatar [name]="dataItem.name" [surname]="dataItem.surname" [small]="true"
                    [thumbnails]="{thumbnail_M: dataItem.avatarThumbnailMUrl || dataItem.avatarUrl, thumbnail_S: dataItem.avatarThumbnailSUrl || dataItem.avatarThumbnailMUrl}">
                  </avatar>
                  <span class="margin-left-10" [textContent]="dataItem.name"></span>
                  <span class="margin-left-5 truncate" [textContent]="dataItem.surname"></span>
                </div>
              }
            </ng-template>
          </kendo-grid-column>
          <!-- Employee name -->
          <kendo-grid-column field="email">
            <ng-template kendoGridHeaderTemplate>
              <div class="flex align-items-center cursor-pointer">
                <span class="truncate" [textContent]="'users.username' | translate"></span>
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center">
                <span class="truncate cursor-default" [textContent]="dataItem.email || dataItem.username"></span>
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- CTA buttons column -->
          <kendo-grid-command-column width="165" [style]="{ 'text-align': 'end' }">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              <div class="flex justify-end td-on-hover" kendoTooltip filter="a.k-master-button" offset="0">
                <a class="k-button k-master-button"
                  (click)="deleteEmployeeFromDepartment(dataItem)" [title]="'global.form.delete' | translate">
                  <span class="icon icon-delete"></span>
                </a>
              </div>
            </ng-template>
          </kendo-grid-command-column>
          <ng-template kendoGridNoRecordsTemplate></ng-template>

          <!-- Custom Pager -->
          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <article class="dui-grid-pager">
              @if (departmentEmployeesDatagrid.total > 10) {
                <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                  <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                  <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                  <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                </article>
                <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
              }
            </article>
          </ng-template>

        </kendo-grid>
      }

      <!-- Skeleton Employees grid -->
      @if (departmentEmployeesDatagrid?.total == 0 && isSyncing) {
        <div>
          <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid org-grid" [rowClass]="skeletonRowCallback">
            <!-- Employee name -->
            <kendo-grid-column>
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center">
                  <span [textContent]="'users.name' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <kendo-skeleton shape="text" animation="pulse" [width]="300" [height]="20"
                  style="border-radius: 4px"></kendo-skeleton>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- Employee email -->
            <kendo-grid-column>
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center">
                  <span [textContent]="'users.email' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <kendo-skeleton shape="text" animation="pulse" [width]="300" [height]="20"
                  style="border-radius: 4px"></kendo-skeleton>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- CTA column -->
            <kendo-grid-command-column width="165"></kendo-grid-command-column>
          </kendo-grid>
        </div>
      }

      <!-- No data information -->
      @if (departmentEmployeesDatagrid.total == 0 && !dataFilterOpts.name && !isSyncing) {
        <no-results
          [imageTitle]="'schemes.employees.noResultsTitle'"
          [description]="'schemes.employees.noResultsText'">
        </no-results>
      }
      @if (departmentEmployeesDatagrid.total == 0 && dataFilterOpts.name && !isSyncing) {
        <no-search-results></no-search-results>
      }

    </div>
  </section>

</div>